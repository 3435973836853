import cn from "classnames";
import { useResponsive } from "hooks/useResponsive";
import { truncate } from "lodash";
import React, { forwardRef, MouseEventHandler, useId, useState } from "react";

import styles from "./Checkbox.module.scss";

type Props = Omit<React.HTMLProps<HTMLInputElement>, "size" | "children"> & {
  size?: "full" | "auto";
  label?: React.ReactNode;
  labelSize?: "xs" | "sm" | "md";
  children?: string;
  error?: string;
  type?: "checkbox" | "radio";
};

export const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    onChange,
    id,
    name,
    className,
    disabled,
    checked,
    labelSize = "md",
    children,
    error,
    label,
    type = "checkbox",
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const sm = useResponsive("sm");
  const randomId = useId();

  const maxLabelLength = sm ? 100 : 200;

  const inputId = id || randomId;
  const labelText = children || label || "";
  const isControlShown = labelText.length > maxLabelLength;
  const labelToDisplay = isExpanded
    ? labelText
    : truncate(labelText, {
        length: maxLabelLength,
        separator: /,?\.* +/,
        omission: "...",
      });

  const onMoreClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={cn(styles.checkbox, className)}>
      <input
        ref={ref}
        className={styles.input}
        onChange={onChange}
        type={type}
        id={inputId}
        name={name}
        disabled={disabled}
        checked={checked}
      />
      <label
        className={cn(styles.label, styles[labelSize], error && styles.error)}
        htmlFor={inputId}
      >
        <span>
          {labelToDisplay}
          {isControlShown && (
            <span className={styles.more} onClick={onMoreClick}>
              {isExpanded ? "Less" : "More"}
            </span>
          )}
        </span>
      </label>
    </div>
  );
});
