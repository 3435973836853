import { FUNNEL_QUESTIONS } from "constants/funnel";
import { useFormPersist } from "hooks/useFormPersist";
import { useModal } from "hooks/useModal";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { finalCheckoutPath } from "pages/FinalCheckout";
import { funnelPath } from "pages/Funnel";
import { successPath } from "pages/Funnel/Success";
import { useRef, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { funnelService } from "../../services/funnelService";
import { visitorIdService } from "../../services/visitoridService";

export type FunnelFormNames = (typeof FUNNEL_QUESTIONS)[number]["name"];
export type FunnelFormValues = Record<FunnelFormNames, string>;

const defaultValues = FUNNEL_QUESTIONS.reduce(
  (acc, question) => ({ ...acc, [question.name]: undefined }),
  {} as FunnelFormValues
);

export class FunnelSubmit {
  visitorid: string | null | undefined;

  data: FunnelFormValues | undefined;
}

export const FUNNEL_FORM_STORAGE_KEY = "funnelData";

export const useFunnel = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigateWithQuery();
  const promiseResolveRef = useRef<(value: boolean) => void>();
  const { open } = useModal("CONTINUE_SURVEY");
  const visitorid = useMemo(
    () => searchParams.get("visitorid"),
    [searchParams]
  );
  const methods = useFormPersist(FUNNEL_FORM_STORAGE_KEY, {
    defaultValues,
    initialPathname: `${funnelPath}/0`,
    shouldRememberPathname: true,
    askIfShouldRestoreValues: () => {
      open();
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
      });
    },
  });

  visitorIdService.setVisitorID(visitorid);

  const onSubmit: SubmitHandler<FunnelFormValues> = (data) => {
    try {
      visitorIdService.trackEvent("Funnel", "Submit", "", "", "");

      const sub: FunnelSubmit = new FunnelSubmit();
      sub.data = data;
      sub.visitorid = visitorid;
      // TODO: Send data to server
      funnelService.submitFunnel(sub).then(() => {
        localStorage.removeItem(FUNNEL_FORM_STORAGE_KEY);
        navigate(`${finalCheckoutPath}${successPath}`);
      });
    } catch (error) {
      console.error("Error sending data", error);
    }
  };

  return { ...methods, onSubmit, resolve: promiseResolveRef.current };
};
