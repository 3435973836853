import { TherapistProfile } from "services/therapistProfileService";

const THERAPY_OPTIONS_ICONS: {
  name: keyof TherapistProfile;
  classIcon?: string;
}[] = [
  {
    name: "in_person_session_availability",
    classIcon: "therapist-options__item-btn person-icon",
  },
  {
    name: "telephone_session_availability",
    classIcon: "therapist-options__item-btn phone-icon",
  },
  {
    name: "video_session_availability",
    classIcon: "therapist-options__item-btn video-icon",
  },
  {
    name: "online_chat_sessions_availability",
    classIcon: "therapist-options__item-btn chat-icon",
  },
];

interface TherapyOptionsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles: any;
  therapist: TherapistProfile;
  title: string;
}

const TherapyOptions = ({ styles, therapist, title }: TherapyOptionsProps) => {
  return (
    <>
      <div className="therapist-options">
        <p className="text-lg pe-15 my-5">{title}</p>
      </div>
      <div className={styles.therapyOptions}>
        <ul>
          {THERAPY_OPTIONS_ICONS.filter(({ name }) => therapist[name]).map(
            ({ name, classIcon }) => (
              <li key={name}>
                <button className={classIcon} />
              </li>
            )
          )}
        </ul>
      </div>
    </>
  );
};

export default TherapyOptions;
