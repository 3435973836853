import { ReactComponent as LogInIcon } from "assets/images/icons/login.svg";
import cn from "classnames";
import { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import { Logo } from "../Logo/Logo";
import styles from "./LandingHeader.module.scss";

const actions = [
  {
    label: "Join",
    value: "signup",
  },
  {
    label: "Login",
    value: "login",
  },
] as const;

type Tooltip = "signup" | "login" | null;

interface Props {
  className?: string;
}

export const LandingHeader: FC<Props> = ({ className }) => {
  const [tooltip, setTooltip] = useState<Tooltip>(null);
  const hideTooltipTimeout = useRef<NodeJS.Timeout | null>(null);

  const onButtonMouseEnter = (value: Tooltip) => {
    if (hideTooltipTimeout.current) {
      clearTimeout(hideTooltipTimeout.current);
      hideTooltipTimeout.current = null;
    }

    setTooltip(value);
  };

  const onButtonMouseLeave = () => {
    if (hideTooltipTimeout.current) {
      clearTimeout(hideTooltipTimeout.current);
      hideTooltipTimeout.current = null;
    }

    hideTooltipTimeout.current = setTimeout(() => {
      setTooltip(null);
    }, 250);
  };

  const onTooltipMouseEnter = () => {
    if (!hideTooltipTimeout.current) {
      return;
    }

    clearTimeout(hideTooltipTimeout.current);
    hideTooltipTimeout.current = null;
  };

  return (
    <header className={cn(styles.root, className)}>
      <Logo className={styles.logo} />
      <div className={styles.headerButtons}>
        {actions.map(({ label, value }) => (
          <div className={styles.tooltipContainer} key={value}>
            <div
              className={cn(styles.tooltip, tooltip === value && styles.open)}
            >
              <Link to={`/therapist/${value}`}>{label} as therapist</Link>
              <Link to={`/patient/${value}`}>{label} as client</Link>
            </div>
          </div>
        ))}
      </div>
    </header>
  );
};
