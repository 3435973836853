import { Button } from "components/UIKit/Button";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { Cookies } from "react-cookie";
import styles from "./Error.module.scss";

export const errorPath = "/error";

export const Error = () => {
  const navigate = useNavigateWithQuery();
  const goBack = () => navigate("/connect");
  const cook = new Cookies();
  const visitorEmail = cook.get("visitorEmail");

  return (
    <div className={styles.errorPane}>
      <div>
        <h1 className={styles.title}>Error</h1>
        <p className={styles.subtitle}>
          An account with {visitorEmail} already exists.
          <br />
          The email and password do not match. Please try again.
        </p>
        <Button onClick={goBack}>Back</Button>
      </div>
    </div>
  );
};
