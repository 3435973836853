import logo from "assets/images/logo-with-text.svg";
import cn from "classnames";
import { Header } from "components/Header/Header";
import { FC } from "react";
import styles from "./SimpeHeader.module.scss";

export const SimpleHeader: FC = () => (
  <Header className={cn(styles.simpleHeader, "bg-teal-light")}>
    <div className="container">
      <a href="https://therapass.com/" className={styles.simpleHeader__logo}>
        <img src={logo} alt="logo" />
      </a>
    </div>
  </Header>
);
