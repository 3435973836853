export class CheckoutSubmitResult {
  success = false;

  errormessage = "";

  orderid = 0;

  email = "";

  mobilenumber = "";

  customerid = 0;

  cartid = "";

  PaymentMode = 0;
}
