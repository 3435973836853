import { HttpService } from "services/httpService";
import { therapassHTTPClient } from "utils/defaultHTTPClient";
import { proxy } from "valtio";

class SystemSettingsService extends HttpService {
  async get() {
    this.http = therapassHTTPClient;
    const data = await this.http.get("/api/v1/system-settings");
    return data;
  }
}

export const systemSettingsService = proxy(new SystemSettingsService());
