import amex from "assets/icons/payment/american-express.svg";
import discover from "assets/icons/payment/discover.svg";
import jcb from "assets/icons/payment/jcb.svg";
import mastercard from "assets/icons/payment/mastercard.svg";
import visa from "assets/icons/payment/visa.svg";
import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function getCardType(cardNumber) {
  const issuer = Payment.fns.cardType(cardNumber);
  let card;

  switch (issuer) {
    case "amex":
      card = amex;
      break;
    case "mastercard":
      card = mastercard;
      break;
    case "visa":
      card = visa;
      break;
    case "discover":
      card = discover;
      break;
    case "jcb":
      card = jcb;
      break;
    default:
      card = "";
      break;
  }
  return card;
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }
  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;
  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }
  return nextValue.trim();
}

export function formatCVC(value, number) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (number) {
    const issuer = Payment.fns.cardType(number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
  }

  return clearValue;
}
