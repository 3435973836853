import { ReactComponent as BrowserIcon } from "assets/icons/browser.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import { Avatar } from "components/Avatar";
import { Button } from "components/UIKit/Button";
import { useModal } from "hooks/useModal";
import { connectPath } from "pages/Connect";
import { useNavigate } from "react-router-dom";
import { therapistProfileService } from "services/therapistProfileService";
import { getWebsiteAddress } from "utils/getWebsiteAddress";
import { therapistName } from "utils/therapistName";
import { useSnapshot } from "valtio";
import "./TherapistLeftSide.scss";

export const TherapistLeftSide = () => {
  const navigate = useNavigate();
  const { open } = useModal("SHARE");
  const { currentProfile } = useSnapshot(therapistProfileService);
  const withStatus =
    currentProfile && currentProfile?.online_chat_sessions_availability;

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        url: window.location.href,
        title: `Therapist ${therapistName({
          title: currentProfile?.title,
          first_name: currentProfile?.user.first_name,
          last_name: currentProfile?.user.last_name,
        })}`,
      });
    } else {
      open();
    }
  };

  if (currentProfile === null) return null;

  return (
    <aside className="therapist-left-side">
      <Avatar
        className="therapist-left-side__img"
        id={currentProfile.id}
        file={currentProfile.avatar}
        alt={therapistName({
          first_name: currentProfile.user.first_name,
          last_name: currentProfile.user.last_name,
        })}
        onClick={() =>
          navigate(`${connectPath}?therapistId=${currentProfile.id}`)
        }
        chat_status={withStatus ? currentProfile.chat_status : undefined}
        avatarSize={"100%"}
      />

      <Button
        className="w-100 therapist-left-side__button"
        color="outline-gray"
        size="sm"
        onClick={handleShare}
      >
        <ShareIcon />
        Share
      </Button>
      {currentProfile?.website && (
        <Button
          className="w-100 therapist-left-side__button"
          color="outline-gray"
          size="sm"
          isLink
          href={getWebsiteAddress(currentProfile?.website)}
          target="_blank"
        >
          <BrowserIcon />
          Website
        </Button>
      )}
    </aside>
  );
};
