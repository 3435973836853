import { Cookies } from "react-cookie";
import {
  Address,
  CheckoutSubmit,
  PaymentData,
  Pet,
  PetInfo,
} from "services/CheckoutSubmit";
import {
  GetCustomerDataArgs,
  GetCustomerDataResult,
  connectService,
} from "services/connectService";
import {
  TherapistProfile,
  therapistProfileService,
} from "services/therapistProfileService";
import { therapassHTTPClient } from "utils/defaultHTTPClient";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { CheckoutSubmitResult } from "./CheckoutSubmitResult";
import { CompleteSurveySubmitResult } from "./CompleteSurveySubmitResult";
import { HttpService } from "./httpService";
import { SubmitPetInfoResult } from "./SubmitPetInfoResult";
import { TransactionSubmit } from "./TransactionSubmit";
import { visitorIdService } from "./visitoridService";

export type ProductTypes =
  | "assistance_animal_rights_attorney_protection"
  | "landlord_guaranteed_acceptance_insurance"
  | "assistance_animal_gear"
  | "esa_letter"
  | "multi_esa_letter";

export type Product = {
  product_type: ProductTypes;
  key: string;
  name: string;
  price: number;
  headline: string;
  cartline1: string;
  cartline2: string;
  icon: string;
  byline: string;
  isletter: boolean;
  description: string;
  bylinePart1: string;
  bylinePart2: string;
  pagetitle: string;
  Bestdeal: boolean;
  PriceByLine: string;
  ProductNamePart1: string;
  ProductNamePart2: string;
};
export type GetProductArgs = {
  therapistKey: string;
  visitorId: string;
  state: string;
  cartid: string;
};
export type ProductResult = {
  products: Product[];
};

class FinalCheckoutService extends HttpService {
  public pets: Pet[] = [];

  public chosenLetter = "esa_letter";

  public chosenOffers: string[] = [];

  availableOffers: readonly string[] = [
    "assistance_animal_rights_attorney_protection",
    // "assistance_animal_gear",
    "esa_letter",
    "multi_esa_letter",
  ];

  public shippingAddress: Address | null = null;

  public paymentData: PaymentData = {
    cardholder_name: "",
    card_number: "",
    exp_date: "",
    cvv_code: "",
  };

  public therapist: TherapistProfile | null = null;

  public paymentStatus: "success" | "error" | null = null;

  public paymentMessage: string | null = null;

  public visitorID: string | null = null;

  public ClientID: number | null = null;

  public orderId: number | null = null;

  public CartID: string | null = null;

  public Token: string | null = null;

  public pagetitle = "";

  public productData: Map<string, Product> = new Map<string, Product>();

  public visitorEmail: string | null = null;

  private counter = 1;

  addPet(petInfo: PetInfo) {
    this.pets = [...this.pets, { id: this.counter, ...petInfo }];
    this.counter += 1;
  }

  setChosenLetter(product: string) {
    this.chosenLetter = product;
  }

  editPet(id: number, petInfo: PetInfo) {
    const index = this.pets.findIndex((pet) => pet.id === id);

    if (index < 0) return;

    this.pets = [
      ...this.pets.slice(0, index),
      { id: this.pets[index].id, ...petInfo },
      ...this.pets.slice(index + 1),
    ];
  }

  removePet(id: number) {
    this.pets = this.pets.filter((pet) => pet.id !== id);
    visitorIdService.trackEvent("removePet", "", "", "", "");
  }

  deSelectOffer(product_key: string) {
    let idx = this.chosenOffers.indexOf(product_key, 0);
    while (idx > -1) {
      this.chosenOffers.splice(idx, 1);
      idx = this.chosenOffers.indexOf(product_key, 0);
    }
  }

  isOfferSelected(product_key: string) {
    return this.chosenOffers.indexOf(product_key) > -1;
  }

  selectOffer(product_key: string) {
    if (!this.isOfferSelected(product_key)) {
      visitorIdService.trackEvent("add-to-cart", product_key, "", "", "");
      this.chosenOffers.push(product_key);
    }
  }

  setShippingAddress(address: Address) {
    this.shippingAddress = { ...address };
  }

  async getProductData(args: GetProductArgs) {
    const result = (await this.http.post(
      "/connect/getproducts/",
      args
    )) as ProductResult;

    this.productData = new Map<string, Product>();
    result.products.forEach((product: Product) => {
      this.productData.set(product.product_type, product);
    });
  }

  async setTherapistID(therapistid: string) {
    await therapistProfileService.getById(therapistid);
    this.therapist = therapistProfileService.currentProfile;
  }

  async setPaymentData(paymentData: PaymentData) {
    this.paymentData = paymentData;

    if (this.paymentData.exp_date === "12/12") {
      this.paymentStatus = "error";
    } else {
      this.paymentStatus = "success";
    }
  }

  async submitPetsInfo(): Promise<SubmitPetInfoResult> {
    const result = new SubmitPetInfoResult();
    let { visitorID } = this;
    visitorIdService.trackEvent("submitPetsInfo", "", "", "", "");
    if (!visitorID) {
      visitorID = await visitorIdService.getVisitorID(false);
      this.visitorID = visitorID;
    }
    await this.http.post("/connect/CaptureAnimals/", {
      visitorid: visitorID,
      pets: this.pets,
    });
    const completeResult = (await this.http.post("/connect/CompleteSurvey/", {
      visitorid: visitorID,
    })) as CompleteSurveySubmitResult;
    if (completeResult.Redirect) {
      result.Redirect = true;
    }

    return result;
  }

  async submitPaymentData(): Promise<{ success: boolean; message: string }> {
    // console.log(this.visitorEmail);
    let result = new CheckoutSubmitResult();
    const returnData = { success: false, message: "" };

    try {
      const checkoutSubmit = new CheckoutSubmit();
      const transactionSubmit = new TransactionSubmit();

      const vid = await visitorIdService.getVisitorID(false);
      this.visitorID = vid;
      const cook = new Cookies();
      this.visitorEmail = cook.get("visitorEmail");
      checkoutSubmit.visitorid = vid;
      checkoutSubmit.address = this.shippingAddress;

      checkoutSubmit.letter = this.chosenLetter;
      checkoutSubmit.offers = this.chosenOffers;
      checkoutSubmit.pets = this.pets;
      checkoutSubmit.paymentData = this.paymentData;

      result = (await this.http.post(
        "/connect/CaptureOrder/?id=v1",
        checkoutSubmit
      )) as CheckoutSubmitResult;

      if (result.success && result.PaymentMode !== 5) {
        this.ClientID = result.customerid;
        const customerData: GetCustomerDataArgs = {
          CartID: result.cartid,
          VisitorID: vid,
          PersonID: result.customerid,
          SettingName: "usertoken",
        };
        connectService
          .getCustomerData(customerData)
          .then((response: GetCustomerDataResult) => {
            if (response.Success) {
              this.Token = response.Value;
            }
          });
        this.CartID = result.cartid;
        this.paymentStatus = "success";
        this.orderId = result.orderid;
        if (this.visitorEmail == null || this.visitorEmail === "") {
          this.visitorEmail = result.email;
        }
        transactionSubmit.visitorid = vid;
        transactionSubmit.visitorEmail = this.visitorEmail || result.email;
        transactionSubmit.address = this.shippingAddress;
        transactionSubmit.offers = this.chosenOffers;
        transactionSubmit.paymentData = this.paymentData;
        transactionSubmit.visitorPhone = result.mobilenumber;
        transactionSubmit.orderid = result.orderid;
        await therapassHTTPClient.post(
          "/api/v1/esa-transaction/",
          transactionSubmit
        );
        returnData.success = true;
        returnData.message = "We have successfully processed your payment.";
      } else if (result.success && result.PaymentMode === 5) {
        returnData.success = false;
        returnData.message =
          "We were not able to identify a free time to schedule your session.  You have not been charged.";
      } else {
        returnData.success = false;
        returnData.message = result.errormessage;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.status === 404) {
        throw e;
      }
      returnData.success = false;
      returnData.message = e.message;
    }
    return returnData;
  }
}

export const finalCheckoutService = proxy(new FinalCheckoutService());

devtools(finalCheckoutService, { name: "finalCheckoutService" });
