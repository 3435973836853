import cn from "classnames";
import { FormatProfileField } from "components/FormatProfileField";
import { FC } from "react";
import { FCWithChildren } from "types/react.types";

interface Props {
  className: string;
  description?: string;
}

export const OfferDescription: FCWithChildren<Props> = ({
  className,
  description,
}) => {
  if (description == null || description === undefined) {
    return <></>;
  }
  const parts = description.split("|");
  return (
    <>
      {parts.map((part) => (
        <div key={part}>
          <FormatProfileField
            className={className}
            maxLength={1000}
            isExpandable
            fallbackText="No description added yet"
          >
            {part}
          </FormatProfileField>
        </div>
      ))}
    </>
  );
  return <></>;
};
