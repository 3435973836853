import { useFormPersist } from "hooks/useFormPersist";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { connectPath } from "pages/Connect/Connect";
import { successPath } from "pages/Connect/Success";
import { funnelPath } from "pages/Funnel";
import { useMemo, useState } from "react";
import { Cookies } from "react-cookie";
import { SubmitHandler } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { SetCustomerDataArgs, connectService } from "services/connectService";
import { therapistProfileService } from "services/therapistProfileService";
import { visitorIdService } from "services/visitoridService";
import { therapassHTTPClient } from "utils/defaultHTTPClient";
import { errorPath } from "./Error";

export type ConnectFormValues = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  agreeDisclosure: boolean;
  secondaryAgreeDisclosure: boolean;
  therapist_key: string | null;
  visitorid: string;
  password: string;
  confirmPassword: string;
};

const defaultValues: ConnectFormValues = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  agreeDisclosure: false,
  secondaryAgreeDisclosure: false,
  therapist_key: "",
  visitorid: "",
  password: "",
  confirmPassword: "",
};

export const CONNECT_FORM_STORAGE_KEY = "connectData";

export const useConnect = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigateWithQuery();
  const therapistId = useMemo(
    () => searchParams.get("therapistId"),
    [searchParams]
  );
  const [showSpinner, setShowSpinner] = useState(false);

  const methods = useFormPersist(CONNECT_FORM_STORAGE_KEY, {
    defaultValues,
  });

  const onSubmit: SubmitHandler<ConnectFormValues> = (data) => {
    try {
      visitorIdService.getVisitorID(false).then((vid) => {
        const submitConnectData = data;
        submitConnectData.visitorid = vid;
        submitConnectData.therapist_key = therapistId;

        // create user in therapass
        if (vid) {
          if (therapistId) {
            setShowSpinner(true);
            visitorIdService.trackEvent(
              "Connect",
              "Submit",
              therapistId,
              "",
              ""
            );
            const cook = new Cookies();
            cook.set("visitorEmail", data.email, { path: "/" });
            therapistProfileService.getById(therapistId).then((therapist) => {
              connectService
                .submitConnect(submitConnectData)
                .then((response: any) => {
                  if (response.Success) {
                    const esaClientId = response.CustomerID;
                    therapassHTTPClient
                      .post("/api/v1/esauser/", {
                        firstName: data.first_name,
                        lastName: data.last_name,
                        mobilePhone: data.phone,
                        email: data.email,
                        esaClientId,
                        therapistId: therapist.user.email,
                      })
                      .then((therapassResponse: any) => {
                        if (therapassResponse.success) {
                          const CustomerData: SetCustomerDataArgs = {
                            VisitorID: vid,
                            CartID: response.CartID,
                            PersonID: esaClientId,
                            SettingName: "usertoken",
                            SettingValue: "token",
                          };
                          connectService.setCustomerData(CustomerData);
                          setShowSpinner(false);
                          /*     if (response.Redirect) {
                            navigate(
                              `${funnelPath}`,
                              `therapistId=${response.TherapistKey}&visitorid=${response.VisitorID}`
                            );
                          } else { */
                          navigate(
                            `${connectPath}${successPath}`,
                            `therapistId=${response.TherapistKey}&visitorid=${response.VisitorID}`
                          );
                          // }
                        } else {
                          setShowSpinner(false);
                          navigate(`${connectPath}${errorPath}`);
                        }
                      });
                  } else {
                    setShowSpinner(false);
                    navigate(`${connectPath}${errorPath}`);
                  }
                });
            });
          }
        } else {
          setShowSpinner(false);
          console.error("Error sending data", "Failed to get vid");
        }
      });
    } catch (error) {
      setShowSpinner(false);
      console.error("Error sending data", error);
    }
  };

  return { ...methods, onSubmit, showSpinner };
};
