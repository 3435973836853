import { HttpService } from "services/httpService";
import { Pagination } from "types/pagination.types";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";

// Dictionaries are lists of choices that are to be FETCHED. We DO NOT KNOW the available codes, we CAN NOT hardcode them
export type DictionaryItem<C = string> = {
  code: C;
  name: string;
  order?: number;
};

const IssuseURL = "/Dictionary/issues";
const LanguagesURL = "/Dictionary/languages";
const TherapiesURL = "/Dictionary/therapies";
const AlliedGroupsURL = "/Dictionary/allied_groups";
const ProfessionalSpecialtiesURL = "/Dictionary/professional_specialties";

type CacheEntry = {
  data: DictionaryItem[] | null;
  request: Promise<DictionaryItem[]> | null;
  error: string | null;
};
type Cache = Record<string, CacheEntry>;

class DictionaryService extends HttpService {
  private cache: Cache = {};

  private static prefix = "/Dictionary";

  private static cacheExpirationTime = 1000 * 60 * 5; // 5 minutes

  clearCache(key?: string) {
    if (key) {
      this.cache[key].data = null;
    } else {
      this.cache = {};
    }
  }

  async fetchDictionary(
    endpoint: string,
    fullURL: boolean
  ): Promise<DictionaryItem[]> {
    if (!(endpoint in this.cache)) {
      this.cache[endpoint] = {
        data: null,
        request: null,
        error: null,
      };
    }
    const cacheEntry = this.cache[endpoint];

    if (cacheEntry.data) {
      return cacheEntry.data;
    }

    if (!cacheEntry.request) {
      cacheEntry.request = this.http
        .get<DictionaryItem, Pagination<DictionaryItem>>(
          fullURL ? endpoint : `${DictionaryService.prefix}${endpoint}/`
        )
        .then((res) => {
          setTimeout(
            () => this.clearCache(endpoint),
            DictionaryService.cacheExpirationTime
          );
          return res.results;
        })
        .catch((error) => {
          cacheEntry.data = null;
          cacheEntry.error = (error as Error).message;
          throw error;
        })
        .finally(() => {
          cacheEntry.request = null;
        });
    }

    return cacheEntry.request;
  }

  async getIssues(): Promise<DictionaryItem[]> {
    return this.fetchDictionary(IssuseURL, true);
  }

  async getSpecialties(): Promise<DictionaryItem[]> {
    return this.fetchDictionary(IssuseURL, true); // they are the same, but two different functions are for "encapsulation"
  }

  async getLanguages(): Promise<DictionaryItem[]> {
    return this.fetchDictionary(LanguagesURL, true);
  }

  async getTherapies(): Promise<DictionaryItem[]> {
    return this.fetchDictionary(TherapiesURL, true);
  }

  async getAlliedGroups(): Promise<DictionaryItem[]> {
    return this.fetchDictionary(AlliedGroupsURL, true);
  }

  async getHealthInsuranceCompanies(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/health_insurance_companies", false);
  }

  async getInsuranceCompanies(): Promise<DictionaryItem[]> {
    return this.fetchDictionary("/insurance_companies", false);
  }

  async getProfessionalSpecialties(): Promise<DictionaryItem[]> {
    return this.fetchDictionary(ProfessionalSpecialtiesURL, true);
  }

  async createHealthInsuranceCompany(name: string) {
    const response: DictionaryItem = await this.http.get(
      "/Dictionary/health_insurance_companies/"
    );

    return response;
  }
}

export const dictionaryService = proxy(new DictionaryService());

devtools(dictionaryService, { name: "dictionaryService" });
