import { ReactComponent as CloseIcon } from "assets/icons/close-square.svg";
import cn from "classnames";
import { Modal } from "components/Modal";
import { Button } from "components/UIKit/Button";
import { Input } from "components/UIKit/Input";

import { useModal } from "hooks/useModal";
import { FC, MouseEvent } from "react";

import styles from "./ShareThisProfilePopup.module.scss";

export const ShareThisProfilePopup: FC = () => {
  const { isOpen, close } = useModal("SHARE");

  const onClickCopy = (e: MouseEvent) => {
    const target = e.target as HTMLButtonElement;
    const input = target.previousSibling?.firstChild
      ?.firstChild as HTMLInputElement;
    input.select();
    navigator.clipboard.writeText(input.value);
  };

  return (
    <Modal visible={isOpen} onClose={close}>
      <div className={cn(styles.popup)}>
        <button className={styles.popupCloseButton} onClick={close}>
          <CloseIcon />
        </button>
        <h3 className={styles.popupTitle}>Share this profile</h3>
        <sub className={styles.popupSubtitle}>Link to therapist</sub>

        <div className="row">
          <div className="col">
            <Input readOnly value={window.location.href} />
          </div>
          <div className="col-xs-auto">
            <Button
              className={cn(styles.popupButton, "mt-15", "mt-xs-0")}
              onClick={onClickCopy}
            >
              Copy link
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
