import { ReactComponent as EditIcon } from "assets/icons/pencil.svg";

import cn from "classnames";
import { AddPetButton } from "components/UIKit/AddPetButton";
import { Button } from "components/UIKit/Button";
import { Input } from "components/UIKit/Input";
import { PetCard } from "components/UIKit/PetCard";
import { RadioAnimal } from "components/UIKit/RadioAnimal";
import { useMobileResponsive, useTabletResponsive } from "hooks/useResponsive";
import { useTitle } from "hooks/useTitle";
import { funnelPath } from "pages/Funnel";
import { homePath } from "pages/Home";
import { ChangeEvent, FC, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PetInfo, PetKind } from "services/CheckoutSubmit";
import { finalCheckoutService } from "services/finalCheckoutService";
import { visitorIdService } from "services/visitoridService";
import { useSnapshot } from "valtio";

import { REQUIRED_MESSAGE } from "../../constants";
import styles from "../../FinalCheckout.module.scss";
import { StepProps } from "../../types";

type Errors = {
  kind?: string;
  name?: string;
  weight?: string;
};

type Values = {
  id?: number;
  kind?: PetKind;
  name?: string;
  weight?: number;
};

export const PetsPage: FC<StepProps> = ({ handleNextStep }) => {
  const { pets } = useSnapshot(finalCheckoutService);
  const petsClass = pets.length === 1 ? "col-12 YY" : "col-6 XX";
  const [mode, setMode] = useState<"list" | "form">(
    pets.length > 0 ? "list" : "form"
  );
  const [currentPetId, setCurrentPetId] = useState<number | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<PetInfo>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const therapistId = useMemo(() => {
    const id = searchParams.get("therapistId");
    if (!id) {
      navigate(homePath);
    }
    return id;
  }, [searchParams, navigate]);

  const visitorid = useMemo(
    () => searchParams.get("visitorid"),
    [searchParams]
  );
  visitorIdService.setVisitorID(visitorid);

  const onSubmit = (values: PetInfo) => {
    if (therapistId) {
      finalCheckoutService.setTherapistID(therapistId);
    }
    if (currentPetId) {
      finalCheckoutService.editPet(currentPetId, values);
    } else {
      finalCheckoutService.addPet(values);
    }
    setMode("list");
  };

  const onNext = () => {
    finalCheckoutService.submitPetsInfo().then((submitPetResult) => {
      handleNextStep();
      /*
      if (submitPetResult.Redirect) {
        console.log("Hello");
      } else {
        handleNextStep();
      } */
    });
  };

  const { isMobile } = useMobileResponsive();
  const { isTablet } = useTabletResponsive();

  useTitle("Final Checkout | Pet Info");

  if (mode !== "list")
    return (
      <div className={styles.root}>
        <h1 className={styles.title}>
          {currentPetId === null ? "Enter your pet info" : "Edit your pet info"}
        </h1>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputs}>
            <Controller
              control={control}
              name="kind"
              rules={{ required: REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <>
                  <div className={styles.row}>
                    <RadioAnimal
                      name="kind"
                      value="dog"
                      label="Dog"
                      cat={false}
                      dog={true}
                      checked={field.value === "dog"}
                      onChange={() => field.onChange("dog")}
                    />
                    {fieldState.error?.message && (
                      <p>{fieldState.error.message}</p>
                    )}
                  </div>
                  <div className={styles.row}>
                    <RadioAnimal
                      name="kind"
                      value="cat"
                      label="Cat"
                      cat={true}
                      dog={false}
                      checked={field.value === "cat"}
                      onChange={() => field.onChange("cat")}
                    />
                  </div>
                </>
              )}
            />
            <div className={styles.row}>
              <Input
                {...register("name")}
                className={styles.input}
                placeholder="Pet name"
                error={errors.name?.message}
              />
            </div>
            <div className={styles.row}>
              <Input
                {...register("weight")}
                type="number"
                className={styles.input}
                placeholder="Pet weight in lbs"
                error={errors.weight?.message}
              />
            </div>

            <div className={cn(styles.row, styles.reverseMobile)}>
              {pets.length > 0 && (
                <Button
                  color="green-light"
                  className={styles.input}
                  onClick={() => {
                    setMode("list");
                  }}
                >
                  Back
                </Button>
              )}
              <Button type="submit" className={styles.input}>
                Next
              </Button>
            </div>
          </div>
        </form>
      </div>
    );

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Review Your Pet Info</h1>
      {(window.outerWidth < 1920 && (
        <div className={styles.content}>
          {pets.length === 0 && <div className={styles.empty}>No pets yet</div>}
          {pets.map((pet) => (
            <div style={{ paddingTop: "2rem" }}>
              <PetCard
                key={pet.id}
                values={[
                  { caption: "Type of Pet", text: pet.kind },
                  { caption: "Your Pet's Name", text: pet.name },
                  { caption: "Your Pet's Weight", text: `${pet.weight} lbs` },
                ]}
                icon={pet.kind}
                petCount={pets.length}
                editIcon={<EditIcon />}
                onRemove={() => finalCheckoutService.removePet(pet.id)}
                onEdit={() => {
                  reset(pet);
                  setMode("form");
                  setCurrentPetId(pet.id);
                }}
              />
            </div>
          ))}
        </div>
      )) ||
        (isTablet && (
          <div className={styles.content}>
            <div className="row">
              {pets.length === 0 && (
                <div className={styles.empty}>No pets yet</div>
              )}
              {pets.map((pet) => (
                <div className="">
                  <div style={{ paddingTop: "2rem" }}>
                    <PetCard
                      key={pet.id}
                      values={[
                        { caption: "Type of Pet", text: pet.kind },
                        { caption: "Your Pet's Name", text: pet.name },
                        {
                          caption: "Your Pet's Weight",
                          text: `${pet.weight} lbs`,
                        },
                      ]}
                      petCount={pets.length}
                      icon={pet.kind}
                      editIcon={<EditIcon />}
                      onRemove={() => finalCheckoutService.removePet(pet.id)}
                      onEdit={() => {
                        reset(pet);
                        setMode("form");
                        setCurrentPetId(pet.id);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )) || (
          <div className={styles.content} style={{ width: "38%" }}>
            <div className="row">
              {pets.length === 0 && (
                <div className={styles.empty}>No pets yet</div>
              )}
              {pets.map((pet) => (
                <div className={petsClass}>
                  <div style={{ paddingTop: "2rem" }}>
                    <PetCard
                      key={pet.id}
                      values={[
                        { caption: "Type of Pet", text: pet.kind },
                        { caption: "Your Pet's Name", text: pet.name },
                        {
                          caption: "Your Pet's Weight",
                          text: `${pet.weight} lbs`,
                        },
                      ]}
                      petCount={pets.length}
                      icon={pet.kind}
                      editIcon={<EditIcon />}
                      onRemove={() => finalCheckoutService.removePet(pet.id)}
                      onEdit={() => {
                        reset(pet);
                        setMode("form");
                        setCurrentPetId(pet.id);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      {/* <div className={styles.content} style={{ display: "flex", width: "39%" }}>
        {pets.length === 0 && <div className={styles.empty}>No pets yet</div>}
        {pets.map((pet) => (
          <PetCard
          	key={pet.id}
          	values={[
          		{ caption: "Type of Pet", text: pet.kind },
          		{ caption: "Your Pet's Name", text: pet.name },
          		{ caption: "Your Pet's Weight", text: `${pet.weight} lbs` },
          	]}
          	icon={pet.kind}
          	editIcon={<EditIcon />}
          	onRemove={() => finalCheckoutService.removePet(pet.id)}
          	onEdit={() => {
          		reset(pet);
          		setMode("form");
          		setCurrentPetId(pet.id);
          	}}
          />
        ))}
      </div> */}
      <div style={{ paddingTop: "3rem" }}>
        <AddPetButton
          type="button"
          onClick={() => {
            reset({});
            setMode("form");
            setCurrentPetId(null);
          }}
        >
          Add Pet
        </AddPetButton>
      </div>
      <div className={styles.buttons}>
        {/* <Button
          	color="green-light"
          	className={styles.input}
          	onClick={() => {
          		const url = `${funnelPath}/18?therapistId=${therapistId}&visitorid=${visitorid}`;
          		navigate(url);
          	}
          }>
          	Back
          </Button> */}
        <Button onClick={onNext}>Next</Button>
      </div>
    </div>
  );
};
