import appInPhoneImg from "assets/images/app-in-phone.png";
import bottomLeftImg from "assets/images/bottom-left.svg";
import phoneCallImg from "assets/images/call.svg";
import logoImg from "assets/images/LogoTheraPass.svg";
import messageImg from "assets/images/message.svg";
import topRightImg from "assets/images/top-right.svg";
import videoCallImg from "assets/images/video.svg";
import { InputSmartSearch } from "components/UIKit/InputSmartSearch";
import { GlobalContext } from "GlobalProvider";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connectService } from "services/connectService";
import { listingService } from "services/listingService";
import { systemSettingsService } from "services/systemSettingsService";
import { visitorIdService } from "services/visitoridService";

export const glV3aPath = "/gl-v3a";

const GlV3a: React.FC = () => {
  const navigate = useNavigate();
  const { setGlobalState } = React.useContext(GlobalContext);
  const [searchUrl, setSearchUrl] = useState("/listing");
  const [autoAssignMode, setAutoAssignMode] = useState(false);
  const [autoAssignTherpist, setAutoAssignTherapist] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const queryNavigate = useNavigateWithQuery();

  // const checkRedirect = async () => {
  //   const value = await connectService.getInterstitialMode();

  //   const url =
  //     value === "aftersearchbeforeresults" ||
  //     value === "aftersearchinplaceofresults"
  //       ? "/interstitial"
  //       : "/listing";
  //   setSearchUrl(url);
  // };
  // useEffect(() => {
  //   checkRedirect();
  // }, []);

  const handleSelectPlace = (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
  }) => {
    listingService.setPlaceFilter({
      ...(args?.city && { city: args.city }),
      ...(args?.search && { search: args.search }),
      ...(args?.state && { state: args.state }),
      ...(args?.zip && { zip: args.zip }),
      withoutRequest: true,
    });
  };

  useEffect(() => {
    if (!autoAssignMode) {
      systemSettingsService.get().then((res: any) => {
        if (res.auto_assign_therapist) {
          setAutoAssignTherapist(true);
        }
      });
    }
    setAutoAssignMode(true);
  }, [autoAssignMode, setAutoAssignMode]);

  const checkRedirect = async () => {
    const value: any = await systemSettingsService.get();
    if (value.auto_assign_therapist) {
      let nextPath = "";
      const query = listingService.getQuery();

      connectService.getTherapist(query, true).then((therapist: any) => {
        if (therapist["ESAStatus"] >= 1) {
          nextPath = `/connect`;
          const therapistKey = therapist["id"].toString();
          queryParams.set("therapistId", therapistKey);
          const therapistQuery = `?therapistId=${therapistKey}`;
          visitorIdService.trackEvent(
            "Home",
            "Exit",
            nextPath,
            therapistQuery,
            ""
          );
          queryNavigate(nextPath, therapistQuery);
        } else {
          navigate(searchUrl, {
            state: { withScroll: false },
          });
        }
      });
    } else {
      navigate(searchUrl, {
        state: { withScroll: false },
      });
    }
  };

  useEffect(() => {
    handleSelectPlace({ search: "" });
    // checkRedirect();
  }, []);

  return (
    <div className="returning">
      <div className="main-content">
        <div
          className="top-right-img"
          style={{
            background: `url(${topRightImg})`,
            backgroundPosition: "top right",
            backgroundSize: "cover",
          }}
        />
        <div
          className="bottom-left-img"
          style={{
            background: `url(${bottomLeftImg})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        />

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <div className="custom-container">
            <div className="content">
              <Link to="/" className="logo">
                <img src={logoImg} alt="TheraPass" />
              </Link>

              <div className="d-flex flex-column flex-md-row justify-content-md-between wrap">
                <div className="left-wrap">
                  <h1 className="title">GET YOUR ESA LETTER THREE WAYS</h1>
                  <p className="desc">
                    With TheraPass, you can instantly connect with an ESA
                    therapist of your choice through text message, phone, video,
                    or mobile video to obtain your ESA letter. You'll receive
                    your ESA letter in PDF format without any waiting time, and
                    it's issued by a state-licensed therapist, making it valid
                    for both housing and travel purposes.
                  </p>

                  <div className="d-flex justify-content-between justify-content-sm-around justify-content-md-start communication">
                    <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between item">
                      <img
                        src={messageImg}
                        alt="Message"
                        width={24}
                        height={24}
                      />
                      <div className="text">Text Message</div>
                    </div>

                    <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between item">
                      <img
                        src={videoCallImg}
                        alt="Video"
                        width={24}
                        height={24}
                      />
                      <div className="text">Video Call</div>
                    </div>

                    <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between item">
                      <img
                        src={phoneCallImg}
                        alt="Phone"
                        width={24}
                        height={24}
                      />
                      <div className="text">Phone Call</div>
                    </div>
                  </div>

                  <div className="search-wrap">
                    <InputSmartSearch
                      placeholder="Enter your City / Zip"
                      onSelectPlace={handleSelectPlace}
                      maxLength={200}
                      max={200}
                      withButton
                      buttonClass="btn-connect"
                      className="input"
                      buttonLabel="CONNECT - GET ESA NOW"
                      onButtonClick={() => {
                        setGlobalState({ prevPage: window.location.pathname });
                        checkRedirect();
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center justify-content-md-end">
                  <img src={appInPhoneImg} alt="App in phone" />
                </div>
              </div>

              <div className="search-wrap my-40 d-md-none">
                <InputSmartSearch
                  placeholder="Enter your City / Zip"
                  onSelectPlace={handleSelectPlace}
                  maxLength={200}
                  max={200}
                  withButton
                  buttonClass="btn-connect"
                  className="input"
                  buttonLabel="CONNECT - GET ESA NOW"
                  onButtonClick={() => {
                    setGlobalState({ prevPage: window.location.pathname });
                    checkRedirect();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="custom-container d-flex justify-content-around flex-wrap">
          <div className="item-space-between">
            <div className="title">2 Million+</div>
            <div className="desc">Mental Health Assessments</div>
          </div>
          <div className="item-space-between">
            <div className="title">600K+</div>
            <div className="desc">Mental Health Professionals</div>
          </div>
          <div className="item-space-between">
            <div className="title">6+</div>
            <div className="desc">Years in Business</div>
          </div>
          <div className="item-space-between">
            <div className="title">200K+</div>
            <div className="desc">Satisfied Customers</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default GlV3a;
