import { useLocation, useNavigate } from "react-router-dom";
import { visitorIdService } from "services/visitoridService";

export const useNavigateWithQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (pathname: string, query?: string) => {
    visitorIdService.trackEvent(
      "Navigate",
      pathname,
      query ?? location.search,
      "",
      ""
    );

    navigate({ pathname, search: query ?? location.search });
  };
};
