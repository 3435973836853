import MapMarker from "assets/icons/location-marker.svg";
import { FormatProfileField } from "components/FormatProfileField";
import { Map } from "components/Map";
import { therapistProfileService } from "services/therapistProfileService";
import { areaToString } from "utils/areaToString";
import { useSnapshot } from "valtio";
import styles from "./TherapistMapList.module.scss";

const mapStyles = {
  height: 233,
  borderRadius: 20,
  width: "100%",
  marginTop: 25,
  marginBottom: 24,
};

export const TherapistMapList = () => {
  /* isEsaTherapist: boolean | null */
  const { currentProfile } = useSnapshot(therapistProfileService);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { city, state, zip_code, street_address, latitude, longitude } =
    currentProfile?.business_info || {};
  const hasAddress = city || state || zip_code || street_address;
  const displayingMap = latitude && longitude && hasAddress;
  let location;

  if (currentProfile === null) return null;

  const showBusinessAddress =
    currentProfile.business_info != null &&
    currentProfile.business_info.city !== "";
  const showHomeAddress = !showBusinessAddress && currentProfile != null;

  if (currentProfile?.business_info?.company_name && showBusinessAddress) {
    location = areaToString(currentProfile.business_info, true);
  }
  if (!currentProfile?.hide_personal_address && showHomeAddress) {
    location = areaToString(currentProfile, true);
  }

  return (
    <ul className={styles.root}>
      {(showBusinessAddress || showHomeAddress) && (
        <li className={styles.item}>
          <p className={styles.title}>Address</p>
        </li>
      )}

      {currentProfile?.business_info?.company_name && showBusinessAddress && (
        <li>
          <p className={styles.text}>
            <FormatProfileField maxLength={60}>
              {currentProfile.business_info.company_name}
            </FormatProfileField>
          </p>
          {location?.streetAddress && (
            <p className={styles.text}>{location.streetAddress}</p>
          )}
          {location?.csz && <p className={styles.text}>{location.csz}</p>}
        </li>
      )}
      {!currentProfile?.hide_personal_address && showHomeAddress && (
        <li className={styles.item}>
          {location?.streetAddress && (
            <p className={styles.text}>{location.streetAddress}</p>
          )}
          {location?.csz && <p className={styles.text}>{location.csz}</p>}
        </li>
      )}
    </ul>
  );
};
