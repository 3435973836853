import { Footer } from "components/Footer/Footer";
import { LandingHeader } from "components/LandingHeader/LandingHeader";
import styles from "./TermsOfUse.module.scss";

export const termsOfUsePath = "/terms";

export const TermsOfUse = () => {
  return (
    <div className={styles.root}>
      <LandingHeader />
      <main className={styles.main}>
        <h1 className={styles.title}>Terms and Conditions</h1>
        <section className={styles.section}>
          <p>Last Modified: August 16, 2023</p>
          <p>
            These terms and conditions are entered into by and between you (“End
            User,” “you,” or “your”) and TheraPass, Inc. (“ESA TheraPass,” “we,”
            “our,” or “us”) and govern your access to and use of the ESA
            TheraPass platform (the “Platform”), including any content,
            functionality and services offered on or through the Platform.
            Unless the context clearly suggests otherwise, the terms “End User,”
            “you,” or “your” include all individuals accessing and using the
            Platform.
          </p>
          <p>
            The following terms and conditions, together with our Privacy
            Policy, available{" "}
            <a href="https://esa.therapass.com/privacy">
              https://esa.therapass.com/privacy
            </a>{" "}
            (“Privacy Policy”), collectively constitute the entire “Terms of
            Use” between you and ESA TheraPass.
          </p>
          <p>
            Please read these Terms of Use carefully before you start to use the
            Platform. BY USING THE PLATFORM YOU ACCEPT AND AGREE TO BE BOUND AND
            ABIDE BY THE TERMS OF USE. IN ACCEPTING THESE TERMS OF USE, YOU: (A)
            ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS OF USE;
            (B) CONSENT TO THESE TERMS OF USE; AND (C) AGREE TO BE LEGALLY BOUND
            BY THESE TERMS OF USE. IF YOU DO NOT TO AGREE TO THESE TERMS OF USE
            OR THE PRIVACY POLICY, PLEASE DO NOT ACCESS OR USE THE PLATFORM.
          </p>
        </section>
        <section className={styles.section}>
          <h2>1. ESA THERAPASS SERVICES</h2>
          <p>
            The Platform provides you a choice of two different professional
            assessment services that determine your eligibility for an emotional
            support animal (“ESA”) and to obtain a letter from a licensed health
            professional (“Provider”) attesting to the results of the
            assessment:
          </p>

          <ul>
            <li>
              <p>
                <b>ESA Recommendation Letter</b> – Through the ESA
                recommendation service offering, you will complete an assessment
                based on the American Psychiatric Association’s Diagnostic and
                Statistical Manual of Mental Disorders, Fifth Edition, Text
                Revision (“Assessment”), and the results of the Assessment will
                be reviewed and evaluated by a Provider licensed in the
                appropriate jurisdiction. If the Provider determines that, based
                on the results of the Assessment, an ESA would alleviate one or
                more symptoms or effect’s associated with a disability that
                correlates to the Assessment results, then the Provider will
                provide a letter recommending the use of an ESA. This service
                offering does not provide you with a real time consultation with
                a Provider and the ESA recommendation letter is solely the
                result of the Provider’s review and professional interpretation
                of the Assessment results.
              </p>
            </li>
            <li>
              <p>
                <b>ESA Diagnosis Letter</b> – Through the ESA diagnosis service
                offering, you will complete an Assessment and a Provider
                licensed in the appropriate jurisdiction will engage you in a
                real time telephone or video consultation to determine if an ESA
                would alleviate one or more symptoms or effect’s associated with
                a disability identified through the Assessment and consultation.
                If so, the Provider will provide you with an ESA diagnosis
                letter, as well as explain your options for continued treatment.
                The ESA diagnosis service offering is designed with the aim of
                providing appropriate documentation to support a reasonable
                accommodation to a landlord’s pet policy based on guidance
                promulgated by the U.S. Department of Housing and Urban
                Development.
              </p>
            </li>
          </ul>
          <p>
            Please note that ESA TheraPass does not (a) make any determinations
            as to your eligibility for an ESA letter, or (b) directly provide an
            ESA letter. Rather, ESA TheraPass merely acts as a medium for the
            professional services and the delivery of the applicable letter from
            the Provider.{" "}
          </p>
        </section>
        <section className={styles.section}>
          <h2>2. NO GUARANTEE OF ACCEPTANCE; REFUND POLICY</h2>
          <div>
            <p>
              ESA TheraPass does not guarantee that a landlord or airline will
              accept an ESA letter obtained through the use of the Platform.
              ESAs and the process through which ESA letters are obtained are
              governed by various federal and state laws that are constantly
              changing and, therefore, we cannot guarantee that ESA letters
              received through the use of our Platform will be accepted in any
              or all instances. If your landlord or an airline rejects an ESA
              letter that you receive through the use of our Platform, it is
              your responsibility to pursue any remedies that you may have
              against such party under law or contract and we have no
              responsibility to assist you in those efforts.
            </p>
            <p>
              For additional payment and upon providing the information required
              by ESA TheraPass, ESA TheraPass will refund the fees you paid to
              ESA TheraPass if an ESA letter you obtained through our service is
              not accepted by a third party. To receive the refund, you must
              have made the additional payment at the time that you purchased
              our services and you must provide ESA TheraPass with information
              about the ESA rejection, including: (a) name, address and
              telephone number of party that rejected the ESA letter, and (b) a
              copy of any written correspondence from the party that rejected
              the ESA letter.{" "}
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>3. CALIFORNIA RESIDENTS</h2>
          <div>
            <p>
              California law requires Providers to establish a client-provider
              relationship with a California Resident for at least 30 days prior
              to providing an ESA letter for dogs. ESA TheraPass and its
              Providers will comply with applicable law in performing our
              obligations under these Terms of Use.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>4. THE PLATFORM DOES NOT PROVIDE MEDICAL ADVICE</h2>
          <div>
            <p>
              THE PLATFORM IS A TOOL FOR INDIVIDUALS TO RECEIVE AN ASSESSMENT
              FOR AN ESA LETTER. HOWEVER, THE PLATFORM DOES NOT PROVIDE MEDICAL
              DIAGNOSES OR RECOMMENDATIONS REGARDING MEDICAL TREATMENT. THE
              PLATFORM AND THE CONTENT APPEARING ON THE PLATFORM ARE NOT
              SUBSTITUTES FOR PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS OR
              TREATMENT. THE CONTENT OF THIS PLATFORM IS MADE AVAILABLE TO YOU
              FOR INFORMATIONAL PURPOSES ONLY.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>5. SMS MESSAGING</h2>
          <div>
            <p>
              The Platform may allow End Users to communicate with Providers
              using short message service (SMS) texting. SMS texting is an
              unencrypted form of communication that presents a greater degree
              of risk of unpermitted access by third parties. As such, users of
              SMS messaging should limit the disclosure of sensitive information
              to the minimum amount necessary to achieve the purpose of the
              communication. By using our SMS messaging service, you consent to
              receiving SMS text messages from the Provider you are contacting.
              Additionally, you agree to comply with all applicable data privacy
              laws in relation to your use of the Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>6. FEES AND OTHER PAYMENTS</h2>
          <div>
            <p>
              The fees for our Services are set forth on the Platform and
              subject to change from time to time.
            </p>
            <p>
              The Platform allows you to make online payments. If you use the
              Platform to make payments online, you acknowledge that we use a
              third party payment processor to process such payments. In
              connection with the processing of such payments, we do not collect
              or retain any credit card numbers or other payment information.
              Rather, all such payment information is provided directly to our
              third party payment processor, whose use of your personal
              information is governed by their privacy policy and their
              agreement, if any, with your credit card issuer. You agree to
              comply with any terms and conditions and the privacy policies of
              the third-party payment processor in connection with your use of
              such third-party payment processor services.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>7. ELIGIBILITY</h2>
          <div>
            <p>
              The Platform is intended for End Users who are 13 years of age or
              older. If you are under the age of 18, you may only use the
              Platform in conjunction with your parents or legal guardian or
              with the consent of your parents or legal guardian. By using the
              Platform you represent and warrant that you are 18 years of age or
              older or meet all of the foregoing eligibility requirements. You
              further represent that you are of legal age in the jurisdiction in
              which you live to enter into a legally binding contract with ESA
              TheraPass. If you do not meet all of these requirements, you must
              not access or use the Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>8. CHANGES TO TERMS OF USE</h2>
          <div>
            <p>
              We reserve the right, at our discretion, to update, change,
              modify, add, or remove portions of these Terms of Use at any time
              in order to conform them to our business practices. All changes
              are effective immediately when we post them, and apply to all
              access to and use of the Platform thereafter. Your continued use
              of the Platform thereafter constitutes your acceptance of such
              changes.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>9. ACCESSING THE PLATFORM AND ACCOUNT SECURITY</h2>
          <div>
            <p>
              We reserve the right to withdraw or amend this Platform, and any
              service or material we provide on the Platform, in our sole
              discretion without notice. We will not be liable if, for any
              reason, all or any part of the Platform is unavailable at any time
              or for any period. From time to time, we may restrict access to
              some parts of the Platform, or the entire Platform, to users,
              including registered users.
            </p>
            <p>
              To access the Platform or some of the resources it offers, you may
              be asked to provide certain registration details or other
              information. It is a condition of your use of the Platform that
              all the information you provide on the Platform is correct,
              current, and complete. You agree that all information you provide
              to register with this Platform or otherwise, including, but not
              limited to, through the use of any interactive features on the
              Platform, is governed by our Privacy Policy{" "}
              <a href="https://esa.therapass.com/privacy">
                https://esa.therapass.com/privacy
              </a>{" "}
              , and you consent to all actions we take with respect to your
              information consistent with our Privacy Policy.
            </p>
            <p>
              If you choose, or are provided with, a user name, password, or any
              other piece of information as part of our security procedures, you
              must treat such information as confidential, and you must not
              disclose it to any other person or entity. You also acknowledge
              that your account is personal to you and agree not to provide any
              other person with access to this Platform or portions of it using
              your user name, password, or other security information. You agree
              to notify us immediately of any unauthorized access to or use of
              your user name or password or any other breach of security. You
              also agree to ensure that you exit from your account at the end of
              each session. You should use particular caution when accessing
              your account from a public or shared computer so that others are
              not able to view or record your password or other personal
              information.
            </p>
            <p>
              We have the right to disable any user name, password, or other
              identifier, whether chosen by you or provided by us, at any time
              if, in our opinion, you have violated any provision of these Terms
              of Use.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>10. DATA PROTECTION AND PRIVACY</h2>
          <div>
            <p>
              You agree that all information you provide through the Platform is
              governed by the Privacy Policy{" "}
              <a href="https://esa.therapass.com/privacy">
                https://esa.therapass.com/privacy
              </a>{" "}
              , and you consent to all actions we take with respect to your
              information consistent with the Privacy Policy.
            </p>
            <p>
              We are not responsible for the acts of third parties who may
              access the Platform and information through your mobile device.
              You should use all security features of your mobile device,
              including any password, locking, or encryption features, to
              protect against unauthorized access and use of the Platform and
              your information.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>11. INTELLECTUAL PROPERTY RIGHTS</h2>
          <div>
            <p>
              The Platform and its entire contents, features, and functionality
              (including but not limited to all information, headers, stories,
              software, text, displays, photos, images, video and audio, and the
              design, selection and arrangement thereof), are owned by ESA
              TheraPass, its licensors or other providers of such material and
              are protected by United States and international copyright,
              trademark, patent, trade secret and other intellectual property or
              proprietary rights laws.
            </p>
            <p>
              Certain products, services or company designations for companies
              other than ESA TheraPass may be mentioned or displayed on the
              Platform for identification purposes only. Third-party trademarks,
              trade names, logos, product or services names contained on the
              Platform are the trademarks, registered or unregistered, of their
              respective owners.
            </p>
            <p>
              We grant you a non-commercial, non-exclusive, non-transferable,
              limited, terminable license to use the Platform, subject to your
              compliance with these Terms of Use. The Platform is licensed, not
              sold, to you, solely for your personal use. You must not
              reproduce, distribute, modify, create derivative works of,
              publicly display, publicly perform, republish, download, store, or
              transmit any of the material on the Platform.
            </p>
            <p>
              These Terms of Use and the notices outlined herein are not
              intended to and do not create any contractual or other legal
              rights in or on behalf of any third party.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>12. PROHIBITED USES</h2>
          <div>
            <p>
              You may use the Platform only for lawful purposes and in
              accordance with these Terms of Use and the Privacy Policy. You
              agree not to use the Platform in any of the following ways:
            </p>
            <ul>
              <li>
                • In any way that violates any applicable federal, state or
                international law or regulation (including, without limitation,
                any laws regarding the export of data or software to and from
                the United States or other countries).{" "}
              </li>
              <li>
                • For the purpose of exploiting, harming or attempting to
                exploit or harm minors in any way by exposing them to
                inappropriate content, asking for personal or sensitive
                information or otherwise.
              </li>
              <li>
                • To transmit, or procure the sending of, any advertising or
                promotional material, including any “junk mail,” “chain letter,”
                or “spam” or any other similar solicitation.
              </li>
              <li>
                • To impersonate or attempt to impersonate ESA TheraPass, a ESA
                TheraPass employee, another user, or any other person or entity
                (including, without limitation, by using e-mail associated with
                any of the foregoing).
              </li>
              <li>
                • To engage in any other conduct that restricts or inhibits
                anyone’s use or enjoyment of the Platform, or which, as
                determined by us, may harm ESA TheraPass or users of the
                Platform or expose them to liability.
              </li>
            </ul>
            <p>Additionally, you agree not to do any of the following:</p>
            <ul>
              <li>
                • Use the Platform in any manner that could disable, overburden,
                damage, or impair the Platform or interfere with any other
                party’s use of the Platform, including its ability to engage in
                real time activities through the Platform.
              </li>
              <li>
                • Use any robot, spider, “scraper,” “crawler,” or other
                automatic device, process, or means to access the Platform for
                any purpose, including monitoring or copying any of the material
                on the Platform.
              </li>
              <li>
                • Use any manual process to monitor or copy any of the material
                on the Platform or for any other unauthorized purpose without
                our prior written consent.
              </li>
              <li>
                • Use any device, software, or routine that interferes with the
                proper working of the Platform.
              </li>
              <li>
                • Introduce any viruses, trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful.
              </li>
              <li>
                • Attempt to gain unauthorized access to, interfere with,
                damage, or disrupt any parts of the Platform, the server on
                which the Platform is stored, or any server, computer, or
                database connected to the Platform.
              </li>
              <li>
                • Attack the Platform via a denial-of-service attack or a
                distributed denial-of-service attack.
              </li>
              <li>
                • Otherwise attempt to interfere with the proper working of the
                Platform.
              </li>
              <li>• Modify copies of any materials from the Platform.</li>
              <li>
                • Use any illustrations, photographs, video or audio sequences
                or any graphics from the Platform or any text.
              </li>
              <li>
                • Delete or alter any copyright, trademark, or other proprietary
                rights notices from copies of materials from the Platform.
              </li>
            </ul>
            <p>
              No right, title, or interest in or to the Platform or any content
              on the Platform are transferred to you, and ESA TheraPass reserves
              all rights not expressly granted in these Terms of Use. Any use of
              the Platform not expressly permitted by these Terms of Use is a
              breach of these Terms of Use and may violate copyright, trademark,
              and other laws.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>13. RELIANCE ON INFORMATION POSTED</h2>
          <div>
            <p>
              The information and content presented on or through the Platform
              is made available solely for general information purposes. We do
              not warrant the accuracy, completeness, or usefulness of this
              information and content. Any reliance you place on such
              information or content is strictly at your own risk. We disclaim
              all liability and responsibility arising from any reliance placed
              on such materials by you or any other visitor to the Platform, or
              by anyone who may be informed of any of their contents.
            </p>
            <p>
              To the extent the Platform may contain content or information
              provided by a third party, all statements and/or opinions
              expressed in these materials, and all articles and responses to
              questions and other content, other than the content provided by
              ESA TheraPass, are solely the opinions and the responsibility of
              the third party providing those materials. These materials do not
              necessarily reflect the opinion of ESA TheraPass. We are not
              responsible, or liable to you or any third party, for the content
              or accuracy of any materials provided by any third parties.
            </p>
            <p>
              In no event will ESA TheraPass be liable for any decision made or
              action taken in reliance upon the information provided through the
              Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>14. INFORMATION ABOUT YOU AND YOUR VISITS TO THE PLATFORM</h2>
          <div>
            <p>
              The Platform is governed by and operated in accordance with United
              States law. If you are located outside of the United States, you
              use the Platform at your own risk. Your information will be
              transferred to and processed in the United States where many of
              our central databases operate. By using the Platform, you: (a)
              acknowledge that the data protection laws of other countries,
              including the United States, may provide a less comprehensive or
              protective standard of protection than those in your country, and
              (b) consent to all actions taken by us with respect to your
              information in compliance with the Privacy Policy and applicable
              privacy laws.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>15. THIRD-PARTY SERVICES</h2>
          <div>
            <p>
              Your use of the Platform may involve services made available to
              you by third parties. The use of such services or functionalities
              may be subject to additional terms and conditions and/or privacy
              policies provided by such third parties and we have no control
              over such terms and conditions. These Terms of Use and our Privacy
              Policy apply only to the Platform and not to any third-party
              website, platform or service. While we expect our business
              partners and affiliates to respect the privacy of our users, we
              cannot be responsible for the actions of third parties.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>16. ELECTRONIC COMMUNICATIONS</h2>
          <div>
            <p>
              When you send emails or other electronic messages to us or in
              connection with the Platform, you are communicating with us
              electronically and consent to our review and analysis of such
              messages and to receive return communications, if any, from us
              electronically, including via SMS text message. You agree that all
              agreements, notices, disclosures, and other communications that we
              provide to you electronically satisfy any legal requirement that
              such communications be in writing.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>17. DISCLAIMER OF WARRANTIES </h2>
          <div>
            <p>
              You understand that we cannot and do not guarantee or warrant that
              files available for downloading from the internet or the Platform
              will be free of viruses or other destructive code. You are
              responsible for implementing sufficient procedures and checkpoints
              to satisfy your particular requirements for anti-virus protection
              and accuracy of data input and output, and for maintaining a means
              external to the Platform for any reconstruction of any lost data.
              WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
              DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER
              TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
              EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL
              DUE TO YOUR USE OF THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED
              THROUGH THE PLATFORM OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED
              ON IT, OR ON ANY PLATFORM LINKED TO IT.
            </p>
            <p>
              YOUR USE OF THE PLATFORM, ITS CONTENT AND ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE PLATFORM ARE AT YOUR OWN RISK. THE PLATFORM,
              ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
              PLATFORM ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
              WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
              NEITHER ESA THERAPASS NOR ANY PERSON ASSOCIATED WITH ESA THERAPASS
              MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
              COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
              AVAILABILITY OF THE PLATFORM. WITHOUT LIMITING THE FOREGOING,
              NEITHER ESA THERAPASS NOR ANYONE ASSOCIATED WITH ESA THERAPASS
              REPRESENTS OR WARRANTS THAT THE PLATFORM, ITS CONTENT OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL BE ACCURATE,
              RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
              CORRECTED, THAT THE PLATFORM OR THE SERVER THAT MAKES IT AVAILABLE
              ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
              PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM
              WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            </p>
            <p>
              ESA THERAPASS HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
              LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
              AND FITNESS FOR PARTICULAR PURPOSE.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <p></p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>18. INDEMNIFICATION </h2>
          <div>
            <p>
              You agree to defend, indemnify and hold harmless ESA TheraPass,
              its affiliates, licensors, and service providers, and its and
              their respective officers, directors, employees, contractors,
              agents, licensors, suppliers, successors and assigns from and
              against any claims, liabilities, damages, judgments, awards,
              losses, costs, expenses or fees (including reasonable attorneys’
              fees) arising out of or relating to your violation of these Terms
              of Use or your use of the Platform, including, but not limited to
              any use of the Platform’s content, and other services and
              products, other than as expressly authorized in these Terms of
              Use, or your use of any information obtained from the Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>19. LIMITATION OF LIABILITY</h2>
          <div>
            <p>
              IN NO EVENT WILL ESA THERAPASS BE LIABLE TO YOU OR TO ANY THIRD
              PARTY FOR ANY LOSS OF USE, REVENUE OR PROFIT OR LOSS OF DATA OR
              FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR
              PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT
              (INCLUDING NEGLIGENCE) OR OTHERWISE, REGARDLESS OF WHETHER SUCH
              DAMAGE WAS FORESEEABLE AND WHETHER OR NOT ESA THERAPASS HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ADDITIONALLY, IN NO
              EVENT WILL THE LIABILITY OF ESA THERAPASS ARISING OUT OF OR
              RELATED TO THESE TERMS OF USE OR THE PLATFORM, WHETHER ARISING OUT
              OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE)
              OR OTHERWISE, EXCEED THE AGGREGATE AMOUNTS PAID OR PAYABLE TO ESA
              THERAPASS BY YOU, IF ANY, IN THE SIX (6) MONTH PERIOD PRECEDING
              THE EVENT GIVING RISE TO THE CLAIM.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>20. GOVERNING LAW AND JURISDICTION</h2>
          <div>
            <p>
              All matters relating to the Platform and these Terms of Use and
              any dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              the State of Delaware without giving effect to any choice or
              conflict of law provision or rule (whether of the State of
              Delaware or any other jurisdiction).
            </p>
            <p>
              Any legal suit, action or proceeding arising out of, or related
              to, these Terms of Use or the Platform shall be instituted
              exclusively in the federal courts of the United States or the
              courts of the State of Delaware, although we retain the right to
              bring any suit, action or proceeding against you for breach of
              these Terms of Use in your country of residence or any other
              relevant country. You waive any and all objections to the exercise
              of jurisdiction over you by such courts and to venue in such
              courts.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>21. ARBITRATION</h2>
          <div>
            <p>
              In our sole discretion, we may require you to submit any disputes
              arising from the use of these Terms of Use or the Platform,
              including disputes arising from or concerning their
              interpretation, violation, invalidity, nonperformance, or
              termination, to final and binding arbitration under the Rules of
              Arbitration of the American Arbitration Association applying
              Delaware law.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>22. LIMITATION ON TIME TO FILE CLAIMS</h2>
          <div>
            <p>
              <b>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                RELATING TO THESE TERMS OF USE OR THE PLATFORM MUST BE COMMENCED
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES;
                OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.{" "}
              </b>
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>23. WAIVER AND SEVERABILITY</h2>
          <div>
            <p>
              No waiver by ESA TheraPass of any term or condition set forth in
              these Terms of Use shall be deemed a further or continuing waiver
              of such term or condition or a waiver of any other term or
              condition, and any failure of ESA TheraPass to assert a right or
              provision under these Terms of Use shall not constitute a waiver
              of such right or provision.
            </p>
            <p>
              If any provision of these Terms of Use is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal, or
              unenforceable for any reason, such provision shall be, in the
              first instance, reformed or limited to the minimum extent such
              that the relevant provision is enforceable, and the remaining
              provisions of these Terms of Use will continue in full force and
              effect. If such reformation or limitation cannot cure the
              deficiency of such provision, it shall be eliminated from these
              Terms of Use and the remaining provisions of these Terms of Use
              will continue in full force and effect.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>24. ENTIRE AGREEMENT</h2>
          <div>
            <p>
              These Terms of Use, the Privacy Policy, and any additional terms
              incorporated by reference constitute the sole and entire agreement
              between you and ESA TheraPass with respect to the Platform, and
              supersede all prior and contemporaneous understandings,
              agreements, representations, and warranties, both written and
              oral, with respect to the Platform.
            </p>
          </div>
        </section>
        <section className={styles.section}>
          <h2>25. YOUR COMMENTS AND CONCERNS</h2>
          <div>
            <p>The Platform is operated by:</p>
            <ul>
              <li>
                <p>
                  TheraPass, Inc.
                  <br />
                  2000 Ponce De Leon Blvd, #600
                  <br />
                  Coral Gables, FL 33134
                  <br />
                </p>
              </li>
            </ul>
            <p>
              All feedback, comments, requests for technical support, and other
              communications relating to the Platform should be directed to
              notice@therapass.com.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};
