import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import styles from "./Back.module.scss";

interface Props {
  className?: string;
}

export const Back = ({ className }: Props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  return (
    <button className={cn(styles.root, className)} onClick={handleClick}>
      <Arrow />
      Back
    </button>
  );
};
