import avatar from "assets/images/avatar.png";
import classNames from "classnames";
import { ChatStatusBadge } from "components/ChatStatusBadge";
import { VerifiedCircle } from "components/VerifiedCircle";
import { useMobileResponsive, useTabletResponsive } from "hooks/useResponsive";
import { therapistPath } from "pages/Therapist";
import { Link, useLocation } from "react-router-dom";
import { ChatStatus, TherapistFile } from "types/therapist.types";

import styles from "./Avatar.module.scss";

type Props = {
  file?: TherapistFile;
  alt?: string;
  className?: string;
  size?: "lg" | "md" | "sm";
  chat_status?: ChatStatus;
  onClick?: () => void;
  withZoom?: boolean;
  isVerified?: boolean;
  id?: string;
  planName?: string;
  withoutLink?: boolean;
  isEsaTherapist?: boolean;
  avatarSize?: string | null;
};

export const Avatar = ({
  file,
  size = "md",
  className,
  chat_status,
  alt,
  onClick,
  isVerified,
  id,
  planName,
  withoutLink,
  isEsaTherapist,
  avatarSize = null,
}: Props) => {
  const { pathname } = useLocation();

  const { isMobile } = useMobileResponsive();
  const { isTablet } = useTabletResponsive();

  return (
    <>
      {(isMobile && (
        <div style={{ marginBottom: "1rem", width: "100%" }}>
          {isVerified && (
            <VerifiedCircle className={styles.verified} withoutText />
          )}
          <Link
            to={withoutLink ? "#" : therapistPath.replace(":id", String(id))}
            state={{ prevPage: pathname }}
            className={styles.link}
          >
            <div style={{ width: "100%" }}>
              {isEsaTherapist ? (
                <img
                  alt={alt}
                  src={(!file?.mime.match("video") && file?.file) || avatar}
                  style={{ width: "100%" }}
                />
              ) : (
                <img
                  alt={alt}
                  src={(!file?.mime.match("video") && file?.file) || avatar}
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </Link>
          {chat_status && planName === "Premium" && (
            <ChatStatusBadge
              className={styles.badge}
              chatStatus={chat_status}
              onClick={onClick}
            />
          )}
        </div>
      )) ||
        (isTablet && (
          <div className={classNames(styles.root, styles[size], className)}>
            {isVerified && (
              <VerifiedCircle className={styles.verified} withoutText />
            )}
            <Link
              to={withoutLink ? "#" : therapistPath.replace(":id", String(id))}
              state={{ prevPage: pathname }}
              className={styles.link}
            >
              <div className="fill">
                {isEsaTherapist ? (
                  <img
                    alt={alt}
                    src={(!file?.mime.match("video") && file?.file) || avatar}
                    ref={(node) => {
                      const imgSize =
                        avatarSize !== null ? avatarSize : "240px";
                      node?.style.setProperty(
                        "min-width",
                        imgSize,
                        "important"
                      );
                    }}
                  />
                ) : (
                  <img
                    alt={alt}
                    src={(!file?.mime.match("video") && file?.file) || avatar}
                    ref={(node) => {
                      const imgSize =
                        avatarSize !== null ? avatarSize : "240px";
                      node?.style.setProperty(
                        "min-width",
                        imgSize,
                        "important"
                      );
                    }}
                  />
                )}
              </div>
            </Link>
            {chat_status && planName === "Premium" && (
              <ChatStatusBadge
                className={styles.badge}
                chatStatus={chat_status}
                onClick={onClick}
              />
            )}
          </div>
        )) || (
          <div className={classNames(styles.root, styles[size], className)}>
            {isVerified && (
              <VerifiedCircle className={styles.verified} withoutText />
            )}
            <Link
              to={withoutLink ? "#" : therapistPath.replace(":id", String(id))}
              state={{ prevPage: pathname }}
              className={styles.link}
            >
              <div className="fill">
                {isEsaTherapist ? (
                  <img
                    alt={alt}
                    src={(!file?.mime.match("video") && file?.file) || avatar}
                    ref={(node) => {
                      const imgSize =
                        avatarSize !== null ? avatarSize : "387px";
                      node?.style.setProperty(
                        "min-width",
                        imgSize,
                        "important"
                      );
                    }}
                  />
                ) : (
                  <img
                    alt={alt}
                    src={(!file?.mime.match("video") && file?.file) || avatar}
                    ref={(node) => {
                      const imgSize =
                        avatarSize !== null ? avatarSize : "323px";
                      node?.style.setProperty(
                        "min-width",
                        imgSize,
                        "important"
                      );
                    }}
                  />
                )}
              </div>
            </Link>
            {chat_status && planName === "Premium" && (
              <ChatStatusBadge
                className={styles.badge}
                chatStatus={chat_status}
                onClick={onClick}
              />
            )}
          </div>
        )}
    </>
  );
};
