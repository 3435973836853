import { HomeLayout } from "components/Layout/HomeLayout";
import { useGA } from "hooks/useGA";
import { useTitle } from "hooks/useTitle";
import { homePath } from "pages/Home";
import { NotFound } from "pages/NotFound";
import { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { therapistProfileService } from "services/therapistProfileService";
import { visitorIdService } from "services/visitoridService";
import { therapistName } from "utils/therapistName";
import { useSnapshot } from "valtio";
import { Confirm, confirmPath } from "./Confirm";
import { Error, errorPath } from "./Error";
import { Success, successPath } from "./Success";
import styles from "./Connect.module.scss";

export const connectPath = "/connect";

export const Connect = () => {
  const [searchParams] = useSearchParams();
  const { currentProfile } = useSnapshot(therapistProfileService);
  const { setTitle } = useTitle();

  useGA().ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const therapistId = useMemo(
    () => searchParams.get("therapistId"),
    [searchParams]
  );

  useEffect(() => {
    if (therapistId) {
      therapistProfileService.getById(therapistId);
    }
  }, [therapistId]);

  useEffect(() => {
    if (currentProfile) {
      setTitle(
        `TheraPass ESA: Connect with ${therapistName({
          ...currentProfile,
          ...currentProfile.user,
        })}`
      );
    }
  }, [setTitle, currentProfile]);

  useEffect(() => {
    if (therapistId) {
      visitorIdService.trackEvent("Connect", "Visit", therapistId, "", "");
    }
  }, [therapistId]);

  if (!therapistId) {
    return <Navigate to={homePath} />;
  }

  return (
    <HomeLayout hasHeader={false}>
      <section
        className={`${styles.background} d-flex align-items-center min-height-100-vh py-40 py-xs-60 py-md-100`}
      >
        <div className="container-normal">
          <Routes>
            <Route
              path={confirmPath}
              element={
                <Confirm
                  SecondaryConfirmationRequired={
                    currentProfile?.SecondaryConfirmationRequired
                  }
                  SecondaryConfirmationText={
                    currentProfile?.SecondaryConfirmationText
                  }
                />
              }
            />
            <Route path={successPath} element={<Success />} />
            <Route path={errorPath} element={<Error />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </section>
    </HomeLayout>
  );
};
