import { ConnectFormValues } from "pages/Connect/useConnect";
import { instanceOf } from "prop-types";
import { Cookies } from "react-cookie";
import { HttpService } from "services/httpService";
import { ListResponse } from "types/common.types";
import { TherapistList } from "types/therapist.types";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { listingService } from "./listingService";
import { visitorIdService } from "./visitoridService";

class ConnectService extends HttpService {
  async getTherapist(qry: string, data = false): Promise<any> {
    // TODO: refactor method to return therapist profile instead of id and change promise type to a interface.
    let result = "";
    try {
      const q = listingService.getQuery();
      this.isFetching = true;
      const items = (await this.http.get(
        `/therapist/search/?${q}`
      )) as ListResponse<TherapistList>;
      const itm = items.results[0];
      if (
        itm !== null &&
        itm !== undefined &&
        itm.id != null &&
        itm.id !== undefined &&
        !data
      ) {
        result = itm.id?.toString();
      } else {
        return itm;
      }
      return result;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return "";
    } finally {
      this.isFetching = false;
    }
    return result;
  }

  private static prefix = "/connect/";

  private isFetching = false;

  private isError = false;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  async submitConnect(data: ConnectFormValues): Promise<string> {
    try {
      this.isFetching = true;
      const result = (await this.http.post("/connect/invoke/", data)) as string;

      return result;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return "";
    } finally {
      this.isFetching = false;
    }
  }

  async storeVisitorData(data: StoreVisitorDataArgs): Promise<string> {
    const vid = await visitorIdService.getVisitorID(false);
    const cook = new Cookies();
    const dataName = `${data.SettingName}`;
    cook.set(dataName, data.SettingValue);
    const args: SetVisitorDataExtArgs = {
      VisitorID: vid,
      SettingName: dataName,
      SettingValue: data.SettingValue,
    };
    await this.setVisitorDataExt(args);
    return data.SettingValue;
  }

  async getInterstitialMode(): Promise<string> {
    /* const args: StoreVisitorDataArgs = {
      SettingName: "tester",
      SettingValue: "testing",
    };
    await this.storeVisitorData(args);
    return "aftersearchinplaceofresults"; */

    const settingName = "interstitialMode";
    const gdArgs: GetVisitorDataArgs = { SettingName: settingName };
    const gd = await this.getVisitorData(gdArgs);
    if (gd.Success && gd.Value !== null) {
      if (
        gd.Value === "aftersearchbeforeresults" ||
        gd.Value === "afterresultsbeforeconnect" ||
        gd.Value === "aftersearchinplaceofresults"
      ) {
        this.storeVisitorData({
          SettingName: settingName,
          SettingValue: gd.Value,
        } as StoreVisitorDataArgs);
        return gd.Value;
      }
    }
    const mode = Math.floor(Math.random() * (4 - 1) + 1);
    let settingValue = "0";
    switch (mode) {
      case 1:
        settingValue = "aftersearchbeforeresults";
        break;
      case 2:
        settingValue = "afterresultsbeforeconnect";
        break;
      case 3:
        settingValue = "aftersearchinplaceofresults";
        break;
      case 4:
        settingValue = "none";
        break;
      default:
        break;
    }
    this.storeVisitorData({
      SettingName: settingName,
      SettingValue: settingValue,
    } as StoreVisitorDataArgs);
    return settingValue;
  }

  async getVisitorData(
    data: GetVisitorDataArgs
  ): Promise<GetVisitorDataResult> {
    try {
      const vid = await visitorIdService.getVisitorID(false);
      const cook = new Cookies();
      const settingName = `${data.SettingName}`;
      const settingValue = cook.get(settingName);
      if (
        settingValue !== undefined &&
        settingValue != null &&
        settingValue !== "undefined"
      ) {
        const result: GetVisitorDataResult = {
          Success: true,
          Message: null,
          Value: settingValue,
        };
        return result;
      }
      const gdArgs: GetVisitorDataArgs = {
        SettingName: settingName,
      };
      const gd = await this.getVisitorDataExt(gdArgs);
      if (
        gd.Success &&
        gd.Value !== undefined &&
        gd.Value !== null &&
        gd.Value !== "undefined"
      ) {
        this.storeVisitorData({
          SettingName: gdArgs.SettingName,
          SettingValue: gd.Value,
        } as StoreVisitorDataArgs);
      }
      const result: GetVisitorDataResult = {
        Success: gd.Success,
        Message: gd.Message,
        Value: gd.Value,
      };
      return result;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      const result: GetVisitorDataResult = {
        Success: false,
        Message: e.message,
        Value: null,
      };
      return result;
    } finally {
      this.isFetching = false;
    }
  }

  async setCustomerData(data: SetCustomerDataArgs): Promise<string> {
    try {
      this.isFetching = true;
      const result = (await this.http.post(
        "/connect/SetCustomerData/",
        data
      )) as string;

      return result;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return "";
    } finally {
      this.isFetching = false;
    }
  }

  async getCustomerData(
    data: GetCustomerDataArgs
  ): Promise<GetCustomerDataResult> {
    try {
      this.isFetching = true;
      const result = (await this.http.post(
        "/connect/GetCustomerData/",
        data
      )) as GetCustomerDataResult;

      return result;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      const result: GetCustomerDataResult = {
        Success: false,
        Message: e.message,
        Value: null,
      };
      return result;
    } finally {
      this.isFetching = false;
    }
  }

  async setVisitorDataExt(data: SetVisitorDataExtArgs): Promise<string> {
    try {
      this.isFetching = true;
      const result = (await this.http.post(
        "/connect/SetVisitorData/",
        data
      )) as string;

      return result;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return "";
    } finally {
      this.isFetching = false;
    }
  }

  async getVisitorDataExt(
    data: GetVisitorDataArgs
  ): Promise<ReadVisitorDataResult> {
    try {
      this.isFetching = true;
      const result = (await this.http.post(
        "/connect/GetVisitorData/",
        data
      )) as ReadVisitorDataResult;

      return result;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      const result: ReadVisitorDataResult = {
        Success: false,
        Message: e.message,
        Value: null,
      };
      return result;
    } finally {
      this.isFetching = false;
    }
  }
}
export interface StoreVisitorDataArgs {
  SettingName: string;
  SettingValue: string;
}
export interface GetCustomerDataArgs {
  VisitorID: string | null;
  CartID: string | null;
  PersonID: number;
  SettingName: string | null;
}

export interface SetCustomerDataArgs {
  VisitorID: string | null;
  CartID: string | null;
  PersonID: 0;
  SettingName: string | null;
  SettingValue: string | null;
}

export interface GetVisitorDataArgs {
  SettingName: string;
}

export interface GetVisitorDataResult {
  Success: boolean;
  Message: string | null;
  Value: string | null;
}
export interface SetVisitorDataExtArgs {
  VisitorID: string | null;
  SettingName: string;
  SettingValue: string;
}
export interface ReadVisitorDataResult {
  Success: boolean;
  Message: string | null;
  Value: string | null;
}
export interface GetCustomerDataResult {
  Success: boolean;
  Message: string | null;
  Value: string | null;
}

export const connectService = proxy(new ConnectService());

devtools(connectService, { name: "connectService" });
