import { ReactComponent as CheckIcon } from "assets/icons/option-check.svg";

import defaultAvatar from "assets/images/avatar.png";
import TherapyOptions from "components/TherapyOptions";
import { upperCase } from "lodash";
import { FC } from "react";
import { TherapistProfile } from "services/therapistProfileService";
import styles from "./TherapistCardSuccess.module.scss";

interface Props {
  therapist: TherapistProfile;
}

export const TherapistCardSuccess: FC<Props> = ({ therapist }) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    user,
    avatar,
    primary_credential_is_verified,
    license_state,
    is_available,
    mental_health_role,
  } = therapist;
  /* eslint-enable @typescript-eslint/naming-convention */

  const cardName = [
    `${user.first_name} ${user.last_name}`,
    upperCase(mental_health_role),
  ]
    .filter((item) => item)
    .join(", ");

  return (
    <div className={styles.therapistCard}>
      <div className={styles.imageContainer}>
        <img
          src={avatar?.file || defaultAvatar}
          alt="Profile image"
          className={styles.avatar}
        />
      </div>
      <div className={styles.info}>
        <h3 className={styles.name} style={{ fontSize: "24px" }}>
          {cardName}
        </h3>

        {license_state ? (
          <div
            className={styles.role}
            style={{ color: "#162521", fontSize: "16px" }}
          >
            "State Licensed ESA Therapist - " {license_state}
          </div>
        ) : (
          <div
            className={styles.role}
            style={{ color: "#162521", fontSize: "16px" }}
          >
            State Licensed ESA Therapist
          </div>
        )}
        <div className={styles.role}>
          {primary_credential_is_verified && (
            <div className={styles.verifiedBadge}>
              <div className={styles.iconContainer}>
                <CheckIcon />
              </div>
              {"ESA Verified"}
            </div>
          )}
        </div>
        {is_available && (
          <TherapyOptions
            styles={styles}
            therapist={therapist}
            title="Therapy Options:"
          />
        )}
      </div>
    </div>
  );
};
