/* eslint-disable @typescript-eslint/no-unused-vars */
import cn from "classnames";
import InputPassword, {
  SPECIAL_CHAR_REGEXP,
} from "components/InputPassword/InputPassword";
import { Button } from "components/UIKit/Button";
import { Checkbox } from "components/UIKit/Checkbox";
import { Input } from "components/UIKit/Input";
import { useConnect } from "pages/Connect/useConnect";
import { useEffect, useMemo, useState, FC } from "react";

import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import progressStyles from "../../ProgressForRegistrationFunnel/ProgressForRegistrationFunnel.module.scss";
import styles from "./Confirm.module.scss";

export const confirmPath = "/";

interface Props {
  SecondaryConfirmationRequired?: boolean;
  SecondaryConfirmationText?: string;
}

export const Confirm: FC<Props> = ({
  SecondaryConfirmationRequired,
  SecondaryConfirmationText,
}) => {
  const [searchParams] = useSearchParams();
  const therapistId = useMemo(
    () => searchParams.get("therapistId"),
    [searchParams]
  );

  const {
    watch,
    handleSubmit,
    control,
    register,
    formState: { isSubmitted, errors, dirtyFields },
    onSubmit,
    getValues,
    showSpinner,
  } = useConnect();
  const agreementError = errors.agreeDisclosure?.message;
  const secondAgreeError = errors.secondaryAgreeDisclosure?.message;
  const showHeader = false;
  return (
    <div className={cn("default-typography", styles.sectionProgress)}>
      <div className="row justify-content-center">
        <div className="col-xs-10 col-md-7">
          <div className="text-center mb-30">
            {showHeader && (
              <h1 className="h2 mb-10" style={{ fontSize: "24px" }}>
                Connect to an ESA Therapist NOW
              </h1>
            )}
            <p className="mb-0" style={{ fontSize: "22px" }}>
              We need your permission to connect with a state-licensed ESA
              Therapist..{" "}
              <span
                className="tooltip-icon icomoon-info align-middle"
                data-tooltip-id="info-tooltip"
                data-tooltip-content="Your phone and email are required for verification purposes before speaking to a healthcare provider. Your information is private and will NOT be used for any other purpose than to"
              ></span>
              <Tooltip id="info-tooltip" place="bottom" />
            </p>
          </div>

          <div className={progressStyles.sectionProgress__content}>
            <div className={progressStyles.sectionProgress__progressBlock}>
              <div
                className={progressStyles.sectionProgress__progressStrip}
                style={{ width: "20%" }}
              ></div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-auto mb-30">
              <div className="row gutter-12 remove-last-child-margin mb-25">
                <div className="col-6">
                  <div className="mb-12">
                    <Input
                      size="sm"
                      placeholder="First Name"
                      {...register("first_name", {
                        required: "This field is required",
                      })}
                      error={errors.first_name?.message}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-12">
                    <Input
                      size="sm"
                      placeholder="Last Name"
                      {...register("last_name", {
                        required: "This field is required",
                      })}
                      error={errors.last_name?.message}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-12">
                    <Input
                      size="sm"
                      type="tel"
                      placeholder="Mobile Phone"
                      {...register("phone", {
                        required: "This field is required",
                        validate: (value) =>
                          isMobilePhone(value) || "Invalid Phone Number",
                      })}
                      error={errors.email?.message}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-12">
                    <Input
                      size="sm"
                      type="email"
                      placeholder="Email Address"
                      {...register("email", {
                        required: "This field is required",
                        validate: (value) => isEmail(value) || "Invalid email",
                      })}
                      error={errors.email?.message}
                    />
                  </div>
                </div>
              </div>
            </div>
            {SecondaryConfirmationRequired ? (
              <div className="">
                <Checkbox
                  labelSize="xs"
                  label={SecondaryConfirmationText}
                  {...register("secondaryAgreeDisclosure", {
                    required: "You must agree to the terms",
                  })}
                  error={secondAgreeError}
                />
                {secondAgreeError && (
                  <p className={cn("invalid-feedback", "ps-30")}>
                    {secondAgreeError}
                  </p>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="mb-25">
              <Checkbox
                labelSize="xs"
                label="By utilizing these electronic therapeutic services, I agree that I am soliciting the services of a licensed mental health professional using the telephone or internet to engage with, and visit the therapist. I give my consent to communicate with the therapist by text message. Text messaging is conducted on a wireless carrier's (Verizon, AT&T, Sprint, TMO) network that lacks encryption and where there exists risk of unauthorized disclosure. I agree to receive texts at my phone number above. Message & data rates may apply. Text STOP to opt-out. Read SMS terms for more info."
                {...register("agreeDisclosure", {
                  required: "You must agree to the terms",
                })}
                error={agreementError}
              />
              {agreementError && (
                <p className={cn("invalid-feedback", "ps-30")}>
                  {agreementError}
                </p>
              )}
            </div>
            <div className="text-center">
              {showSpinner ? (
                <Button
                  id="contact"
                  type="submit"
                  disabled={true}
                  isLoading={true}
                  className="btn__spinner"
                >
                  Please Wait
                </Button>
              ) : (
                <Button id="contact" type="submit">
                  Get ESA Now
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
