import cn from "classnames";
import { privacyPolicyPath } from "pages/PrivacyPolicy";
import { termsOfUsePath } from "pages/TermsOfUse";
import { Link } from "react-router-dom";
// import { therapassApiEndpoint } from "utils/env";
import styles from "./Footer.module.scss";

interface Props {
  className?: string;
  variant?: "default" | "dashboard";
}

export const Footer = ({ variant = "default", className }: Props) => {
  return (
    <footer className={cn(styles.root, className, styles[variant])}>
      <div className={styles.row}>
        <ul className={styles.column}>
          <li className={styles.title}>Links</li>
          <li className={styles.link}>
            <a href="https://therapass.com/">Home</a>
          </li>
          <li className={styles.link}>
            <a href={`https://therapass.com/patient/login`}>Login</a>
          </li>
        </ul>
        <ul className={styles.column}>
          <li className={styles.link}>&nbsp;</li>
        </ul>
        <ul className={styles.column}>
          <li className={styles.title}>Legal</li>
          <li className={styles.link}>
            <Link to={privacyPolicyPath}>Privacy</Link>
          </li>
          <li className={styles.link}>
            <Link to={termsOfUsePath}>Terms of Use</Link>
          </li>
        </ul>
      </div>
      <p className={styles.copy}>© TheraPass 2022-2023. All right reserved</p>
    </footer>
  );
};
