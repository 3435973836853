import cn from "classnames";
import { Button } from "components/UIKit/Button";
import { InputSearch } from "components/UIKit/InputSearch";
import { ComponentProps } from "react";
import { useSearchParams } from "react-router-dom";
import { listingService } from "services/listingService";
import { useInputSmartSearch } from "./useInputSmartSearch";
import styles from "./InputSmartSearch.module.scss";

interface Props extends ComponentProps<typeof InputSearch> {
  optionsClassName?: string;
  onSelectPlace?: (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
  }) => void;
  withButton?: boolean;
  buttonClass?: string;
  onButtonClick?: () => void;
  types?: string[];
  buttonLabel?: string;
}

export const InputSmartSearch = ({
  className,
  optionsClassName,
  value: propValue,
  placeholder,
  onSelectPlace,
  withButton,
  buttonClass,
  onButtonClick,
  types,
  buttonLabel = "Find a therapist",
  ...props
}: Props) => {
  const {
    value,
    options,
    isLoaded,
    isOptionsVisible,
    optionsContainerRef,
    maxOptionsHeight,
    handleChange,
    handleFocus,
    handleBlur,
    handleSelect,
    isSelectOption,
  } = useInputSmartSearch(String(propValue || ""), onSelectPlace, types);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <div className={cn(styles.root, className)}>
        <InputSearch
          {...props}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          placeholder={isLoaded ? placeholder : "Loading..."}
        />
        {options.length > 0 && isOptionsVisible && (
          <div
            className={cn(styles.options, optionsClassName)}
            ref={optionsContainerRef}
            style={{ maxHeight: maxOptionsHeight }}
          >
            {options.map((item) => (
              <div
                className={styles.item}
                key={item.id}
                onMouseDown={() => handleSelect(item.id)}
              >
                {item.text}
              </div>
            ))}
          </div>
        )}
      </div>
      {withButton && (
        <Button
          className={buttonClass}
          onClick={async () => {
            if (value) {
              if (!isSelectOption && options.length > 0) {
                await handleSelect(options[0].id);
                await listingService.init(searchParams);
              }
              onButtonClick?.();
            }
          }}
        >
          {buttonLabel}
        </Button>
      )}
    </>
  );
};
