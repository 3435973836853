import cn from "classnames";
import { Footer } from "components/Footer/Footer";
import { ListingLayout } from "components/Layout/ListingLayout";
import { LoadingHOC } from "components/LoadingHOC";
import { Pagination } from "components/Pagination";
import { TherapistListingItem } from "components/TherapistListingItem";
import { GlobalContext } from "GlobalProvider";
import { useGA } from "hooks/useGA";
import { useListing } from "hooks/useListing";
import { useMobileResponsive } from "hooks/useResponsive";
import { useTitle } from "hooks/useTitle";
import { useContext, useEffect } from "react";
import { listingService } from "services/listingService";
import { visitorIdService } from "services/visitoridService";

import styles from "./Listing.module.scss";

export const listingPath = "/listing";

export const Listing = () => {
  const {
    therapists,
    esaTherapists,
    nonesaTherapists,
    isFetchingList,
    pageCount,
    currentPage,
    isError,
  } = useListing();
  useTitle("Therapass ESA: Listing");
  useGA().ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const { globalState } = useContext(GlobalContext);

  useEffect(() => {
    visitorIdService.trackEvent("Listings", "View", "", "", "");
  }, []);
  // visitorIdService.trackEvent("Listings", "View", "", "");

  useEffect(() => {
    document.getElementById("root")?.scrollTo({ top: 0 });
  }, [therapists]);

  const { isMobile } = useMobileResponsive();

  return (
    <>
      <ListingLayout>
        <h1 className={styles.listingHeader}>
          {isMobile
            ? "ESA Therapists"
            : "Emotional Support Animal (ESA) Therapists"}
        </h1>
        <a
          href={globalState?.prevPage || "/"}
          className=""
          style={{ color: "turquoise" }}
        >
          {"<"} Back
        </a>
        <LoadingHOC
          isLoading={isFetchingList}
          className={styles.listingCatalog}
          text="Searching for therapists..."
        >
          {isError && !isFetchingList ? (
            <div className={styles.noAvailable}>This page does not exist</div>
          ) : (
            <>
              {esaTherapists.length === 0 && !isFetchingList && (
                <div className={styles.noAvailable}>
                  No available therapists
                </div>
              )}
              {esaTherapists.map((therapist) => (
                <TherapistListingItem
                  key={therapist.id}
                  therapist={therapist}
                  className="mt-15"
                />
              ))}

              <div className={styles.pointerForCertified}>
                <div className="row g-0">
                  <div className="col-xs-6">
                    <div
                      className={cn(
                        styles.pointerForCertified__item,
                        styles.pointerForCertified__itemCertified
                      )}
                    >
                      ESA Certified
                    </div>
                  </div>
                  <div className="col-xs-6">
                    <div
                      className={cn(
                        styles.pointerForCertified__item,
                        styles.pointerForCertified__itemNotCertified
                      )}
                    >
                      Not ESA Certified
                    </div>
                  </div>
                </div>
              </div>

              {nonesaTherapists.length === 0 && !isFetchingList && (
                <div className={styles.noAvailable}>
                  No available therapists
                </div>
              )}
              {nonesaTherapists.map((therapist) => (
                <TherapistListingItem
                  key={therapist.id}
                  therapist={therapist}
                />
              ))}
              <Pagination
                pageCount={pageCount}
                currentPage={currentPage}
                goTo={(page) =>
                  listingService.setCurrentPage(
                    page > pageCount ? pageCount : page
                  )
                }
              />
            </>
          )}
        </LoadingHOC>
      </ListingLayout>
      <Footer />
    </>
  );
};
