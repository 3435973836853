import certificateIcon from "assets/icons/certificate.svg";
import { ReactComponent as RedUserIcon } from "assets/icons/unavailable-user.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import cn from "classnames";
import "./Availability.scss";

interface Props {
  className?: string;
  isAvailable?: boolean;
  isESATherapist?: boolean;
}

export const Availability = ({
  className,
  isAvailable,
  isESATherapist,
}: Props) => {
  let AvailabilityText = "";
  if (isESATherapist) {
    AvailabilityText = "Available Now";
  } else {
    AvailabilityText = "";
    /* AvailabilityText = isAvailable
			? "Available for new clients"
			: "Unavailable for new clients";
		*/
  }

  return (
    <div
      className={cn("available", className, {
        "available--red": !isAvailable,
      })}
    >
      <span className="available__badge">
        {isAvailable ? (
          <UserIcon className="available__icon" />
        ) : (
          <RedUserIcon className="available__icon" />
        )}
        {AvailabilityText}
      </span>
    </div>
  );
};
