import iconDocuments from "assets/icons/card-product/documents.svg";
import iconEsaGear from "assets/icons/card-product/esa-gear.jpg";
import iconShield from "assets/icons/card-product/shield.svg";

interface Props {
  className?: string;
  icon?: string;
}

export function OfferIcon({ icon, className }: Props) {
  if (icon === "shield") {
    return <img alt="Icon" src={iconShield} className={className} />;
  }
  if (icon === "dog") {
    return <img alt="Icon" src={iconEsaGear} className={className} />;
  }

  return <img src={iconDocuments} alt="Icon" className={className} />;
}

export default OfferIcon;
