import { ListingHeader } from "components/Header/ListingHeader";
import { Layout } from "components/Layout/Layout";
import { FCWithChildren } from "types/react.types";
import styles from "./ListingLayout.module.scss";

export const ListingLayout: FCWithChildren = ({ children }) => (
  <Layout
    containerClassName={styles.wrapper}
    mainClassName={styles.listing}
    header={<ListingHeader />}
  >
    <div className="container-listing-normal">{children}</div>
  </Layout>
);
