import { isEmpty, trim } from "lodash";
import { AreaShort } from "types/areas.types";

export const areaToString = (location?: AreaShort, showStreet?: boolean) => {
  const csz = [location?.city, `${location?.state} ${location?.zip_code || ""}`]
    .map((item) => trim(item))
    .filter((item) => !isEmpty(item))
    .join(", ");

  const address = {
    streetAddress: "",
    csz,
  };
  if (
    showStreet &&
    location?.street_address !== undefined &&
    location?.street_address !== ""
  ) {
    address.streetAddress = location.street_address;
  }
  return address;
};
