import { states } from "constants/options";
import classNames from "classnames";

import { StateLicense } from "types/therapist.types";

import licenseIcon from "./images/license.png";

import styles from "./StateLicenseListItem.module.scss";

const stateLicenseToString = (license: StateLicense | string) => {
  if (typeof license === "string")
    return states.find((state) => state.value === license)?.label;

  const stateName = states.find(
    (state) => state.value === license.state
  )?.label;

  return `${stateName || license.state} (#${license.number})`;
};

type Props = {
  license: StateLicense | string;
  className?: string;
};

export const StateLicenseListItem = ({ license, className }: Props) => {
  return (
    <p className={classNames(styles.root, className)}>
      {/* <img alt="license" src={licenseIcon} className={styles.licenseIcon} /> */}
      State Licensed ESA Therapist - {stateLicenseToString(license)}
    </p>
  );
};
