import cn from "classnames";
import { HTMLProps } from "react";

import { ReactComponent as PlusIcon } from "./icons/PlusIcon.svg";

import styles from "./AddButton.module.scss";

interface Props extends Omit<HTMLProps<HTMLButtonElement>, "type"> {
  type?: "button" | "submit" | "reset";
  className?: string;
}

export function AddPetButton({ children, className, ...restProps }: Props) {
  return (
    <button className={cn(styles.button, className)} {...restProps}>
      <div className={styles.iconWrapper}>
        <PlusIcon />
      </div>
      <div className={styles.text}>{children}</div>
    </button>
  );
}
