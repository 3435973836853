import cn from "classnames";
import { FC, forwardRef, HTMLProps } from "react";
import styles from "./ChoiceCard.module.scss";

interface Props extends HTMLProps<HTMLInputElement> {
  icon?: FC<HTMLProps<SVGElement>>;
  label?: string;
  iconSize?: number;
  hasLabel?: boolean;
  isMobile?: boolean;
}

export const ChoiceCard = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      icon: Icon,
      iconSize,
      label,
      hasLabel = true,
      checked,
      isMobile,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {isMobile ? (
          <label
            className={cn(
              className,
              styles.choiceCard,
              checked && styles.checked
            )}
            style={{ width: "150px", height: "150px" }}
          >
            <input type="radio" ref={ref} checked={checked} {...props} />
            {Icon && (
              <Icon
                alt={label}
                className={iconSize === 2 ? styles.catDogIcon : ""}
              />
            )}
            <div
              className={iconSize === 2 ? styles.catDogIconText : ""}
              style={{ width: "150px", textAlign: "center" }}
            >
              {hasLabel ? label : null}
            </div>
          </label>
        ) : (
          <label
            className={cn(
              className,
              styles.choiceCard,
              checked && styles.checked
            )}
          >
            <input type="radio" ref={ref} checked={checked} {...props} />
            {Icon && <Icon alt={label} />}
            {hasLabel ? label : null}
          </label>
        )}
      </>
    );
  }
);
