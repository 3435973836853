import cn from "classnames";
import React, { HTMLProps } from "react";
import { Link, To } from "react-router-dom";
import { FCWithChildren } from "types/react.types";
import styles from "./Button.module.scss";

export interface Props extends Omit<HTMLProps<HTMLButtonElement>, "size"> {
  color?:
    | "primary"
    | "outline-primary"
    | "green-light"
    | "outline-green-light"
    | "outline-gray"
    | "red"
    | "outline-red-light"
    | "yellow"
    | "turquoise"
    | "gray"
    | "link-gray"
    | "link";
  size?: "sm" | "xl";
  text?: string;
  type?: "button" | "submit" | "reset";
  isLink?: boolean;
  to?: To;
  href?: string;
  target?: "_blank";
  disabled?: boolean;
  isLoading?: boolean;
  rel?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  state?: any;
}

export const Button: FCWithChildren<Props> = ({
  color = "primary",
  onClick,
  size,
  text,
  children,
  type = "button",
  className,
  isLink,
  to = "/",
  href,
  target,
  disabled,
  isLoading,
  rel,
  state,
  ...rest
}) => {
  const classNames = cn(
    styles.btn,
    styles[`btn-${color}`],
    styles[`btn-${size}`],
    disabled && styles.disabled,
    className
  );

  if (isLink) {
    return href ? (
      <a href={href} target={target} className={classNames} rel={rel}>
        {text || children}
      </a>
    ) : (
      <Link
        to={to}
        state={state}
        className={classNames}
        target={target}
        rel={rel}
      >
        {text || children}
      </Link>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames}
      disabled={disabled || isLoading}
      {...rest}
    >
      {text || children}
      {isLoading && <div className={styles.spinner} />}
    </button>
  );
};
