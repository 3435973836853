import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/remove.svg";
import cn from "classnames";
import { ReactNode, useState } from "react";
import styles from "./Card.module.scss";

interface Value {
  caption?: string;
  text?: ReactNode;
  class?: string;
  el?: ReactNode;
}

interface Props {
  className?: string;
  iconClassName?: string;
  values: Value[];
  icon: string;
  petCount: number;
  onEdit?: () => void;
  onRemove?: () => void;
  editIcon?: ReactNode;
  removeIcon?: ReactNode;
  buttonClass?: string;
  withAccordion?: boolean;
  accordionContent?: ReactNode;
  withFlex?: boolean;
}

export const PetCard = ({
  className,
  iconClassName,
  values,
  icon,
  petCount,
  onEdit,
  onRemove,
  editIcon = <EditIcon />,
  removeIcon = <RemoveIcon />,
  buttonClass,
  withAccordion,
  accordionContent,
  withFlex = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const withContent = withAccordion && accordionContent && isOpen;

  return (
    <>
      <div
        className={cn(
          styles.card,
          className,
          withAccordion && styles.accordion,
          "container"
        )}
        onClick={() => withAccordion && setIsOpen((prevState) => !prevState)}
        style={{ height: "227px", width: "357px" }}
      >
        <div
          className="row"
          style={{ width: "100%", height: "100%", paddingRight: "0" }}
        >
          <div
            className="col-4"
            style={{
              borderRadius: "10px 0 0 10px",
              background: "#21816A",
              height: "100%",
              width: "30%",
              paddingRight: "0",
            }}
          >
            {icon === "cat" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="78"
                height="79"
                viewBox="5 -10 78 79"
                fill="none"
              >
                <g filter="url(#filter0_d_671_1830)">
                  <circle cx="36" cy="35.5" r="31" fill="#FAFAFA" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.1626 36.1554C18.1626 34.3646 18.4509 32.5932 19.0204 30.8907C19.0204 30.8907 19.0332 30.8529 19.0487 30.8063C19.0644 30.7599 19.077 29.7481 19.077 28.5468V17.9181C19.077 17.8762 19.079 17.8387 19.0816 17.8052C19.0872 17.7434 19.1329 17.7055 19.174 17.724C19.2152 17.7425 20.0059 18.3648 20.9421 19.1109L23.4849 21.1371C24.4209 21.8832 25.3265 22.39 25.5069 22.2689L25.8341 22.0498C28.8303 20.0453 32.3454 18.9857 36.0002 18.9857C39.6542 18.9857 43.1694 20.0453 46.1656 22.0498C46.1656 22.0498 46.312 22.1481 46.4927 22.2689C46.6735 22.39 47.5788 21.8832 48.5151 21.1371L51.0582 19.1103C51.9944 18.3645 52.7851 17.7422 52.8262 17.7237C52.8673 17.7055 52.9131 17.7434 52.9186 17.8052C52.9216 17.8387 52.9233 17.8762 52.9233 17.9181V28.5468C52.9233 29.7481 52.9359 30.7599 52.9516 30.8063L52.9799 30.8907C53.5488 32.5923 53.8374 34.3637 53.8374 36.1554C53.8374 45.6224 45.8359 53.3245 36.0003 53.3245C26.1644 53.3245 18.1626 45.6224 18.1626 36.1554ZM55.0859 28.1931V17.9181C55.0859 16.943 54.6879 16.1564 53.9945 15.7602C53.6286 15.5511 53.2117 15.4687 52.7769 15.5109C52.083 15.5786 50.833 16.525 49.8959 17.2697L48.3938 18.4631C47.4564 19.2077 45.8743 19.2922 44.7943 18.7789C42.0714 17.485 39.0792 16.8104 36.0002 16.8104C32.921 16.8104 29.9286 17.485 27.2053 18.7789C26.1254 19.2922 24.5445 19.2065 23.6082 18.4601L20.5255 16.0031L20.4071 15.9212C19.5746 15.4224 18.6991 15.3639 18.0054 15.7602C17.3123 16.1564 16.9144 16.9433 16.9144 17.9181V28.1931C16.9144 29.3947 16.601 31.2937 16.3648 32.4715C16.1222 33.6825 16 34.9145 16 36.1554C16 46.8219 24.9722 55.5 36.0003 55.5C47.028 55.5 56 46.8219 56 36.1554C56 34.9145 55.8778 33.6825 55.6352 32.4715C55.3993 31.294 55.0859 29.3947 55.0859 28.1931Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M36.8652 42.2489V41.7773C36.8652 41.5172 37.2463 41.2145 37.658 40.9755C37.9014 40.8341 38.1309 40.659 38.3391 40.4496C39.631 39.1501 39.1357 38.0967 37.2323 38.0967H34.7676C32.8645 38.0967 32.3689 39.1501 33.6608 40.4496C33.8693 40.659 34.0985 40.8341 34.342 40.9755C34.7539 41.2145 35.135 41.5172 35.135 41.7773V42.2489C35.135 43.3121 34.2755 44.1766 33.2185 44.1766C32.6902 44.1766 32.2112 43.9605 31.8642 43.6115C31.2764 43.0202 30.9149 42.2489 30.4373 42.2489C29.9593 42.2489 29.5267 43.3029 30.1958 44.2983C30.8515 45.2741 31.9618 45.9169 33.2185 45.9169C33.7664 45.9169 34.2863 45.7943 34.7525 45.5753C35.5449 45.2031 36.4553 45.2031 37.2478 45.5753C37.714 45.7943 38.2338 45.9169 38.7817 45.9169C40.0384 45.9169 41.1483 45.2741 41.8044 44.2983C42.4733 43.3029 42.0406 42.2489 41.5627 42.2489C41.0848 42.2489 40.7235 43.0202 40.1357 43.6115C39.7888 43.9605 39.3097 44.1766 38.7817 44.1766C37.7247 44.1766 36.8652 43.3121 36.8652 42.2489Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30.8124 32.2143C30.8124 31.1014 29.9155 30.1992 28.809 30.1992C27.7025 30.1992 26.8057 31.1014 26.8057 32.2143C26.8057 33.3274 27.7025 34.2294 28.809 34.2294C29.9155 34.2294 30.8124 33.3274 30.8124 32.2143Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M45.1945 32.2143C45.1945 31.1014 44.2973 30.1992 43.1911 30.1992C42.0846 30.1992 41.1877 31.1014 41.1877 32.2143C41.1877 33.3274 42.0846 34.2294 43.1911 34.2294C44.2973 34.2294 45.1945 33.3274 45.1945 32.2143Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M24.6743 39.7312H24.2961C23.1019 39.7312 22.1335 40.0233 22.1335 40.3837C22.1335 40.7441 23.1019 41.0362 24.2961 41.0362H24.6743C25.8688 41.0362 26.8369 40.7441 26.8369 40.3837C26.8369 40.0233 25.8688 39.7312 24.6743 39.7312Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M24.7749 38.0558L25.0959 38.1145C26.2712 38.3286 27.2756 38.2148 27.3398 37.8602C27.4037 37.5056 26.5027 37.0446 25.3274 36.8305L25.0064 36.7718C23.831 36.5575 22.8266 36.6713 22.7625 37.0259C22.6986 37.3804 23.5995 37.8414 24.7749 38.0558Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.7625 43.7416C22.8263 44.0962 23.831 44.21 25.0064 43.9959L25.3274 43.9372C26.5024 43.7231 27.4033 43.2621 27.3395 42.9075C27.2756 42.5529 26.2712 42.4392 25.0959 42.6532L24.7749 42.7119C23.5995 42.926 22.6986 43.387 22.7625 43.7416Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M47.3257 41.0363H47.7042C48.8987 41.0363 49.8667 40.7442 49.8667 40.3838C49.8667 40.0233 48.8987 39.7312 47.7042 39.7312H47.3257C46.1311 39.7312 45.1631 40.0233 45.1631 40.3838C45.1631 40.7442 46.1311 41.0363 47.3257 41.0363Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M46.9044 38.1144L47.2251 38.0561C48.4004 37.8417 49.3013 37.3806 49.2372 37.0261C49.1734 36.6715 48.1689 36.5577 46.9936 36.7721L46.6728 36.8305C45.4975 37.0449 44.5966 37.5059 44.6607 37.8605C44.7246 38.2151 45.729 38.3288 46.9044 38.1144Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M46.6728 43.9372L46.9936 43.9956C48.1686 44.21 49.1734 44.0959 49.2372 43.7416C49.3011 43.387 48.4001 42.926 47.2248 42.7117L46.9044 42.6532C45.729 42.4392 44.7246 42.5529 44.6604 42.9075C44.5965 43.2621 45.4975 43.7231 46.6728 43.9372Z"
                    fill="#21816A"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_671_1830"
                    x="0"
                    y="0.5"
                    width="78.5"
                    height="78"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_671_1830"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_671_1830"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="78"
                height="79"
                viewBox="5 -10 78 79"
                fill="none"
              >
                <g filter="url(#filter0_d_671_1861)">
                  <circle cx="36" cy="35.5" r="31" fill="#FAFAFA" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M50.3009 33.3398C49.8266 33.3398 49.4419 34.1 49.4419 35.0375V39.0169C49.4419 46.3423 43.412 52.3022 36.0002 52.3022C28.5887 52.3022 22.5585 46.3423 22.5585 39.0169V35.0375C22.5585 34.1 22.1741 33.3398 21.6998 33.3398C21.2253 33.3398 20.8408 34.1 20.8408 35.0375V39.0169C20.8408 47.2787 27.6412 54 36.0002 54C44.3595 54 51.1599 47.2787 51.1599 39.0169V35.0375C51.1599 34.1 50.7752 33.3398 50.3009 33.3398Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.8368 44.5352C32.4227 44.1259 32.0563 43.5921 31.582 43.5921C31.1077 43.5921 30.6871 44.4084 31.1922 45.2019C31.7355 46.0557 32.6974 46.6243 33.7909 46.6243C34.2172 46.6243 34.6233 46.5376 34.9924 46.381C35.6188 46.1155 36.3817 46.1155 37.0084 46.381C37.3773 46.5376 37.7833 46.6243 38.2097 46.6243C39.3035 46.6243 40.2652 46.0557 40.8088 45.2019C41.3139 44.4084 40.8933 43.5921 40.419 43.5921C39.9445 43.5921 39.5781 44.1259 39.164 44.5352C38.9194 44.7769 38.5818 44.9264 38.2097 44.9264C37.4651 44.9264 36.8593 44.3278 36.8593 43.5921V43.2374C36.8593 43.0412 37.2342 42.7933 37.6392 42.5619C37.879 42.425 38.1045 42.2556 38.3097 42.0531C39.5848 40.7925 39.0957 39.7708 37.2168 39.7708H34.784C32.9053 39.7708 32.416 40.7925 33.6913 42.0531C33.8963 42.2556 34.1219 42.425 34.3615 42.5619C34.7666 42.7933 35.1413 43.0412 35.1413 43.2374V43.5921C35.1413 44.3278 34.5354 44.9264 33.7909 44.9264C33.4188 44.9264 33.0813 44.7769 32.8368 44.5352Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30.2888 32.5562C29.4098 32.5562 28.6975 33.2602 28.6975 34.1289C28.6975 34.9975 29.4098 35.7016 30.2888 35.7016C31.1676 35.7016 31.88 34.9975 31.88 34.1289C31.88 33.2602 31.1676 32.5562 30.2888 32.5562Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M43.3034 34.1289C43.3034 33.2603 42.591 32.5562 41.7121 32.5562C40.8332 32.5562 40.1208 33.2603 40.1208 34.1289C40.1208 34.9976 40.8332 35.7016 41.7121 35.7016C42.591 35.7016 43.3034 34.9976 43.3034 34.1289Z"
                    fill="#21816A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M48.7764 21.0093C45.8686 19.0966 42.4837 18 39.489 18C39.4876 18 32.5082 18.0009 32.5082 18.0009C29.513 18.0014 26.1276 19.0987 23.2195 21.0122C20.0827 23.0762 18.0208 25.7476 17.4143 28.534C14.5085 41.8829 16.8564 43.6253 17.6276 44.1975C18.2689 44.6734 19.0417 44.8196 19.9363 44.6367C20.8654 44.4468 21.3518 43.6953 21.1267 43.2826C20.9016 42.8701 20.2965 42.8013 19.7107 42.9482C19.2608 43.0613 18.9088 43.0253 18.6591 42.8399C18.1336 42.4501 16.6121 40.2916 19.0938 28.891C20.0192 24.6402 26.0804 19.6991 32.5082 19.6989L39.4876 19.6977C45.9158 19.6977 51.9774 24.6377 52.9036 28.8874C55.3883 40.2914 53.867 42.4501 53.3417 42.8399C53.0916 43.0253 52.7396 43.061 52.2901 42.9482C51.7044 42.8013 51.0995 42.8701 50.8744 43.2826C50.6493 43.6953 50.9425 44.3067 51.584 44.5108C52.0083 44.6457 52.4077 44.7132 52.7817 44.7132C53.3786 44.7132 53.9103 44.5415 54.3733 44.198C55.1447 43.6253 57.4923 41.8827 54.5831 28.5301C53.9759 25.7435 51.9138 23.0728 48.7764 21.0093Z"
                    fill="#21816A"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_671_1861"
                    x="0"
                    y="0.5"
                    width="78.5"
                    height="78"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_671_1861"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_671_1861"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            )}
          </div>
          <div
            key={values[0].caption}
            className="col-6"
            style={{ width: "60%", paddingRight: "0" }}
          >
            {values.map((v) => (
              <>
                {v?.el ? (
                  v.el
                ) : (
                  <>
                    <div
                      className={styles.caption}
                      style={{ paddingTop: "1rem", fontSize: "15px" }}
                    >
                      {v.caption}
                    </div>
                    <div className={styles.text}>{v.text}</div>
                  </>
                )}
              </>
            ))}
            {onEdit && (
              <div style={{ paddingTop: "1rem" }}>
                <button
                  type="button"
                  aria-label="Edit"
                  onClick={onEdit}
                  style={{
                    display: "flex",
                    padding: "8px 16px 8px 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "8px",
                    background: "#21816A",
                    border: "#21816A",
                    color: "#FAFAFA",
                    fontSize: "14px",
                  }}
                >
                  Manage Pet
                </button>
              </div>
            )}
          </div>
          <div className="col-2" style={{ width: "10%", paddingRight: "0" }}>
            {onRemove && (
              <button
                type="button"
                className={cn(styles.remove, buttonClass)}
                aria-label="Remove"
                onClick={onRemove}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="18 12 20 30"
                  fill="none"
                >
                  <g>
                    <circle cx="28" cy="27.5" r="15" fill="#21816A" />
                    <path
                      d="M26.3281 20.3141L25.7344 21.3125H30.2656L29.6719 20.3141C29.625 20.2367 29.5469 20.1875 29.4625 20.1875H26.5344C26.45 20.1875 26.3719 20.2332 26.325 20.3141H26.3281ZM30.9219 19.3789L32.0688 21.3125H34.25C34.6656 21.3125 35 21.6887 35 22.1562C35 22.6238 34.6656 23 34.25 23H34V33.6875C34 35.2414 32.8813 36.5 31.5 36.5H24.5C23.1187 36.5 22 35.2414 22 33.6875V23H21.75C21.3344 23 21 22.6238 21 22.1562C21 21.6887 21.3344 21.3125 21.75 21.3125H23.9313L25.0781 19.3754C25.4031 18.8305 25.95 18.5 26.5344 18.5H29.4625C30.0469 18.5 30.5938 18.8305 30.9187 19.3754L30.9219 19.3789ZM23.5 23V33.6875C23.5 34.3098 23.9469 34.8125 24.5 34.8125H31.5C32.0531 34.8125 32.5 34.3098 32.5 33.6875V23H23.5ZM26 25.25V32.5625C26 32.8719 25.775 33.125 25.5 33.125C25.225 33.125 25 32.8719 25 32.5625V25.25C25 24.9406 25.225 24.6875 25.5 24.6875C25.775 24.6875 26 24.9406 26 25.25ZM28.5 25.25V32.5625C28.5 32.8719 28.275 33.125 28 33.125C27.725 33.125 27.5 32.8719 27.5 32.5625V25.25C27.5 24.9406 27.725 24.6875 28 24.6875C28.275 24.6875 28.5 24.9406 28.5 25.25ZM31 25.25V32.5625C31 32.8719 30.775 33.125 30.5 33.125C30.225 33.125 30 32.8719 30 32.5625V25.25C30 24.9406 30.225 24.6875 30.5 24.6875C30.775 24.6875 31 24.9406 31 25.25Z"
                      fill="#FAFAFA"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_671_1856"
                      x="0"
                      y="0.5"
                      width="62"
                      height="62"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="3" dy="4" />
                      <feGaussianBlur stdDeviation="8" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_671_1856"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_671_1856"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
            )}
            {withAccordion && (
              <button
                type="button"
                className={cn(styles.arrow, buttonClass)}
                aria-label="Remove"
                onClick={onRemove}
              >
                {withContent ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </button>
            )}
          </div>
        </div>
      </div>
      {withContent && accordionContent}
    </>
  );
};
