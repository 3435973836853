import cn from "classnames";
import { forwardRef, HTMLProps } from "react";
import styles from "./Radio.module.scss";

interface Props extends HTMLProps<HTMLInputElement> {
  label?: string;
  cat: boolean;
  dog: boolean;
}

export const RadioAnimal = forwardRef<HTMLInputElement, Props>(
  ({ className, label, checked, cat = false, dog = false, ...props }, ref) => {
    return (
      <label className={cn(className, styles.radio, checked && styles.checked)}>
        <input type="radio" ref={ref} checked={checked} {...props} />
        {label}
        {(cat && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="24"
            viewBox="0 0 28 24"
            fill="none"
          >
            <path
              d="M15.4579 9C14.49 9 10.3404 9.09375 7.66666 13.0312V9C7.66666 6.51562 5.60624 4.5 3.06666 4.5C2.22237 4.5 1.53333 5.175 1.53333 6C1.53333 6.825 2.22237 7.5 3.06666 7.5C3.91095 7.5 4.59999 8.17406 4.59999 9V21C4.59999 22.6523 5.9776 24 7.66666 24H16.1C16.5253 24 16.8667 23.666 16.8667 23.2922V22.5C16.8667 21.6741 16.1776 21 15.3333 21H13.8L19.9333 16.5V23.25C19.9333 23.666 20.2747 24 20.7 24H22.2333C22.6586 24 23 23.666 23 23.25V13.5891C22.5088 13.7121 21.9995 13.8 21.4667 13.8C18.5054 13.8 16.0281 11.7375 15.4579 9ZM23 4.5H19.9333L16.8667 1.5V7.8C16.8667 10.2844 18.9271 12.2578 21.4667 12.2578C24.0062 12.2578 26.0667 10.2844 26.0667 7.8V1.5L23 4.5ZM19.55 8.25C19.1247 8.25 18.7833 7.91602 18.7833 7.5C18.7833 7.08398 19.1247 6.75 19.55 6.75C19.9753 6.75 20.3167 7.08398 20.3167 7.5C20.3167 7.91602 19.9765 8.25 19.55 8.25ZM23.3833 8.25C22.9581 8.25 22.6167 7.91602 22.6167 7.5C22.6167 7.08398 22.9581 6.75 23.3833 6.75C23.8086 6.75 24.15 7.08398 24.15 7.5C24.15 7.91602 23.8098 8.25 23.3833 8.25Z"
              fill="#4C5D59"
            />
          </svg>
        )) ||
          (dog && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="24"
              viewBox="0 0 27 24"
              fill="none"
            >
              <g clip-path="url(#clip0_671_1813)">
                <path
                  d="M15.5953 0.930469C15.6844 0.393516 16.1484 0 16.6922 0C17.0437 0 17.3719 0.165 17.5828 0.445406L18.375 1.5H20.8172C21.4125 1.5 21.989 1.73719 22.4109 2.15906L23.25 3H25.875C26.4984 3 27 3.50391 27 4.125V5.25C27 7.32188 25.3219 9 23.25 9H20.0015L19.7625 10.4297L14.5125 7.42969L15.5953 0.930469ZM21 3C20.5875 3 20.25 3.33562 20.25 3.75C20.25 4.16437 20.5875 4.5 21 4.5C21.4125 4.5 21.75 4.16437 21.75 3.75C21.75 3.33562 21.4125 3 21 3ZM19.5 12.0047V22.5C19.5 23.3297 18.8297 24 18 24H16.5C15.6703 24 15 23.3297 15 22.5V17.1C13.8328 17.6766 12.6 18 11.25 18C9.89998 18 8.62498 17.6766 7.49998 17.1V22.5C7.49998 23.3297 6.82967 24 5.99998 24H4.49998C3.6717 24 2.99998 23.3297 2.99998 22.5V11.7094C1.65138 11.1984 0.592478 10.0547 0.226665 8.59219L0.0447809 7.86563C-0.156163 7.05938 0.332509 6.24375 1.13623 6.00469C1.93967 5.84531 2.75435 6.33281 2.95545 7.13437L3.13732 7.86563C3.3042 8.53125 3.9042 8.95781 4.59232 8.95781H14.2406L19.5 12.0047Z"
                  fill="#162521"
                />
              </g>
              <defs>
                <clipPath id="clip0_671_1813">
                  <rect width="27" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ))}
      </label>
    );
  }
);
