import { string } from "prop-types";

export interface PaymentData {
  cardholder_name: string;
  card_number: string;
  exp_date: string;
  cvv_code: string;
}

export type PetKind = "cat" | "dog";
export interface PetInfo {
  kind: PetKind;
  name: string;
  weight: number;
}

export interface Pet extends PetInfo {
  id: number;
}
export interface Address {
  street_address: string;
  unit: string;
  city: string;
  zip: string;
  state: string;
}

export class CheckoutSubmit {
  address: Address | null | undefined;

  paymentData: PaymentData | null | undefined;

  pets: Pet[] = [];

  offers: string[] = [];

  letter = "";

  visitorid = "";
}
