import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { useCallback, useEffect, useRef } from "react";
import { DeepPartial, FieldValues, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

type Config<T extends FieldValues> = {
  defaultValues?: DeepPartial<T>;
  initialPathname?: string;
  shouldRememberPathname?: boolean;
  askIfShouldRestoreValues?: () => Promise<boolean>;
};

export const useFormPersist = <T extends FieldValues>(
  localStorageKey: string,
  {
    defaultValues,
    initialPathname,
    shouldRememberPathname,
    askIfShouldRestoreValues,
  }: Config<T>
) => {
  const location = useLocation();
  const navigate = useNavigateWithQuery();
  const methods = useForm<T>();
  const shouldChangeStorage = useRef(false);
  const watchedValues = methods.watch();

  const initializeForm = useCallback(
    (values?: DeepPartial<T>, pathname?: string) => {
      // @ts-ignore
      methods.reset(values);
      if (pathname && shouldRememberPathname) {
        navigate(pathname);
      }
      setTimeout(() => {
        shouldChangeStorage.current = true;
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods.reset, navigate]
  );

  useEffect(() => {
    const savedData = localStorage.getItem(localStorageKey);

    if (!savedData) {
      initializeForm(defaultValues, initialPathname);
      return;
    }

    const { pathname, ...parsedData } = JSON.parse(savedData);
    const isParsedDataValid =
      pathname &&
      Object.keys(parsedData).length &&
      (!defaultValues ||
        Object.keys(parsedData).every((key) => key in defaultValues));

    if (!isParsedDataValid) {
      initializeForm(defaultValues, initialPathname);
      return;
    }

    if (!askIfShouldRestoreValues) {
      initializeForm(parsedData, pathname);
      return;
    }

    askIfShouldRestoreValues().then((shouldRestore) => {
      if (shouldRestore) {
        initializeForm(parsedData, pathname);
      } else {
        initializeForm(defaultValues, initialPathname);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, localStorageKey]);

  useEffect(() => {
    if (!shouldChangeStorage.current) {
      return;
    }

    const valuesWithPathname = {
      ...watchedValues,
      pathname: location.pathname,
    };

    localStorage.setItem(localStorageKey, JSON.stringify(valuesWithPathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageKey, watchedValues]);

  return methods;
};
