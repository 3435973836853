import { FUNNEL_QUESTIONS } from "constants/funnel";
import { HomeLayout } from "components/Layout/HomeLayout";
import { useGA } from "hooks/useGA";
import { useTitle } from "hooks/useTitle";
import { Question } from "pages/Funnel/Question";
import { Success, successPath } from "pages/Funnel/Success";
import { homePath } from "pages/Home";
import { NotFound } from "pages/NotFound";
import { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { therapistProfileService } from "services/therapistProfileService";
import { visitorIdService } from "services/visitoridService";
import { therapistName } from "utils/therapistName";
import { useSnapshot } from "valtio";
import styles from "./Funnel.module.scss";

export const funnelPath = "/funnel";

export const checkOutPath = "/final_checkout";

export const Funnel = () => {
  const [searchParams] = useSearchParams();
  const { currentProfile } = useSnapshot(therapistProfileService);
  const { setTitle } = useTitle();
  useGA().ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  const therapistId = useMemo(
    () => searchParams.get("therapistId"),
    [searchParams]
  );
  const visitorid = useMemo(
    () => searchParams.get("visitorid"),
    [searchParams]
  );

  visitorIdService.setVisitorID(visitorid);

  useEffect(() => {
    if (therapistId) {
      therapistProfileService.getById(therapistId);
    }
  }, [therapistId]);

  useEffect(() => {
    if (currentProfile) {
      setTitle(
        `TheraPass ESA: Connect with ${therapistName({
          ...currentProfile,
          ...currentProfile.user,
        })}`
      );
    }
  }, [setTitle, currentProfile]);

  if (!therapistId) {
    return <Navigate to={homePath} />;
  }

  return (
    <HomeLayout hasHeader={false}>
      <div className={styles.funnelPage}>
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={{ pathname: "./0", search: searchParams.toString() }}
              />
            }
          />
          {FUNNEL_QUESTIONS.map((question, index) => (
            <Route
              key={question.name}
              path={`/${index}`}
              element={
                <Question
                  question={question}
                  index={index}
                  position={
                    // eslint-disable-next-line no-nested-ternary
                    index === 0
                      ? "first"
                      : index === FUNNEL_QUESTIONS.length - 1
                      ? "last"
                      : "middle"
                  }
                />
              }
            />
          ))}
          <Route path={successPath} element={<Success />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </HomeLayout>
  );
};
