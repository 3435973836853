import cn from "classnames";
import { FormatProfileField } from "components/FormatProfileField";
import { OfferDescription } from "components/OfferDescription/OfferDescription";
import { OfferIcon } from "components/OfferIcon/OfferIcon";

import { Button } from "components/UIKit/Button";
import { StepProps } from "pages/FinalCheckout/types";
import { FC, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {
  finalCheckoutService,
  GetProductArgs,
  ProductTypes,
  Product,
} from "services/finalCheckoutService";
import { visitorIdService } from "services/visitoridService";
import { defaultEsaLetterType, showEsaLetterSelection } from "utils/env";
import { useSnapshot } from "valtio";

import styles from "./Offers.module.scss";
import "react-tooltip/dist/react-tooltip.css";

let availableOffers: {
  name: ProductTypes;
  title?: string;
  price?: number;
  duration?: string;
  icon?: string;
  byline?: string;
  bylineP1?: string;
  bylineP2?: string;
  description?: string;
}[] = [
  {
    name: "landlord_guaranteed_acceptance_insurance",
    duration: "60 days",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia officiis perferendis voluptatum tempora, ex doloremque nihil odio est repellat inventore, quod dolorum eligendi ducimus asperiores corrupti reprehenderit earum, eum explicabo.",
  },
];

export const Offers: FC<StepProps> = ({ handlePrevStep, handleNextStep }) => {
  const [searchParams] = useSearchParams();
  const therapistId = useMemo(
    () => searchParams.get("therapistId"),
    [searchParams]
  );
  const visitorid = useMemo(
    () => searchParams.get("visitorid"),
    [searchParams]
  );
  visitorIdService.setVisitorID(visitorid);
  visitorIdService.trackEvent("Offers", "", "", "", "");

  useEffect(() => {
    if (therapistId) {
      finalCheckoutService.setTherapistID(therapistId);
    }
  }, [therapistId]);

  const { productData } = useSnapshot(finalCheckoutService);

  useEffect(() => {
    const getProductArgs: GetProductArgs = {
      therapistKey: therapistId ?? "",
      visitorId: visitorid ?? "",
      state: "",
      cartid: "",
    };

    finalCheckoutService.getProductData(getProductArgs);
    document.getElementById("root")?.scrollTo({ top: 0 });
  }, [therapistId, visitorid]);

  if (!showEsaLetterSelection) {
    finalCheckoutService.selectOffer(defaultEsaLetterType);
  }
  availableOffers = availableOffers.map((offer) => {
    const product: Product | undefined = productData.get(offer.name);
    const updatedOffer = { ...offer };
    if (product) {
      updatedOffer.price = product.price;
      updatedOffer.title = product.name;
      updatedOffer.icon = product.icon;
      updatedOffer.byline = product.byline;
      updatedOffer.description = product.description;

      if (product.byline?.split("|") != null) {
        if (product.byline?.split("|").length > 1) {
          // eslint-disable-next-line no-param-reassign
          updatedOffer.bylineP1 = product.byline?.split("|")[0];
          // eslint-disable-next-line no-param-reassign
          updatedOffer.bylineP2 = offer.byline?.split("|")[1];
        } else if (product.byline?.split("|").length > 0) {
          // eslint-disable-next-line no-param-reassign
          updatedOffer.bylineP1 = product.byline?.split("|")[0];
        }
      }
    }
    return updatedOffer;
  });
  const getLandlordInsurance = () => {
    finalCheckoutService.selectOffer(
      "landlord_guaranteed_acceptance_insurance"
    );
    handleNextStep();
  };
  const rejectLandlordInsurance = () => {
    finalCheckoutService.deSelectOffer(
      "landlord_guaranteed_acceptance_insurance"
    );
    handleNextStep();
  };
  const handlePrevClick = () => {
    finalCheckoutService.deSelectOffer("multi_esa_letter");
    finalCheckoutService.deSelectOffer("esa_letter");
    handlePrevStep();
  };

  return (
    <section className={cn(styles.sectionOffers, "default-typography")}>
      <div className="container-normal">
        <h1 className="text-center mb-30 mb-xs-50">Additional items</h1>

        <div className="position-relative">
          <div
            className={cn(
              "row",
              "justify-content-center",
              styles.sectionOffers__cardsRow
            )}
          >
            {availableOffers.map((offer) => {
              return (
                <div
                  className="col-xxs-auto d-flex position-relative"
                  key={offer.name}
                >
                  <div
                    className={
                      finalCheckoutService.isOfferSelected(offer.name)
                        ? cn(styles.cardOffer, styles.cardOffer__active)
                        : styles.cardOffer
                    }
                  >
                    <div className={styles.cardOffer__topContent}>
                      <div className={styles.cardOffer__iconBlock}>
                        <OfferIcon
                          className={styles.cardOffer__icon}
                          icon={offer.icon}
                        ></OfferIcon>
                      </div>
                      <div className={styles.cardOffer__titleHolder}>
                        <h4 className="mb-10">{offer.title}</h4>
                        {offer.bylineP1 !== null &&
                          offer.bylineP1 !== undefined &&
                          offer.bylineP1.length > 0 && (
                            <div className={styles.cardOffer__subTitlesList}>
                              <h6 className={styles.cardOffer__subTitle}>
                                {offer.bylineP1}
                              </h6>
                              {offer.bylineP2 !== null &&
                                offer.bylineP2 !== undefined &&
                                offer.bylineP2.length > 0 && (
                                  <h6 className={styles.cardOffer__subTitle}>
                                    {offer.bylineP2}
                                  </h6>
                                )}
                            </div>
                          )}
                      </div>
                      <div className="text-center">
                        <h2
                          className="d-inline-block mb-0 align-text-bottom text-primary lh-1"
                          style={{ fontSize: "34px" }}
                        >
                          ${offer.price}
                        </h2>
                      </div>
                    </div>
                    <div className={styles.cardOffer__bottomContent}>
                      <div className={styles.cardOffer__bottomContentInner}>
                        <OfferDescription
                          description={offer.description}
                          className={styles.cardOffer__description}
                        ></OfferDescription>

                        {offer.name === "esa_letter" ||
                        offer.name === "multi_esa_letter" ? (
                          ""
                        ) : (
                          <div className={styles.cardOffer__btnBlock}>
                            <div className="row">
                              <div className="col-xxs-6">
                                <Button
                                  className="w-100 mb-15 mb-xxs-0 btn-sm"
                                  onClick={getLandlordInsurance}
                                >
                                  Yes
                                </Button>
                              </div>
                              <div className="col-xxs-6">
                                <Button
                                  className="w-100 mb-15 mb-xxs-0 btn-sm"
                                  color="outline-gray"
                                  onClick={rejectLandlordInsurance}
                                >
                                  No
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Tooltip id="info-tooltip" place="bottom" />
        </div>

        <div className="row justify-content-center pt-10 pt-md-10">
          <div className="col-xs-8 col-md-6">
            <div className={cn("row", "gutter-100", styles.reverseMobile)}>
              <div className="col-xxs-3" />
              <div className="col-xxs-6">
                <Button
                  className="w-100 mb-15 mb-xxs-0"
                  color="outline-gray"
                  onClick={handlePrevClick}
                >
                  Back
                </Button>
              </div>
              <div className="col-xxs-3" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
