import { wrap } from "module";
import idCard from "assets/images/card-front.png";
import cn from "classnames";

import { FormatProfileField } from "components/FormatProfileField";
import { OfferDescription } from "components/OfferDescription/OfferDescription";
import { Button } from "components/UIKit/Button";
import { DisplayProduct, StepProps } from "pages/FinalCheckout/types";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  finalCheckoutService,
  GetProductArgs,
  Product,
  ProductTypes,
} from "services/finalCheckoutService";
import { visitorIdService } from "services/visitoridService";
import { showEsaLetterSelection } from "utils/env";
import { useSnapshot } from "valtio";

import styles from "./EsaLetterSelection.module.scss";

const basicLetterProductDisplay: DisplayProduct = {
  name: "esa_letter",
  description: "ESA Letter",
  product_type: "esa_letter",
  product_key: "esa_letter",
};

const premiumLetterProudctDisplay: DisplayProduct = {
  name: "multi_esa_letter",
  description: "Video interview with therapist.",
  product_type: "multi_esa_letter",
  product_key: "multi_esa_letter",
};

export const EsaLetterSelection: FC<StepProps> = ({
  handlePrevStep,
  handleNextStep,
}) => {
  const { pets } = useSnapshot(finalCheckoutService);
  if (!showEsaLetterSelection) {
    handleNextStep();
  }
  const [searchParams] = useSearchParams();
  const therapistId = useMemo(
    () => searchParams.get("therapistId"),
    [searchParams]
  );
  const visitorid = useMemo(
    () => searchParams.get("visitorid"),
    [searchParams]
  );
  visitorIdService.setVisitorID(visitorid);
  visitorIdService.trackEvent("ESALetterSelection", "", "", "", "");

  useEffect(() => {
    if (therapistId) {
      finalCheckoutService.setTherapistID(therapistId);
    }
  }, [therapistId]);

  const {
    chosenOffers,
    productData,
    chosenLetter: chosenProduct,
  } = useSnapshot(finalCheckoutService);

  useEffect(() => {
    const getProductArgs: GetProductArgs = {
      therapistKey: therapistId ?? "",
      visitorId: visitorid ?? "",
      state: "",
      cartid: "",
    };

    finalCheckoutService.getProductData(getProductArgs);
    document.getElementById("root")?.scrollTo({ top: 0 });
  }, [therapistId, visitorid]);

  const [selectedOffer, setSelectedOffer] = useState(false);
  const availableOffers: Product[] = [];

  const basicLetter = productData.get("esa_letter");

  const premiumLetter = productData.get("multi_esa_letter");
  // Add premium first so it shows first.
  if (premiumLetter != null && pets.length > 1) {
    availableOffers.push(premiumLetter);
  }
  if (basicLetter != null && pets.length === 1) {
    availableOffers.push(basicLetter);
  }
  let pagetitle = "";
  availableOffers.forEach((offer) => {
    if (offer.pagetitle !== "") {
      pagetitle = offer.pagetitle;
    }
    if (offer.byline?.split("|") != null) {
      if (offer.byline?.split("|").length > 1) {
        // eslint-disable-next-line no-param-reassign
        offer.bylinePart1 = offer.byline?.split("|")[0];
        // eslint-disable-next-line no-param-reassign
        offer.bylinePart2 = offer.byline?.split("|")[1];
      } else if (offer.byline?.split("|").length > 0) {
        // eslint-disable-next-line no-param-reassign
        offer.bylinePart1 = offer.byline?.split("|")[0];
        // eslint-disable-next-line no-param-reassign
        offer.bylinePart2 = "";
      }
    }
  });

  const handleClick = () => {
    handleNextStep();
  };

  return (
    <section className={cn(styles.sectionOffers, "default-typography")}>
      <div className="container">
        {pagetitle !== "" && (
          <h1 className="h2 fw-semibold text-xxs-center mb-20 mb-xxs-40">
            {pagetitle}
          </h1>
        )}
        <div className="row gutter-16 justify-content-center">
          {availableOffers.map((offer) => (
            <div
              className={cn(
                styles.productContainer,
                "col-md-4 col-auto as-end"
              )}
            >
              <div
                className={cn(
                  styles.price_wrapper,
                  styles.is_position_relative,
                  styles.animation,
                  offer.Bestdeal ? styles.Bestdeal : styles.dummy
                )}
              >
                <div
                  className={cn(
                    styles.price,
                    styles.is_white,
                    offer.Bestdeal ? styles.is_green_bg : styles.is_blue_bg,
                    styles.is_position_relative
                  )}
                >
                  <span>${offer.price}</span>
                  {offer.PriceByLine !== "" && (
                    <span className={styles.txt_small}>(Just $84/pet)</span>
                  )}
                </div>
                {offer.Bestdeal === true && (
                  <h5
                    className={cn(
                      styles.is_uppercase,
                      styles.center,
                      styles.is_position_relative
                    )}
                  >
                    Best Deal
                  </h5>
                )}
                <div
                  className={styles.pets}
                  dangerouslySetInnerHTML={{
                    __html: offer.ProductNamePart1,
                  }}
                ></div>
                <h2
                  className={styles.center}
                  dangerouslySetInnerHTML={{
                    __html: offer.ProductNamePart2,
                  }}
                ></h2>
                {offer.isletter === true && (
                  <div className="row gutter-40 justify-content-center">
                    <div className="col-auto">
                      <img src={idCard} />
                    </div>
                  </div>
                )}
                <div className={styles.cardOffer__narrowСontent}>
                  <div className={styles.cardOffer__infoList}>
                    <OfferDescription
                      description={offer.description}
                      className={styles.cardOffer__description}
                    ></OfferDescription>
                  </div>
                </div>

                <label className={styles.checkboxLikeButton}>
                  <input
                    type="checkbox"
                    className={styles.checkboxLikeButton__input}
                    checked={false}
                    onChange={() => {
                      finalCheckoutService.deSelectOffer(
                        finalCheckoutService.chosenLetter
                      );
                      // select current product
                      if (finalCheckoutService.isOfferSelected(offer.key)) {
                        finalCheckoutService.deSelectOffer(offer.key);
                      }
                      finalCheckoutService.selectOffer(offer.key);
                      finalCheckoutService.setChosenLetter(offer.key);

                      handleClick();
                    }}
                  />

                  <span className={styles.checkboxLikeButton__block}>
                    <span
                      className={styles.checkboxLikeButton__notSelectedYetBlock}
                    >
                      Select
                    </span>
                    <span className={styles.checkboxLikeButton__selectedBlock}>
                      <span
                        onClick={handleClick}
                        className={cn(
                          styles.checkboxLikeButton__selectedBlockText,
                          "btn-with-checkmark"
                        )}
                      >
                        Selected
                      </span>
                      <span
                        className={
                          styles.checkboxLikeButton__selectedBlockUncheck
                        }
                      >
                        <span className="icomoon-remove"></span>
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          ))}
        </div>

        <Button
          className={styles.sectionOffers__bottomButton}
          color="green-light"
          onClick={handlePrevStep}
        >
          Back
        </Button>
      </div>
    </section>
  );
};
