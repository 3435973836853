import classNames from "classnames";
import { TherapistCard } from "components/TherapistCard";
import { Button } from "components/UIKit/Button";
import { finalCheckoutService } from "services/finalCheckoutService";
import { visitorIdService } from "services/visitoridService";
import { therapassApiEndpoint } from "utils/env";
import { useSnapshot } from "valtio";
import styles from "../../FinalCheckout.module.scss";

import localStyles from "./Confirmation.module.scss";

export const confirmationPath = "/confirmation";

export const Confirmation = () => {
  const {
    therapist,
    paymentStatus,
    CartID,
    visitorID,
    orderId,
    visitorEmail,
    ClientID: clientid,
  } = useSnapshot(finalCheckoutService);
  const accountURL = `https://therapass.com/redirectesa/?cartid=${CartID}&visitorid=${visitorID}&clientid=${clientid}&email=${visitorEmail}&orderid=${orderId}`;

  if (paymentStatus !== "error") {
    // This will trigger the conversion
    window.gtag("event", "conversion", {
      send_to: "AW-11025675463/xGv7CKuqvocZEMfpuYkp",
    });
    visitorIdService.trackEvent("Confirmation", "", "", "", "");
  }
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Success!</h1>
      <p className={classNames(styles.text, localStyles.text)}>
        Please have your phone nearby. Your ESA therapist is going to text you
        shortly.
        <br />
        In the mean time you can access your account by clicking the button
        below.
        <iframe
          src={accountURL}
          style={{ width: "1PX", height: "1px" }}
        ></iframe>
      </p>
      <div className="m-40">
        <Button>
          <a href={accountURL} target="_blank">
            Go to account
          </a>
        </Button>
      </div>
      {therapist && <TherapistCard therapist={therapist} />}
    </div>
  );
};
