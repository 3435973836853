export class SubmitPetInfoResult {
  success = false;

  Message = "";

  Redirect = false;

  VisitorID = "";

  ErrorMessage = "";

  Destination = "";

  TherapistKey = "";

  Returning = false;

  ProductKey = "";

  CartID = "";
}
