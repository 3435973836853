/* eslint-disable @typescript-eslint/naming-convention */
import cn from "classnames";
import { Availability } from "components/Availability";
import { Avatar } from "components/Avatar";
import { FormatProfileField } from "components/FormatProfileField";
import { StateLicenseListItem } from "components/StateLicenseListItem";
import { Button } from "components/UIKit/Button";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import {
  useResponsive,
  useMobileResponsive,
  useTabletResponsive,
} from "hooks/useResponsive";
import { connectPath } from "pages/Connect";
// import { interstitialPath } from "pages/Interstitial";
import { therapistPath } from "pages/Therapist";
import { FC, useEffect, useState } from "react";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { connectService } from "services/connectService";
import { TherapistListItem } from "types/therapist.types";
import { getAvatar } from "utils/getAvatar";
import { therapistName } from "utils/therapistName";
import "./TherapistListingItem.scss";

interface Props {
  className?: string;
  therapist: TherapistListItem;
}

export const TherapistListingItem: FC<Props> = ({ className, therapist }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [nextPagePath, setNextPagePath] = useState(connectPath);
  const [interstitialMode, setInterstitialMode] = useState("");
  const {
    first_name,
    last_name,
    photos,
    in_person_session_availability,
    online_chat_sessions_availability,
    telephone_session_availability,
    personal_statement_short_description,
    video_session_availability,
    id,
    chat_status,
    professional_specialties,
    subscription_plan,
    source,
    verified_state_licenses,
  } = therapist;
  const withAvailableOptions =
    in_person_session_availability ||
    online_chat_sessions_availability ||
    telephone_session_availability ||
    video_session_availability;
  const hasAccessToChat = therapist.subscription_plan.chat_access;
  const hasChatAvailable = therapist.chat_status === "available";
  const isESATherapist = therapist.ESAStatus === 1;
  const ln = last_name + (isESATherapist ? ", ESA Specialist" : "");
  const withStatus =
    therapist &&
    hasAccessToChat &&
    hasChatAvailable &&
    therapist?.online_chat_sessions_availability;

  const professionalSpecialtyName = useDictionaryItemNames(
    "ProfessionalSpecialties",
    professional_specialties
  );

  const isMobileSize = useResponsive("sm");

  const { isMobile } = useMobileResponsive();
  const { isTablet } = useTabletResponsive();
  const getNextPagePath = async () => {
    // const mode = await connectService.getInterstitialMode();

    // const pathName =
    //   mode === "afterresultsbeforeconnect" ? interstitialPath : connectPath;
    // setInterstitialMode(mode);
    const pathName = connectPath;
    setNextPagePath(pathName);
  };

  useEffect(() => {
    getNextPagePath();
  }, []);

  const onMessage = () => {
    let tid = therapist.id;
    if (tid == null) {
      tid = "";
    }
    const finalPathName = `${connectPath}?therapistId=${tid}`;
    // let finalPathName = connectPath;
    // if (interstitialMode === "aftersearchbeforeresults") {
    //   finalPathName = `${connectPath}?therapistId=${tid}`;
    // } else if (interstitialMode === "afterresultsbeforeconnect") {
    //   finalPathName = `${interstitialPath}?therapistId=${tid}`;
    // }
    navigate(finalPathName);
  };

  return (
    <>
      {(isMobile && (
        <>
          <div className={cn("therapist-card", className)}>
            <div className="row">
              <div className="col-md-auto">
                <Avatar
                  file={getAvatar(photos)}
                  alt={`${first_name} ${last_name}`}
                  chat_status={withStatus ? chat_status : undefined}
                  onClick={withStatus ? onMessage : undefined}
                  id={id}
                  planName={subscription_plan.name}
                  isEsaTherapist={isESATherapist}
                />
              </div>
              <div className="col-md-auto">
                <div className="therapist-card__title row">
                  <h3 className="therapist-card__name row">
                    <Link
                      to={therapistPath.replace(":id", String(id))}
                      state={{ prevPage: pathname }}
                      className="d-inline"
                    >
                      <FormatProfileField maxLength={50} className="d-inline">
                        {therapistName({ first_name, last_name: ln }).trim()}
                      </FormatProfileField>
                    </Link>
                  </h3>
                  {therapist.company_name &&
                    therapist.use_company_name_also && (
                      <div className="text-lg mt-10">
                        {therapist.company_name}
                      </div>
                    )}
                </div>
                {isESATherapist && (
                  <div
                    className="therapist-card__info-content"
                    style={{ paddingLeft: "0" }}
                  >
                    {source.includes("imported") &&
                    professionalSpecialtyName.length > 0 ? (
                      <div className="text-block text-lg text-gray-700 mb-15">
                        {professionalSpecialtyName
                          .filter((item) => item)
                          .join(", ")}
                      </div>
                    ) : null}
                    {verified_state_licenses.slice(0, 3).map((license) => (
                      <StateLicenseListItem
                        key={`${license.state}-${license.number}`}
                        license={license}
                      />
                    ))}

                    {isESATherapist && (
                      <Availability
                        isAvailable={withAvailableOptions}
                        isESATherapist={isESATherapist}
                      />
                    )}
                  </div>
                )}
                {withAvailableOptions && (
                  <>
                    <div className={"therapist-options"}>
                      <p className="text-lg pe-15 my-5">Therapy Options:</p>
                    </div>
                    <div>
                      {in_person_session_availability && (
                        <button
                          className="therapist-options__item-btn person-icon"
                          aria-label="In-Person"
                        />
                      )}
                      {telephone_session_availability && (
                        <button
                          className="therapist-options__item-btn phone-icon"
                          aria-label="Telephone"
                        />
                      )}
                      {video_session_availability && (
                        <button
                          className="therapist-options__item-btn video-icon"
                          aria-label="Video"
                        />
                      )}
                      {online_chat_sessions_availability && (
                        <button
                          className="therapist-options__item-btn chat-icon"
                          aria-label="ChatConnect"
                        />
                      )}
                    </div>
                  </>
                )}

                <div className="text-block text-lg text-gray-700">
                  <p>
                    <FormatProfileField
                      maxLength={200}
                      isExpandable
                      fallbackText="No description added yet"
                    >
                      {personal_statement_short_description}
                    </FormatProfileField>
                  </p>
                </div>
                {hasChatAvailable && isMobileSize && (
                  <>
                    <div className="col-xs-6 col-layout-flip-6 d-flex mt-20">
                      <Button className="mb-15 w-100" onClick={onMessage}>
                        Get ESA Letter Now
                      </Button>
                    </div>
                    <div className="col-xs-6 col-layout-flip-6 d-flex">
                      <Button
                        className="mb-15 w-100"
                        color="green-light"
                        isLink
                        to={therapistPath.replace(":id", String(id))}
                        state={{ prevPage: pathname }}
                      >
                        View Therapist Profile
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )) ||
        (isTablet && (
          <>
            {isESATherapist ? (
              <div
                className="therapist-card"
                style={{ marginTop: "2rem", paddingBottom: "9rem" }}
              >
                <div className="row">
                  <div
                    className={cn("col-md-auto", "therapist-card__photo-col")}
                  >
                    <Avatar
                      file={getAvatar(photos)}
                      alt={`${first_name} ${last_name}`}
                      chat_status={withStatus ? chat_status : undefined}
                      onClick={withStatus ? onMessage : undefined}
                      id={id}
                      planName={subscription_plan.name}
                      isEsaTherapist={isESATherapist}
                    />
                    <h3
                      className="therapist-card__name row"
                      style={{ width: "40%" }}
                    >
                      <Link
                        to={therapistPath.replace(":id", String(id))}
                        state={{ prevPage: pathname }}
                        className="d-inline"
                      >
                        <FormatProfileField maxLength={50} className="d-inline">
                          {therapistName({ first_name, last_name: ln }).trim()}
                        </FormatProfileField>
                      </Link>
                    </h3>
                    {therapist.company_name &&
                      therapist.use_company_name_also && (
                        <div className="text-lg mt-10">
                          {therapist.company_name}
                        </div>
                      )}

                    {isESATherapist && (
                      <div
                        className="therapist-card__info-content"
                        style={{ paddingLeft: "0", width: "40%" }}
                      >
                        {source.includes("imported") &&
                        professionalSpecialtyName.length > 0 ? (
                          <div className="text-block text-lg text-gray-700 mb-15">
                            {professionalSpecialtyName
                              .filter((item) => item)
                              .join(", ")}
                          </div>
                        ) : null}
                        {verified_state_licenses.slice(0, 3).map((license) => (
                          <StateLicenseListItem
                            key={`${license.state}-${license.number}`}
                            license={license}
                          />
                        ))}

                        {isESATherapist && (
                          <Availability
                            isAvailable={withAvailableOptions}
                            isESATherapist={isESATherapist}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    className="col row"
                    style={{ paddingRight: "0", paddingLeft: "42%" }}
                  >
                    <div
                      className="therapist-card__title row"
                      style={{ paddingLeft: "0" }}
                    ></div>

                    {withAvailableOptions && (
                      <>
                        <div className={"therapist-options"}>
                          <p className="text-lg pe-15 my-5">Therapy Options:</p>
                        </div>
                        <div style={{ paddingLeft: "0" }}>
                          {in_person_session_availability && (
                            <button
                              className="therapist-options__item-btn person-icon"
                              aria-label="In-Person"
                            />
                          )}
                          {telephone_session_availability && (
                            <button
                              className="therapist-options__item-btn phone-icon"
                              aria-label="Telephone"
                            />
                          )}
                          {video_session_availability && (
                            <button
                              className="therapist-options__item-btn video-icon"
                              aria-label="Video"
                            />
                          )}
                          {online_chat_sessions_availability && (
                            <button
                              className="therapist-options__item-btn chat-icon"
                              aria-label="ChatConnect"
                            />
                          )}
                        </div>
                      </>
                    )}

                    <div
                      className="text-block text-lg text-gray-700"
                      style={{ paddingLeft: "0" }}
                    >
                      <p>
                        <FormatProfileField
                          maxLength={200}
                          isExpandable
                          fallbackText="No description added yet"
                        >
                          {personal_statement_short_description}
                        </FormatProfileField>
                      </p>
                    </div>
                    {hasChatAvailable && isTablet && (
                      <>
                        <div
                          className="col-xs-6 col-layout-flip-6 d-flex mt-20"
                          style={{ paddingLeft: "0", width: "60%" }}
                        >
                          <Button className="mb-15 w-100" onClick={onMessage}>
                            Get ESA Letter Now
                          </Button>
                        </div>
                        <div
                          className="col-xs-6 col-layout-flip-6 d-flex"
                          style={{ paddingLeft: "0", width: "60%" }}
                        >
                          <Button
                            className="mb-15 w-100"
                            color="green-light"
                            isLink
                            to={therapistPath.replace(":id", String(id))}
                            state={{ prevPage: pathname }}
                          >
                            View Therapist Profile
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              /* non esa below */
              <div
                className={cn("therapist-card", className)}
                style={{ marginTop: "2rem", paddingBottom: "3rem" }}
              >
                <div className="row" style={{ paddingBottom: "3rem" }}>
                  <div
                    className={cn("col-md-auto", "therapist-card__photo-col")}
                    style={{ width: "100%" }}
                  >
                    <Avatar
                      file={getAvatar(photos)}
                      alt={`${first_name} ${last_name}`}
                      chat_status={withStatus ? chat_status : undefined}
                      onClick={withStatus ? onMessage : undefined}
                      id={id}
                      planName={subscription_plan.name}
                      isEsaTherapist={isESATherapist}
                    />
                    <h3 className="therapist-card__name row">
                      <Link
                        to={therapistPath.replace(":id", String(id))}
                        state={{ prevPage: pathname }}
                        className="d-inline"
                      >
                        <FormatProfileField maxLength={50} className="d-inline">
                          {therapistName({ first_name, last_name: ln }).trim()}
                        </FormatProfileField>
                      </Link>
                    </h3>
                    {therapist.company_name &&
                      therapist.use_company_name_also && (
                        <div className="text-lg mt-10">
                          {therapist.company_name}
                        </div>
                      )}
                  </div>
                  <div
                    className="col"
                    style={{ paddingRight: "0", paddingLeft: "39%" }}
                  >
                    <div>
                      {withAvailableOptions && (
                        <>
                          <div className={"therapist-options"}>
                            <p
                              className="text-lg pe-15 my-5"
                              style={{ fontSize: "18px" }}
                            >
                              Therapy Options:
                            </p>
                          </div>
                          <div
                            style={{ paddingLeft: "0", paddingBottom: "1rem" }}
                          >
                            {in_person_session_availability && (
                              <button
                                className="therapist-options__item-btn person-icon"
                                aria-label="In-Person"
                              />
                            )}
                            {telephone_session_availability && (
                              <button
                                className="therapist-options__item-btn phone-icon"
                                aria-label="Telephone"
                              />
                            )}
                            {video_session_availability && (
                              <button
                                className="therapist-options__item-btn video-icon"
                                aria-label="Video"
                              />
                            )}
                            {online_chat_sessions_availability && (
                              <button
                                className="therapist-options__item-btn chat-icon"
                                aria-label="ChatConnect"
                              />
                            )}
                          </div>
                        </>
                      )}
                      <div
                        className="text-block text-lg text-gray-700"
                        style={{ paddingLeft: "0", paddingBottom: "1rem" }}
                      >
                        <p>
                          <FormatProfileField
                            maxLength={200}
                            isExpandable
                            fallbackText="No description added yet"
                          >
                            {personal_statement_short_description}
                          </FormatProfileField>
                        </p>
                      </div>
                      <div className="col-xs-6 col-layout-flip-6 d-flex mt-20">
                        <Button
                          className="mb-15 w-100"
                          color="green-light"
                          isLink
                          to={therapistPath.replace(":id", String(id))}
                          state={{ prevPage: pathname }}
                        >
                          View Therapist Profile
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )) || (
          <div className={cn("therapist-card", className)}>
            {isESATherapist ? (
              <div className="row">
                <div className={cn("col-md-auto", "therapist-card__photo-col")}>
                  <Avatar
                    file={getAvatar(photos)}
                    alt={`${first_name} ${last_name}`}
                    chat_status={withStatus ? chat_status : undefined}
                    onClick={withStatus ? onMessage : undefined}
                    id={id}
                    planName={subscription_plan.name}
                    isEsaTherapist={isESATherapist}
                  />
                </div>

                <div
                  className="col row"
                  style={{ paddingRight: "12%", paddingLeft: "25%" }}
                >
                  <div
                    className="therapist-card__title row"
                    style={{ paddingLeft: "0" }}
                  >
                    <h3 className="therapist-card__name row">
                      <Link
                        to={therapistPath.replace(":id", String(id))}
                        state={{ prevPage: pathname }}
                        className="d-inline"
                      >
                        <FormatProfileField maxLength={50} className="d-inline">
                          {therapistName({ first_name, last_name: ln }).trim()}
                        </FormatProfileField>
                      </Link>
                    </h3>
                    {therapist.company_name &&
                      therapist.use_company_name_also && (
                        <div className="text-lg mt-10">
                          {therapist.company_name}
                        </div>
                      )}
                  </div>
                  {isESATherapist && (
                    <div className="therapist-card__info-content">
                      {source.includes("imported") &&
                      professionalSpecialtyName.length > 0 ? (
                        <div className="text-block text-lg text-gray-700 mb-15">
                          {professionalSpecialtyName
                            .filter((item) => item)
                            .join(", ")}
                        </div>
                      ) : null}
                      {verified_state_licenses.slice(0, 3).map((license) => (
                        <StateLicenseListItem
                          key={`${license.state}-${license.number}`}
                          license={license}
                        />
                      ))}

                      {isESATherapist && (
                        <Availability
                          isAvailable={withAvailableOptions}
                          isESATherapist={isESATherapist}
                        />
                      )}
                    </div>
                  )}
                  {withAvailableOptions && (
                    <>
                      <div className={"therapist-options"}>
                        <p className="text-lg pe-15 my-5">Therapy Options:</p>
                      </div>
                      <div style={{ paddingLeft: "0" }}>
                        {in_person_session_availability && (
                          <button
                            className="therapist-options__item-btn person-icon"
                            aria-label="In-Person"
                          />
                        )}
                        {telephone_session_availability && (
                          <button
                            className="therapist-options__item-btn phone-icon"
                            aria-label="Telephone"
                          />
                        )}
                        {video_session_availability && (
                          <button
                            className="therapist-options__item-btn video-icon"
                            aria-label="Video"
                          />
                        )}
                        {online_chat_sessions_availability && (
                          <button
                            className="therapist-options__item-btn chat-icon"
                            aria-label="SMS"
                          />
                        )}
                      </div>
                    </>
                  )}

                  <div
                    className="text-block text-lg text-gray-700"
                    style={{ paddingLeft: "0" }}
                  >
                    <p>
                      <FormatProfileField
                        maxLength={600}
                        isExpandable
                        fallbackText="No description added yet"
                      >
                        {personal_statement_short_description}
                      </FormatProfileField>
                    </p>
                  </div>
                  {hasChatAvailable && !isMobileSize && (
                    <>
                      <div
                        className="col-xs-6 col-layout-flip-6 d-flex mt-20"
                        style={{ paddingLeft: "0" }}
                      >
                        <Button className="mb-15 w-100" onClick={onMessage}>
                          Get ESA Letter Now
                        </Button>
                      </div>
                      <div className="col-xs-6 col-layout-flip-6 d-flex mt-20">
                        <Button
                          className="mb-15 w-100"
                          color="green-light"
                          isLink
                          to={therapistPath.replace(":id", String(id))}
                          state={{ prevPage: pathname }}
                        >
                          View Therapist Profile
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              /* non esa below */
              <div className="row" style={{ marginTop: "3rem" }}>
                <div className={cn("col-md-auto", "therapist-card__photo-col")}>
                  <Avatar
                    file={getAvatar(photos)}
                    alt={`${first_name} ${last_name}`}
                    chat_status={withStatus ? chat_status : undefined}
                    onClick={withStatus ? onMessage : undefined}
                    id={id}
                    planName={subscription_plan.name}
                    isEsaTherapist={isESATherapist}
                  />
                </div>
                <div
                  className="col"
                  style={{ paddingRight: "12%", paddingLeft: "24%" }}
                >
                  <div>
                    <div
                      className="therapist-card__title row"
                      style={{ paddingLeft: "0", paddingBottom: "1rem" }}
                    >
                      <h3 className="therapist-card__name row">
                        <Link
                          to={therapistPath.replace(":id", String(id))}
                          state={{ prevPage: pathname }}
                          className="d-inline"
                        >
                          <FormatProfileField
                            maxLength={50}
                            className="d-inline"
                          >
                            {therapistName({
                              first_name,
                              last_name: ln,
                            }).trim()}
                          </FormatProfileField>
                        </Link>
                      </h3>
                      {therapist.company_name &&
                        therapist.use_company_name_also && (
                          <div className="text-lg mt-10">
                            {therapist.company_name}
                          </div>
                        )}
                    </div>
                    {withAvailableOptions && (
                      <>
                        <div className={"therapist-options"}>
                          <p className="text-lg pe-15 my-5">Therapy Options:</p>
                        </div>
                        <div
                          style={{ paddingLeft: "0", paddingBottom: "1rem" }}
                        >
                          {in_person_session_availability && (
                            <button
                              className="therapist-options__item-btn person-icon"
                              aria-label="In-Person"
                            />
                          )}
                          {telephone_session_availability && (
                            <button
                              className="therapist-options__item-btn phone-icon"
                              aria-label="Telephone"
                            />
                          )}
                          {video_session_availability && (
                            <button
                              className="therapist-options__item-btn video-icon"
                              aria-label="Video"
                            />
                          )}
                          {online_chat_sessions_availability && (
                            <button
                              className="therapist-options__item-btn chat-icon"
                              aria-label="ChatConnect"
                            />
                          )}
                        </div>
                      </>
                    )}
                    <div
                      className="text-block text-lg text-gray-700"
                      style={{ paddingLeft: "0", paddingBottom: "1rem" }}
                    >
                      <p>
                        <FormatProfileField
                          maxLength={600}
                          isExpandable
                          fallbackText="No description added yet"
                        >
                          {personal_statement_short_description}
                        </FormatProfileField>
                      </p>
                    </div>
                    <div className="col-xs-6 col-layout-flip-6 d-flex mt-20">
                      <Button
                        className="mb-15 w-100"
                        color="green-light"
                        isLink
                        to={therapistPath.replace(":id", String(id))}
                        state={{ prevPage: pathname }}
                      >
                        View Therapist Profile
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
    </>
  );
};
