import { ReactComponent as CatAndDogIcon } from "assets/icons/cat-and-dog.svg";
import { ReactComponent as CatIcon } from "assets/icons/cat.svg";
import { ReactComponent as DogIcon } from "assets/icons/dog.svg";
import { ReactComponent as FemaleIcon } from "assets/icons/female.svg";
import { ReactComponent as MaleIcon } from "assets/icons/male.svg";
import { ReactComponent as NonBinaryIcon } from "assets/icons/non-binary.svg";
import { ReactComponent as thumbsdownIcon } from "assets/icons/thumbsdown.svg";
import { ReactComponent as thumbsUpIcon } from "assets/icons/thumbsup.svg";

import {
  Animal,
  Binary,
  Count,
  Frequency,
  Gender,
  QuestionOption,
  QuestionType,
  QuestionValue,
} from "types/funnel.types";

type FunnelQuestion = {
  name: string;
  label: string;
  type: QuestionType;
  options: QuestionOption<QuestionValue>[];
};

export const ANIMAL_OPTIONS: QuestionOption<Animal>[] = [
  { value: "dog", label: "Dog", icon: DogIcon, iconSize: 1 },
  { value: "cat", label: "Cat", icon: CatIcon, iconSize: 1 },
  { value: "both", label: "Both", icon: CatAndDogIcon, iconSize: 2 },
];

export const COUNT_OPTIONS: QuestionOption<Count>[] = [
  { value: "1", label: "One (1)", icon: DogIcon, iconSize: 1 },
  { value: "+2", label: "Two (+2)", icon: CatAndDogIcon, iconSize: 2 },
];

export const BINARY_OPTIONS: QuestionOption<Binary>[] = [
  { value: "yes", label: "Yes", icon: thumbsUpIcon, iconSize: 1 },
  { value: "no", label: "No", icon: thumbsdownIcon, iconSize: 1 },
];

export const FREQUENCY_OPTIONS: QuestionOption<Frequency>[] = [
  { value: "always", label: "Always", iconSize: 1 },
  { value: "often", label: "Often", iconSize: 1 },
  { value: "sometimes", label: "Sometimes", iconSize: 1 },
  { value: "rarely", label: "Rarely", iconSize: 1 },
  { value: "never", label: "Never", iconSize: 1 },
];

export const GENDER_OPTIONS: QuestionOption<Gender>[] = [
  { value: "male", label: "Male", icon: MaleIcon, iconSize: 1 },
  { value: "female", label: "Female", icon: FemaleIcon, iconSize: 1 },
  { value: "nonbinary", label: "Other", icon: NonBinaryIcon, iconSize: 1 },
];

export const FUNNEL_QUESTIONS = [
  {
    name: "animal",
    label: "Is your ESA letter for a dog, cat or both?",
    type: "horizontalWithLabel",
    options: ANIMAL_OPTIONS,
  },
  {
    name: "how_many",
    label: "How many animals is your ESA letter for?",
    type: "horizontalWithLabel",
    options: COUNT_OPTIONS,
  },
  {
    name: "received_esa_before",
    label: "Have you received an ESA letter before?",
    type: "horizontalWithoutLabel",
    options: BINARY_OPTIONS,
  },
  {
    name: "mentally_diagnosed_before",
    label:
      "Have you ever been formally diagnosed with a mental health related condition by a medical professional? (Eg. anxiety, depression)",
    type: "horizontalWithoutLabel",
    options: BINARY_OPTIONS,
  },
  {
    name: "little_interest",
    label:
      "Over the past two (2) weeks, how often have you had little interest or pleasure in doing things?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "felt_depressed",
    label:
      "Over the past two (2) weeks, how often have you felt down, depressed, or hopeless?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "felt_angrier_than_usual",
    label:
      "Over the past two (2) weeks, how often have you felt more angry, grouchy, or irritated than usual?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "slept_less_than_usual",
    label:
      "Over the past two (2) weeks, have you been sleeping less than usual, but still have a lot of energy?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "started_more_projects_than_usual",
    label:
      "Over the past two (2) weeks, have you been starting more projects than usual or doing more risky things than usual?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "felt_afraid",
    label:
      "Over the past two (2) weeks, how often have you felt afraid or panicked?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "felt_worried",
    label:
      "Over the past two (2) weeks, how often have you felt worried, anxious, or on edge?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "avoided_anxiety_situations",
    label:
      "Over the past two (2) weeks, how often have you avoided situations that make you anxious?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "experienced_aches_and_pains",
    label:
      "Over the past two (2) weeks, have you experienced unexplained aches and pains (back, joints, abdomen, legs)?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "felt_illness_not_taken_seriously",
    label:
      "Over the past two (2) weeks, have you been feeling that your illnesses are not being taken seriously enough?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "had_problems_with_sleep",
    label:
      "Over the past two (2) weeks, have you had problems with sleep that affected your sleep quality overall?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "had_unpleasant_thoughts",
    label:
      "Over the past two (2) weeks, have you unpleasant thoughts, urges, or images that repeatedly enter your mind?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "felt_driven_to_perform_behaviours",
    label:
      "Over the past two (2) weeks, have you felt driven to perform certain behaviors or mental acts over and over again?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "drank_alcohol",
    label:
      "Over the past two (2) weeks have you been drinking at least 4 drinks of any kind of alcohol in a single day?",
    type: "stack",
    options: FREQUENCY_OPTIONS,
  },
  {
    name: "gender",
    label: "Your Gender:",
    type: "horizontalWithLabel",
    options: GENDER_OPTIONS,
  },
] as const satisfies readonly FunnelQuestion[];
