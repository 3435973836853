import cn from "classnames";
import { InputSmartSearch } from "components/UIKit/InputSmartSearch";
import { GlobalContext } from "GlobalProvider";
import { useGA } from "hooks/useGA";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Address } from "services/CheckoutSubmit";
import { connectService } from "services/connectService";
import { listingService } from "services/listingService";
import { systemSettingsService } from "services/systemSettingsService";
import { visitorIdService } from "services/visitoridService";

import styles from "./ProgressForRegistrationFunnel.module.scss";

export const progressForRegistrationFunnelPath =
  "/progress-for-registration-funnel";

export const ProgressForRegistrationFunnel = () => {
  useGA().ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const { setGlobalState } = useContext(GlobalContext);
  const [searchUrl, setSearchUrl] = useState("/listing");
  const [autoAssignMode, setAutoAssignMode] = useState(false);
  const [autoAssignTherpist, setAutoAssignTherapist] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  // const { zip } = useSnapshot(listingService);
  let VisitorID = "";

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Address>({});

  const navigate = useNavigate();

  const queryNavigate = useNavigateWithQuery();
  visitorIdService.getVisitorID(false).then((vid) => {
    VisitorID = vid;
  });

  visitorIdService.trackEvent(
    "Progress For registration Funnel",
    "/progress-for-registration-funnel",
    "",
    "",
    ""
  );

  useEffect(() => {
    visitorIdService.trackEvent(
      "Progress For registration Funnel",
      "Visit",
      progressForRegistrationFunnelPath,
      "",
      ""
    );
  }, []);

  const handleSelectPlace = (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
  }) => {
    listingService.setPlaceFilter({
      ...(args?.city && { city: args.city }),
      ...(args?.search && { search: args.search }),
      ...(args?.state && { state: args.state }),
      ...(args?.zip && { zip: args.zip }),
      withoutRequest: true,
    });
  };

  useEffect(() => {
    if (!autoAssignMode) {
      systemSettingsService.get().then((res: any) => {
        if (res.auto_assign_therapist) {
          setAutoAssignTherapist(true);
        }
      });
    }
    setAutoAssignMode(true);
  }, [autoAssignMode, setAutoAssignMode]);

  const checkRedirect = async () => {
    const value: any = await systemSettingsService.get();
    if (value.auto_assign_therapist) {
      let nextPath = "";
      const query = listingService.getQuery();

      connectService.getTherapist(query, true).then((therapist: any) => {
        if (therapist["ESAStatus"] >= 1) {
          nextPath = `/connect`;
          const therapistKey = therapist["id"].toString();
          queryParams.set("therapistId", therapistKey);
          const therapistQuery = `?therapistId=${therapistKey}`;
          visitorIdService.trackEvent(
            "Progress For registration Funnel",
            "Exit",
            nextPath,
            therapistQuery,
            ""
          );
          queryNavigate(nextPath, therapistQuery);
        } else {
          navigate(searchUrl, {
            state: { withScroll: false },
          });
        }
      });
    } else {
      navigate(searchUrl, {
        state: { withScroll: false },
      });
    }
  };

  useEffect(() => {
    handleSelectPlace({ search: "" });
  }, []);

  return (
    <section className={cn("default-typography", styles.sectionProgress)}>
      <div className="container">
        <div className={styles.sectionProgress__content}>
          <h3 className="fw-bold mb-25 mb-sm-40">5 Minute Pre-Approval</h3>

          <div className={styles.sectionProgress__progressBlock}>
            <div
              className={styles.sectionProgress__progressStrip}
              style={{ width: "10%" }}
            ></div>
          </div>

          <div
            className={cn(
              styles.sectionProgress__description,
              "mb-25 mb-sm-40"
            )}
          >
            <div className="text-block">
              <p>
                In order for us to best facilitate your ESA Letter we need to
                start with where you live. This information will not be shared
                with any third parties.
              </p>
            </div>
          </div>

          <div className={styles.sectionProgress__inputAndButtonBlock}>
            {/* <Input size="sm" placeholder="Zipcode" className="mb-30 mb-sm-45" {...register("unit")}/> */}
            {/* <Input
              size="sm"
              className="mb-30 mb-sm-45"
              placeholder="Zip"
              {...register("zip", {
                required: REQUIRED_MESSAGE,
                validate: (value) =>
                  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) ||
                  "Should be valid zip code",
              })}
              error={errors.zip?.message}
            />
            <Button
              className="w-100"
              onClick={() => {
                setGlobalState({ prevPage: window.location.pathname });
                checkRedirect();
              }}
            >
              NEXT
            </Button> */}
            <InputSmartSearch
              placeholder="Enter your City / Zip"
              onSelectPlace={handleSelectPlace}
              maxLength={200}
              max={200}
              withButton
              buttonClass="w-100"
              className="text-start sm mb-30 mb-sm-45"
              buttonLabel="NEXT"
              onButtonClick={() => {
                setGlobalState({ prevPage: window.location.pathname });
                checkRedirect();
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
