import logo from "assets/images/logo.svg";
import cn from "classnames";
import { Link } from "react-router-dom";
import "./Logo.scss";

interface Props {
  className?: string;
}

export const Logo = ({ className }: Props) => {
  return (
    <Link to="/" className={cn("logo", className)}>
      <img src={logo} alt="logo" />
    </Link>
  );
};
