import cn from "classnames";
import { useState } from "react";
import { FCWithChildren } from "types/react.types";
import { cropString } from "utils/cropString";
import { isEmpty } from "utils/isEmpty";
import styles from "./FormatProfileField.module.scss";

interface Props {
  className?: string;
  fallbackText?: string;
  shouldFallback?: boolean;
  maxLength?: number;
  isExpandable?: boolean;
  shouldLimitLength?: boolean;
  wrapBreaksWord?: boolean;
  objectToCheck?: any;
}

export const FormatProfileField: FCWithChildren<Props> = ({
  children,
  className,
  fallbackText = "-",
  shouldFallback = true,
  maxLength = 20,
  isExpandable = false,
  shouldLimitLength = false,
  wrapBreaksWord = false,
  objectToCheck = children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (shouldFallback && isEmpty(objectToCheck)) {
    return (
      <span className={cn(className, styles.text, styles.fallback)}>
        {fallbackText}
      </span>
    );
  }

  if (typeof objectToCheck === "string") {
    const containsLongWords = /\w{20,}/.test(objectToCheck);
    const willCropString = objectToCheck.length > maxLength;

    return (
      <span
        className={cn(
          className,
          styles.text,
          objectToCheck.length > maxLength && styles.cropped,
          (wrapBreaksWord || containsLongWords) && styles.breakWord
        )}
        title={
          shouldLimitLength && objectToCheck.length > maxLength
            ? objectToCheck
            : undefined
        }
      >
        {shouldLimitLength && !isExpanded
          ? cropString(objectToCheck, {
              maxLength,
              endingIfCropped: isExpandable ? "... " : "",
            })
          : objectToCheck}{" "}
      </span>
    );
  }

  return <>{children}</>;
};
