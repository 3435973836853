import { breakpoints } from "constants/breakpoints";
import { useEffect, useState } from "react";
import { useWindowResize } from "./useWindowResize";
/*
export const useResponsive = (breakpoint: keyof typeof breakpoints) => {
	const [isMatch, setIsMatch] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			const lastBreakpoint = Object.keys(breakpoints).pop();
			setIsMatch(
				breakpoint === lastBreakpoint
					? window.innerWidth >= breakpoints[breakpoint]
					: window.innerWidth <= breakpoints[breakpoint]
			);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [breakpoint]);

	return isMatch;
};
*/
export const useResponsive = (breakpoint: keyof typeof breakpoints) => {
  const { width } = useWindowResize();

  return width <= 900;
};

export const useMobileResponsive = () => {
  const { width } = useWindowResize();

  return {
    isMobile: width <= 700,
  };
};

export const useTabletResponsive = () => {
  const { width } = useWindowResize();

  return {
    isTablet: width <= 900 && width > 700,
  };
};
