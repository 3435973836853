import playIconLarge from "assets/icons/play-large.svg";
import classNames from "classnames";
import { ReactNode, useRef, useState } from "react";
import { blockEvent } from "utils/blockEvent";
import useVideoEffect from "../videoCommon";
import { Controls } from "./Controls";
import { useCurrentTime, usePaused, useTotalDuration } from "./hooks";
import { calcSeekedTime } from "./util";
import styles from "./VideoPlayer.module.scss";

type Props = {
  src: string;
  adornment?: ReactNode;
  isFullscreen?: boolean;
  className?: string;
  showControls?: boolean;
  width?: number;
  height?: number;
};

export const Player = ({
  src,
  adornment,
  isFullscreen,
  className,
  width,
  height,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isControlsHidden, setIsControlsHidden] = useState(true);
  const delayedControlsDisappearRef = useRef<(() => void) | null>(null);

  const totalDuration = useTotalDuration(videoRef);
  const currentTimeData = useCurrentTime(videoRef);
  const pausedData = usePaused(videoRef);
  const vidWidth = width;
  const vidHeight = height;

  useVideoEffect(
    videoRef,
    () => {
      const keyboardControlsListener = (e: KeyboardEvent) => {
        if ((e.target as HTMLElement | null)?.tagName !== "VIDEO") {
          return;
        }

        // eslint-disable-next-line default-case
        switch (e.code) {
          case "Space":
            pausedData.set((curr) => !curr);
            break;
          case "ArrowRight":
            currentTimeData.set((curr) =>
              calcSeekedTime(totalDuration, curr, true)
            );
            break;
          case "ArrowLeft":
            currentTimeData.set((curr) =>
              calcSeekedTime(totalDuration, curr, false)
            );
        }
      };

      document.addEventListener("keyup", keyboardControlsListener);

      return () =>
        document.removeEventListener("keyup", keyboardControlsListener);
    },
    [totalDuration]
  );

  const onMouseMove = () => {
    setIsControlsHidden(false);
    const hideControls = () => {
      setIsControlsHidden(true);
    };

    delayedControlsDisappearRef.current = hideControls;
    setTimeout(() => {
      if (delayedControlsDisappearRef.current === hideControls) {
        hideControls();
      }
    }, 1500);
  };

  const onMouseLeave = () => {
    setIsControlsHidden(true);
  };

  const onVideoClick = () => {
    pausedData.set((curr) => !curr);
    setIsControlsHidden(false);
  };

  return (
    <div
      ref={containerRef}
      className={classNames(
        styles.playerContainer,
        className,
        isFullscreen && styles.fullScreen
      )}
      onClick={blockEvent}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      <video
        width={`${vidWidth}%`}
        height={`${vidHeight}%`}
        ref={videoRef}
        autoPlay={false}
        preload="metadata"
        src={`${src}#t=0.001`}
        onClick={onVideoClick}
      />
      <img
        src={playIconLarge}
        className={classNames(
          styles.playIconOverlay,
          !pausedData.val && styles.hidden
        )}
        alt="play"
      />
      {!!videoRef.current && (
        <Controls
          currentTimeData={currentTimeData}
          pausedData={pausedData}
          totalDuration={totalDuration}
          adornment={adornment}
          className={classNames(
            styles.controls,
            isControlsHidden && styles.hidden
          )}
        />
      )}
    </div>
  );
};
