import { HttpService } from "services/httpService";
import { ListResponse } from "types/common.types";
import { FileType, TherapistId } from "types/therapist.types";
import { DeepPartial } from "types/utils.types";

const TherapistSearchURL = "/therapist/search";
export class TherapistCollectionService<
  TId,
  TEntity extends { id: TId }
> extends HttpService {
  prefix = TherapistSearchURL;

  collectionString: string;

  data: TEntity[] = [];

  isFetched = false;

  isProcessing = false;

  constructor({ collectionString, ...rest }: { collectionString: string }) {
    super(rest);
    this.collectionString = collectionString;
  }

  async list(therapistId: TherapistId, fileType?: FileType, useCache = true) {
    if (!this.isFetched || !useCache) {
      this.isProcessing = true;
      const response = (await this.http.get(
        `${this.prefix}/${therapistId}${this.collectionString}/`
      )) as ListResponse<TEntity[]>;
      this.data = response.results;
      this.isFetched = true;
      this.isProcessing = false;
    }
    return this.data;
  }

  async getById(therapistId: TherapistId, entityId: TId) {
    return (await this.http.get(
      `${this.prefix}/${therapistId}${this.collectionString}/${entityId}/`
    )) as TEntity;
  }

  async create(therapistId: TherapistId, data: DeepPartial<TEntity>) {
    this.isProcessing = true;
    const newEntity = (await this.http.post(
      `${this.prefix}/${therapistId}${this.collectionString}/`,
      data
    )) as TEntity;
    this.data.push(newEntity);
    this.isProcessing = false;
    return newEntity;
  }

  async patch(
    therapistId: TherapistId,
    entityId: TId,
    data: DeepPartial<TEntity>
  ) {
    this.isProcessing = true;
    const newEntity = (await this.http.patch(
      `${this.prefix}/${therapistId}${this.collectionString}/${entityId}/`,
      data
    )) as TEntity;
    this.data = this.data.map((entity) =>
      entity.id === newEntity.id ? newEntity : entity
    );
    this.isProcessing = false;
    return newEntity;
  }

  async delete(therapistId: TherapistId, entityId: TId) {
    this.isProcessing = true;
    await this.http.delete(
      `${this.prefix}/${therapistId}${this.collectionString}/${entityId}/`
    );
    this.data = this.data.filter((entity) => entity.id !== entityId);
    this.isProcessing = false;
  }
}
