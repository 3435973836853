import { Address, PaymentData } from "./CheckoutSubmit";

export class TransactionSubmit {
  address: Address | null | undefined;

  paymentData: PaymentData | null | undefined;

  offers: any | null;

  visitorid = "";

  visitorEmail: string | null = "";

  visitorPhone: string | null = "";

  orderid = 0;
}
