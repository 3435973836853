import logo from "assets/images/logo-with-text.svg";
import cn from "classnames";
import { Header } from "components/Header/Header";
import { homePath } from "pages/Home";
import { FC } from "react";
import { Link } from "react-router-dom";
import { listingService } from "services/listingService";
import { useSnapshot } from "valtio";
import styles from "./ListingHeader.module.scss";

export const ListingHeader: FC = () => {
  const { showFilters } = useSnapshot(listingService);

  return (
    <Header
      className={cn(styles.listingHeader, showFilters && styles.hideHeader)}
    >
      <div className="container-listing-normal">
        <Link to={homePath} className={styles.listingHeader__logo}>
          <img src={logo} alt="logo" />
        </Link>
      </div>
    </Header>
  );
};
