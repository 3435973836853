import { states } from "constants/options";
import { ReactComponent as XCircle } from "assets/icons/tick-circle-red.svg";
import cn from "classnames";
import { Button } from "components/UIKit/Button";
import { Input } from "components/UIKit/Input";
import { AppSelect } from "components/UIKit/Select";
import { useGA } from "hooks/useGA";
import { REQUIRED_MESSAGE } from "pages/FinalCheckout/constants";
import { StepProps } from "pages/FinalCheckout/types";
import { FC, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Address, PaymentData } from "services/CheckoutSubmit";
import { Product, finalCheckoutService } from "services/finalCheckoutService";
import { useSnapshot } from "valtio";
import styles from "../../FinalCheckout.module.scss";
import { confirmationPath } from "../Confirmation";
import {
  formatCreditCardNumber,
  formatExpirationDate,
  formatCVC,
  getCardType,
} from "./utils";
import localStyles from "./PaymentInfo.module.scss";

export const PaymentInfo: FC<StepProps> = ({ handlePrevStep }) => {
  useGA().ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  const [searchParams] = useSearchParams();
  const { productData } = useSnapshot(finalCheckoutService);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    address: Address;
    paymentData: PaymentData;
  }>();

  const [state, setState] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: undefined,
    icon: "",
  });

  const [showSpinner, setShowSpinner] = useState(false);
  const [paymentFailed, setpaymentFailed] = useState(false);
  const [, setpaymentMessage] = useState("");
  const navigate = useNavigate();

  const therapistId = useMemo(
    () => searchParams.get("therapistId"),
    [searchParams]
  );
  const onSubmit = (data: { address: Address }) => {
    const payData = {
      card_number: state.number,
      cardholder_name: state.name,
      cvv_code: state.cvc,
      exp_date: state.expiry,
    } as PaymentData;

    if (therapistId) {
      finalCheckoutService.setTherapistID(therapistId);
    }
    if (
      payData.card_number &&
      payData.cardholder_name &&
      payData.cvv_code &&
      payData.exp_date
    ) {
      finalCheckoutService.setPaymentData(payData);
      finalCheckoutService.setShippingAddress(data.address);
      setShowSpinner(true);
      finalCheckoutService.submitPaymentData().then((result) => {
        if (result.success) {
          navigate(confirmationPath);
        } else {
          setShowSpinner(false);
          setpaymentFailed(true);
          setpaymentMessage(result.message);
        }
      });
    }
  };

  const initSelectedProducts = () => {
    const initialSelectedProducts: Product[] = [];
    let letterProduct: Product | null = null;

    productData.forEach((product: Product) => {
      if (finalCheckoutService.isOfferSelected(product.product_type)) {
        initialSelectedProducts.push(product);
      } else if (product.key === finalCheckoutService.chosenLetter) {
        letterProduct = product;
      }
    });
    if (letterProduct != null) {
      initialSelectedProducts.push(letterProduct);
    }
    return initialSelectedProducts;
  };

  const [selectedProducts, setSelectedProducts] =
    useState(initSelectedProducts);

  const getTotalPrice = () => {
    let totalPrice = 0;
    if (selectedProducts) {
      totalPrice = selectedProducts.reduce(
        (sum, { price: currentPrice }) => sum + currentPrice,
        0
      );
    }
    return totalPrice.toFixed(2);
  };

  const removeProduct = (product: Product) => {
    finalCheckoutService.deSelectOffer(product.product_type);
    setSelectedProducts(
      selectedProducts.filter((element) => element !== product)
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (evt: any) => {
    const name = evt.target.name;
    let value = evt.target.value;

    if (name === "number") {
      value = formatCreditCardNumber(value);
    } else if (name === "expiry") {
      value = formatExpirationDate(value);
    } else if (name === "cvc") {
      value = formatCVC(value, state.number);
    }

    if (name === "number") {
      setState((prev) => ({
        ...prev,
        [name]: value,
        icon: getCardType(value),
      }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputFocus = (evt: any) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  return (
    <>
      {!paymentFailed ? (
        <section
          className={cn(localStyles.sectionCheckoutCart, "default-typography")}
        >
          <div className="container-normal">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <h1 className="h2 mb-30 mb-xs-50 text-center">Checkout</h1>

                <div className="row">
                  <div className="col-sm-6">
                    <div
                      className={cn(
                        localStyles.sectionCheckoutCart__contentBlock,
                        "mb-30",
                        "mb-sm-0"
                      )}
                    >
                      <div
                        className={
                          localStyles.sectionCheckoutCart__contentHeading
                        }
                      >
                        <h4 className="mb-0">Payment info</h4>
                      </div>

                      <div
                        className={localStyles.sectionCheckoutCart__contentBody}
                      >
                        <div className="row gutter-20">
                          <div className="col-12">
                            <div className="mb-20">
                              <Input
                                type="string"
                                name="name"
                                placeholder="Name"
                                value={state.name}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                error={
                                  errors.paymentData?.cardholder_name?.message
                                }
                                autoComplete="cc-name"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row mb-20">
                              <div className="col-12">
                                <Input
                                  type="string"
                                  name="number"
                                  placeholder="Card Number"
                                  value={state.number}
                                  onChange={handleInputChange}
                                  onFocus={handleInputFocus}
                                  error={
                                    errors.paymentData?.card_number?.message
                                  }
                                  pattern="[\d| -]{16,22}"
                                  autoComplete="cc-number"
                                  required
                                />
                              </div>
                              {/* <div className="col-3">
                              {state.icon && (
                                <span>
                                  <img
                                    src={state.icon}
                                    width={"100%"}
                                    height={"100%"}
                                  />
                                </span>
                              )}
                            </div> */}
                            </div>
                          </div>
                          <div className="col-xxs-6">
                            <div className="mb-20 mb-sm-0">
                              <Input
                                type="tel"
                                name="expiry"
                                placeholder="Expiration Date"
                                value={state.expiry}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                error={errors.paymentData?.exp_date?.message}
                                pattern="\d\d/\d\d\d\d"
                                autoComplete="cc-exp"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-xxs-6">
                            <div className="mb-20">
                              <Input
                                type="string"
                                name="cvc"
                                placeholder="CVC code"
                                value={state.cvc}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                error={errors.paymentData?.cvv_code?.message}
                                pattern="\d{3,4}"
                                autoComplete="cc-csc"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="mb-20">
                              <Input
                                autoComplete="address-line1"
                                placeholder="Billing Street Address"
                                {...register("address.street_address", {
                                  required: REQUIRED_MESSAGE,
                                })}
                                error={errors.address?.street_address?.message}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="mb-20">
                              <Input
                                autoComplete="address-line2"
                                placeholder="Apt, Suite, Unit, etc (optional)"
                                {...register("address.unit")}
                              />
                            </div>
                          </div>
                          <div className="col-xxs-6">
                            <div className="mb-20">
                              <Input
                                autoComplete="address-level2"
                                placeholder="City"
                                {...register("address.city", {
                                  required: REQUIRED_MESSAGE,
                                })}
                                error={errors.address?.city?.message}
                              />
                            </div>
                          </div>
                          <div className="col-xxs-6">
                            <div className="mb-20">
                              <Input
                                placeholder="Zip"
                                {...register("address.zip", {
                                  required: REQUIRED_MESSAGE,
                                  validate: (value) =>
                                    /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) ||
                                    "Should be valid zip code",
                                })}
                                error={errors.address?.zip?.message}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div>
                              <Controller
                                name="address.state"
                                control={control}
                                rules={{ required: REQUIRED_MESSAGE }}
                                render={({ field, fieldState }) => (
                                  <AppSelect
                                    placeholder="State"
                                    options={states}
                                    {...field}
                                    value={states.find(
                                      ({ value }) => value === field.value
                                    )}
                                    onChange={(newValue) =>
                                      field.onChange(
                                        (
                                          newValue as {
                                            value: string;
                                            label: string;
                                          }
                                        ).value
                                      )
                                    }
                                    error={fieldState.error?.message}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div
                      className={localStyles.sectionCheckoutCart__contentBlock}
                    >
                      <div
                        className={
                          localStyles.sectionCheckoutCart__contentHeading
                        }
                      >
                        <div className="row align-items-center">
                          <div className="col-8">
                            <h4 className="mb-0">Your Cart</h4>
                          </div>
                          <div className="col-4 text-end">
                            <p className="text-sm text-gray-700">
                              {selectedProducts.length} items
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className={localStyles.sectionCheckoutCart__contentBody}
                      >
                        <ul className={localStyles.sectionCheckoutCart__cart}>
                          {selectedProducts.map((product: Product) => (
                            <li
                              className={
                                localStyles.sectionCheckoutCart__cartItem
                              }
                              key={product.key}
                            >
                              <div className="row gutter-10">
                                <div className="col-6">
                                  <p>{product.name}</p>
                                </div>
                                <div className="col-3 text-end">
                                  {!product.isletter && (
                                    <button
                                      type="button"
                                      className={
                                        localStyles.sectionCheckoutCart__cartRemoveBtn
                                      }
                                      onClick={() => removeProduct(product)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                                <div className="col-3 text-end">
                                  <p>
                                    <strong className="fw-semibold">
                                      ${product.price}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}

                          <li
                            className={cn(
                              localStyles.sectionCheckoutCart__cartItem,
                              localStyles.sectionCheckoutCart__cartItemSubtotal
                            )}
                          >
                            <div className="row gutter-10">
                              <div className="col-7">
                                <p className="text-xl">Subtotal:</p>
                              </div>
                              <div className="col-5 text-end">
                                <p className="text-xl">
                                  <strong className="fw-semibold">
                                    ${getTotalPrice()}
                                  </strong>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center pt-30 pt-md-50">
              <div className="col-xs-8 col-md-6">
                <div className={cn("row", "gutter-20", styles.reverseMobile)}>
                  <div className="col-xxs-6">
                    <Button
                      className="w-100 mb-15 mb-xxs-0"
                      color="outline-gray"
                      onClick={handlePrevStep}
                    >
                      Back
                    </Button>
                  </div>
                  <div className="col-xxs-6">
                    {showSpinner ? (
                      <Button
                        id="contact"
                        type="submit"
                        disabled={true}
                        isLoading={true}
                        className="btn__spinner"
                      >
                        Please Wait
                      </Button>
                    ) : (
                      <Button
                        className="w-100 mb-15 mb-xxs-0"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Pay
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section
          className={cn(localStyles.sectionCheckoutCart, "default-typography")}
        >
          <div className="container-normal">
            <div className="row justify-content-center">
              <XCircle
                className={localStyles.xcircle}
                style={{ height: "100px" }}
              />
              <h1 className={cn(styles.title, localStyles.title)}>
                Unsuccessful payment
              </h1>
              <p className={cn(styles.text, localStyles.error_text)}>
                {/* {paymentMessage != null ? `${paymentMessage}` : <></>} */}
                <br />
                Please try again or enter other payment information.
              </p>
              <a className={localStyles.link} onClick={handlePrevStep}>
                Try again
              </a>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
