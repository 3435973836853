import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as MapMarker } from "assets/icons/map-marker.svg";
import defaultAvatar from "assets/images/avatar.png";
import TherapyOptions from "components/TherapyOptions";
import { useResponsive } from "hooks/useResponsive";
import { upperCase } from "lodash";
import { FC } from "react";
import { TherapistProfile } from "services/therapistProfileService";

import styles from "./TherapistCard.module.scss";

interface Props {
  therapist: TherapistProfile;
}

export const TherapistCard: FC<Props> = ({ therapist }) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    user,
    avatar,
    primary_credential_is_verified,
    license_state,
    is_available,
    mental_health_role,
  } = therapist;
  /* eslint-enable @typescript-eslint/naming-convention */

  const md = useResponsive("md");
  const xs = useResponsive("xs");

  const cardName = [
    `${user.first_name} ${user.last_name}`,
    upperCase(mental_health_role),
  ]
    .filter((item) => item)
    .join(", ");

  return (
    <div className={styles.therapistCard}>
      <div className={styles.imageContainer}>
        <img
          src={avatar?.file || defaultAvatar}
          alt="Profile image"
          className={styles.avatar}
        />
      </div>
      <div className={styles.info}>
        <h3 className={styles.name} style={{ fontSize: "24px" }}>
          {cardName}
        </h3>
        <div
          className={styles.role}
          style={{ color: "#162521", fontSize: "16px" }}
        >
          State Licensed ESA Therapist
          {primary_credential_is_verified && (
            <div className={styles.verifiedBadge}>
              <div className={styles.iconContainer}>
                <CheckIcon />
              </div>
              {!xs && "Verified"}
            </div>
          )}
        </div>
        {license_state && (
          <div className={styles.license}>
            {md ? (
              <MapMarker className={styles.mapMarker} />
            ) : (
              "State License: "
            )}
            {license_state}
          </div>
        )}
        {is_available && (
          <TherapyOptions
            styles={styles}
            therapist={therapist}
            title={md ? "Available therapy options:" : "Therapy Options:"}
          />
        )}
      </div>
    </div>
  );
};
