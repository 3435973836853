import { TherapistCollectionService } from "services/therapistCollectionService";
import { StateLicense, StateLicenseId } from "types/stateLicense.types";
import { StateLicense as VerifiedStateLicenses } from "types/therapist.types";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";

class StateLicenseService extends TherapistCollectionService<
  StateLicenseId,
  StateLicense
> {
  constructor(props?: any) {
    super({ ...props, collectionString: "/state-licenses" });
  }

  get verifiedStateLicenses(): VerifiedStateLicenses[] {
    return this.data
      .filter(
        (license) =>
          license.license_state && license.license_number && license.is_verified
      )
      .map((license) => ({
        state: license.license_state,
        number: license.license_number,
      }));
  }
}

export const stateLicenseService = proxy(new StateLicenseService());
devtools(stateLicenseService, { name: "stateLicenseService" });
