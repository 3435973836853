import React from "react";

interface GlobalState {
  prevPage: string;
}

interface GlobalContextType {
  globalState: GlobalState | null;
  setGlobalState: React.Dispatch<React.SetStateAction<GlobalState | null>>;
}

export const GlobalContext = React.createContext<GlobalContextType>({
  globalState: null,
  setGlobalState: () => null,
});

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [globalState, setGlobalState] = React.useState<GlobalState | null>(
    null
  );

  const value = {
    globalState,
    setGlobalState,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
