import { resolve } from "dns/promises";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { HttpService } from "services/httpService";
import { Pagination } from "types/pagination.types";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { visitorIdService } from "./visitoridService";

class FunnelService extends HttpService {
  private static prefix = "/connect/";

  private isFetching = false;

  private isError = false;

  async submitFunnel(data: any): Promise<string> {
    try {
      this.isFetching = true;
      const vid = await visitorIdService.getVisitorID(false);
      const SubmitData = data;
      SubmitData.VisitorID = vid;
      const result = (await this.http.post(
        "/connect/CaptureFunnel/?id=v1",
        SubmitData
      )) as string;

      return result;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return "";
    } finally {
      this.isFetching = false;
    }
  }
}

export const funnelService = proxy(new FunnelService());

devtools(funnelService, { name: "funnelService" });
