import ReactGA from "react-ga4";

export const useGA = () => {
  ReactGA.initialize([
    {
      trackingId: "G-15SQC2PS01",
    },
    {
      trackingId: "G-79JVSB3956",
    },
    {
      trackingId: "AW-11025675463",
    },
  ]);

  return { ReactGA };
};
