import cn from "classnames";
import { HomeLayout } from "components/Layout/HomeLayout";
import { InputSmartSearch } from "components/UIKit/InputSmartSearch";
import { GlobalContext } from "GlobalProvider";
import { useGA } from "hooks/useGA";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { useTitle } from "hooks/useTitle";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connectService } from "services/connectService";
import { listingService } from "services/listingService";
import { systemSettingsService } from "services/systemSettingsService";
import { visitorIdService } from "services/visitoridService";
import styles from "./Home.module.scss";

export const homeDPath = "/ca-v2a";

export const HomeD = () => {
  useTitle("TheraPass ESA");
  useGA().ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  useTitle("TheraPass ESA");
  const { setGlobalState } = useContext(GlobalContext);
  const [autoAssignMode, setAutoAssignMode] = useState(false);
  const [autoAssignTherpist, setAutoAssignTherapist] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const [searchUrl, setSearchUrl] = useState("/listing");
  let VisitorID = "";

  const navigate = useNavigate();
  const queryNavigate = useNavigateWithQuery();
  visitorIdService.getVisitorID(false).then((vid) => {
    VisitorID = vid;
  });
  // eslint-disable-next-line no-console
  console.log(VisitorID);

  // const checkRedirect = async () => {
  //   const value = await connectService.getInterstitialMode();

  //   const url =
  //     value === "aftersearchbeforeresults" ||
  //     value === "aftersearchinplaceofresults"
  //       ? "/interstitial"
  //       : "/listing";
  //   setSearchUrl(url);
  // };

  visitorIdService.trackEvent("LandingPage", "Home/ca-v2a", "", "", "");
  useEffect(() => {
    visitorIdService.trackEvent("Home", "Visit", "", "", "");
    // checkRedirect();
  }, []);
  const handleSelectPlace = (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
  }) => {
    listingService.setPlaceFilter({
      ...(args?.city && { city: args.city }),
      ...(args?.search && { search: args.search }),
      ...(args?.state && { state: args.state }),
      ...(args?.zip && { zip: args.zip }),
      withoutRequest: true,
    });
  };

  useEffect(() => {
    if (!autoAssignMode) {
      systemSettingsService.get().then((res: any) => {
        if (res.auto_assign_therapist) {
          setAutoAssignTherapist(true);
        }
      });
    }
    setAutoAssignMode(true);
  }, [autoAssignMode, setAutoAssignMode]);

  const checkRedirect = async () => {
    const value: any = await systemSettingsService.get();
    if (value.auto_assign_therapist) {
      let nextPath = "";
      const query = listingService.getQuery();

      connectService.getTherapist(query, true).then((therapist: any) => {
        if (therapist["ESAStatus"] >= 1) {
          nextPath = `/connect`;
          const therapistKey = therapist["id"].toString();
          queryParams.set("therapistId", therapistKey);
          const therapistQuery = `?therapistId=${therapistKey}`;
          visitorIdService.trackEvent(
            "Home",
            "Exit",
            nextPath,
            therapistQuery,
            ""
          );
          queryNavigate(nextPath, therapistQuery);
        } else {
          navigate(searchUrl, {
            state: { withScroll: false },
          });
        }
      });
    } else {
      navigate(searchUrl, {
        state: { withScroll: false },
      });
    }
  };

  useEffect(() => {
    handleSelectPlace({ search: "" });
  }, []);
  return (
    <HomeLayout>
      {/* Mobile Only Header */}
      <section className="d-xxs-none pb-30 default-typography bg-teal-light order-1">
        <div className="container">
          <h1 className="mb-5 fw-bold" style={{ fontSize: "28px" }}>
            CALIFORNIA RESIDENTS
          </h1>
          <h3 className="mb-30 fw-bold">
            Emotional Support Animal (ESA)
            <br />
            Assessments & Letters
          </h3>

          <div className={cn(styles.videoIframeHolder, "mb-30")}>
            <iframe
              src="https://player.vimeo.com/video/868938270?h=66e7276bd8"
              className="of-cover"
            ></iframe>
          </div>

          <h3 className="fw-bold">
            Get your ESA from an
            <br />
            established healthcare provider
          </h3>
        </div>
      </section>
      {/* End */}

      <section className="pt-xxs-15 pt-md-0 pb-40 pb-sm-55 pb-lg-85 default-typography bg-teal-light order-5 order-xxs-1">
        <div className="container">
          <div className="d-none d-xxs-block d-sm-block mb-15 mb-xxs-30">
            <h1 className="h1">CALIFORNIA RESIDENTS</h1>
            <h1 className="h2 mb-10 mb-xs-20">
              Emotional Support Animal (ESA)
              <br />
              Assessments & Letters
            </h1>

            <div className="row">
              <div className="col-sm-9 col-md-8 col-xl-8">
                <h4 className="fw-normal">
                  Get your California DSM-5 disability assessment letter
                  instantly. ESA letter to follow CA Statute AB 468 30-day
                  mandatory period. Choose your therapist and how you want to
                  connect: text message, phone or video call.
                </h4>
              </div>
            </div>
          </div>
          <div className="d-xxs-none mb-15 mb-xxs-30">
            <div className="row">
              <div className="col-sm-9 col-md-8 col-xl-8">
                <h4 className="fw-normal" style={{ fontSize: "16px" }}>
                  Get your California DSM-5 disability assessment letter
                  instantly. ESA letter to follow CA Statute AB 468 30-day
                  mandatory period. Choose your therapist and how you want to
                  connect: text message, phone or video call.
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-7 col-xl-6">
              <div className="mb-xxs-30 mb-md-0">
                <ul className="list-with-check mb-25 mb-sm-0 mb-md-25">
                  <li>No waiting - instant ESA letter delivery (PDF)</li>
                  <li>National healthcare company, state-licensed therapist</li>
                  <li>Valid for both Housing and Travel</li>
                  <li>Get your ESA by text message, phone or video</li>
                  <li>No breed restrictions</li>
                  <li>No extra charge for multiple pets</li>
                  <li>Guaranteed landlord acceptance or money back</li>
                </ul>

                <div className="d-xxs-none d-md-block">
                  <div className={styles.search}>
                    <InputSmartSearch
                      placeholder="Enter your City / Zip"
                      onSelectPlace={handleSelectPlace}
                      maxLength={200}
                      max={200}
                      withButton
                      buttonClass={styles.search__btn}
                      className={styles.search__input}
                      buttonLabel="CONNECT - GET ESA NOW"
                      onButtonClick={() => {
                        setGlobalState({ prevPage: window.location.pathname });
                        checkRedirect();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-5 col-xl-6 d-none d-xxs-block">
              <div className={styles.videoIframeHolder}>
                <iframe
                  src="https://player.vimeo.com/video/868938270?h=66e7276bd8"
                  className="of-cover"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="d-xxs-none  py-40 py-sm-55 py-lg-85 default-typography bg-green-dark-3 bg-xxs-light-mint order-1">
        <div className="container">
          <h2 className="mb-20 mb-xxs-30 mb-md-50 text-xxs-center text-md-start text-white text-xxs-green-dark-2 fw-bold">
            3 Ways to Get Your ESA
          </h2>

          <div className="row gutter-16">
            <div className="col-sm-4 d-flex">
              <div
                className={styles.cardContactOptions}
                style={{ padding: "10px 24px 10px 70px" }}
              >
                <div className={styles.cardContactOptions__icon}>
                  <span className="icomoon-round-message"></span>
                </div>

                <h3 className="fw-bold">Text Message</h3>
                <div className="text-block text-xl lh-sm">
                  <p>
                    Take online disability test from your phone, get letter in
                    minutes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex">
              <div
                className={styles.cardContactOptions}
                style={{ padding: "10px 24px 10px 70px" }}
              >
                <div className={styles.cardContactOptions__icon}>
                  <span className="icomoon-round-phone"></span>
                </div>

                <h3 className="fw-bold">Phone ESA Letter</h3>
                <div className="text-block text-xl lh-sm">
                  <p>
                    Want a more professional evaluation? Connect immediately to
                    a therapist by phone and get ESA letter in minutes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex">
              <div
                className={styles.cardContactOptions}
                style={{ padding: "10px 24px 10px 70px" }}
              >
                <div className={styles.cardContactOptions__icon}>
                  <span className="icomoon-round-video"></span>
                </div>

                <h3 className="fw-bold">Video Call</h3>
                <div className="text-block text-xl lh-sm">
                  <p>
                    If you’re more comfortable speaking to a therapist on video,
                    connect immediately from your phone and get your ESA letter
                    in minutes
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none d-xxs-block">
            <div className={cn(styles.search, "mx-auto")}>
              <InputSmartSearch
                placeholder="Enter your City / Zip"
                onSelectPlace={handleSelectPlace}
                maxLength={200}
                max={200}
                withButton
                buttonClass={styles.search__btn}
                className={styles.search__input}
                buttonLabel="CONNECT - GET ESA NOW"
                onButtonClick={() => {
                  setGlobalState({ prevPage: window.location.pathname });
                  checkRedirect();
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-40 py-sm-55 py-lg-85 default-typography bg-green-dark-3 bg-xxs-light-mint order-1 d-none d-xxs-block d-sm-block">
        <div className="container">
          <h2 className="mb-20 mb-xxs-30 mb-md-50 text-xxs-center text-md-start text-white text-xxs-green-dark-2">
            3 Ways to Get Your ESA Letter
          </h2>

          <div className="row gutter-16">
            <div className="col-sm-4 d-flex">
              <div className={styles.cardContactOptions}>
                <div className={styles.cardContactOptions__icon}>
                  <span className="icomoon-round-message"></span>
                </div>

                <h4 className="fw-semibold mb-10">Text Message ESA Letter</h4>
                <div className="text-block text-xl lh-sm">
                  <p>
                    Take online disability test from your phone, get letter in
                    minutes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex">
              <div className={styles.cardContactOptions}>
                <div className={styles.cardContactOptions__icon}>
                  <span className="icomoon-round-phone"></span>
                </div>

                <h4 className="fw-semibold mb-10">Phone ESA Letter</h4>
                <div className="text-block text-xl lh-sm">
                  <p>
                    Want a more professional evaluation? Connect immediately to
                    a therapist by phone and get ESA letter in minutes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex">
              <div className={styles.cardContactOptions}>
                <div className={styles.cardContactOptions__icon}>
                  <span className="icomoon-round-video"></span>
                </div>

                <h4 className="fw-semibold mb-10">Video Call</h4>
                <div className="text-block text-xl lh-sm">
                  <p>
                    If you’re more comfortable speaking to a therapist on video,
                    connect immediately from your phone and get your ESA letter
                    in minutes
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none d-xxs-block">
            <div className={cn(styles.search, "mx-auto")}>
              <InputSmartSearch
                placeholder="Enter your City / Zip"
                onSelectPlace={handleSelectPlace}
                maxLength={200}
                max={200}
                withButton
                buttonClass={styles.search__btn}
                className={styles.search__input}
                buttonLabel="CONNECT - GET ESA NOW"
                onButtonClick={() => {
                  setGlobalState({ prevPage: window.location.pathname });
                  checkRedirect();
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-40 pt-sm-55 pt-lg-85 pb-20 pb-xxs-40 pb-sm-55 pb-lg-85 default-typography bg-teal-light order-1">
        <div className="container">
          <h2 className="d-xs-none d-xxs-block mb-20 mb-xxs-30 mb-md-50 text-xxs-center text-md-start text-black fw-bold">
            Why TheraPass?
          </h2>
          <h2 className="d-none d-xs-block d-sm-block mb-20 mb-xxs-30 mb-md-50 text-xxs-center text-md-start text-green-dark-2">
            Why TheraPass?
          </h2>

          <div className="row gutter-16">
            <div className="col-6 col-lg-3 d-flex">
              <div className={styles.cardBenefits}>
                <div className={styles.cardBenefits__icon}>
                  <span className="icomoon-round-evaluation"></span>
                </div>

                <h2 className="fw-bold mb-5">+2 Million</h2>

                <div
                  className="d-xs-none d-xxs-block text-block lh-sm"
                  style={{ fontSize: "14px" }}
                >
                  <p>
                    ESA & Disability <br></br> Assessments
                  </p>
                </div>

                <div className="d-none d-xxs-block d-sm-block text-block text-xxl lh-sm">
                  <p>
                    ESA & Disability <br></br> Assessments
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 d-flex">
              <div className={styles.cardBenefits}>
                <div className={styles.cardBenefits__icon}>
                  <span className="icomoon-round-user"></span>
                </div>

                <h2 className="fw-bold mb-5">+600,000</h2>

                <div
                  className="d-xs-none d-xxs-block text-block lh-sm"
                  style={{ fontSize: "14px" }}
                >
                  <p>
                    Therapist & Mental<br></br> Health Professionals
                  </p>
                </div>

                <div className="d-none d-xxs-block d-sm-block text-block text-xxl lh-sm">
                  <p>
                    Therapist & Mental<br></br> Health Professionals
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 d-flex">
              <div className={styles.cardBenefits}>
                <div className={styles.cardBenefits__icon}>
                  <span className="icomoon-round-thumbs-up"></span>
                </div>

                <h2 className="fw-bold mb-5">+200,000</h2>

                <div
                  className="d-xs-none d-xxs-block text-block lh-sm"
                  style={{ fontSize: "14px" }}
                >
                  <p>
                    Satisfied<br></br> Customers
                  </p>
                </div>

                <div className="d-none d-xxs-block d-sm-block text-block text-xxl lh-sm">
                  <p>
                    Satisfied<br></br> Customers
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 d-flex">
              <div className={styles.cardBenefits}>
                <div className={styles.cardBenefits__icon}>
                  <span className="icomoon-round-6-years"></span>
                </div>

                <h2 className="fw-bold mb-5">+6</h2>

                <div
                  className="d-xs-none d-xxs-block text-block lh-sm"
                  style={{ fontSize: "14px" }}
                >
                  <p>
                    Years in<br></br> Business
                  </p>
                </div>

                <div className="d-none d-xxs-block d-sm-block text-block text-xxl lh-sm">
                  <p>
                    Years in<br></br> Business
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={cn(styles.quoteBlock, "d-none d-xs-block")}>
            <div className="row">
              <div className="col-md-5">
                <h3 className="fw-bold mb-30 mb-md-0">
                  More than 2 million people trust TheraPass with their ESA
                  Letters and mental health assessments.
                </h3>
              </div>
              <div className="col-md-7">
                <div className="text-xxl fw-medium">
                  <p className="mb-20">
                    “With TheraPass I always have my choice of how to connect
                    with a therapist - by phone, video, or text message. Getting
                    my ESA Letter took just a couple of minutes - no waiting!”
                  </p>
                  <p>- Andrea B.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none d-xxs-block">
            <div className={cn(styles.search, "mx-auto")}>
              <InputSmartSearch
                placeholder="Enter your City / Zip"
                onSelectPlace={handleSelectPlace}
                maxLength={200}
                max={200}
                withButton
                buttonClass={styles.search__btn}
                className={styles.search__input}
                buttonLabel="CONNECT - GET ESA NOW"
                onButtonClick={() => {
                  setGlobalState({ prevPage: window.location.pathname });
                  checkRedirect();
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </HomeLayout>
  );
};
