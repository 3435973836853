import { ReactComponent as ChatIcon } from "assets/icons/comment-dots.svg";
import { ReactComponent as LandlineIcon } from "assets/icons/landline-phone.svg";
import { ReactComponent as MobileIcon } from "assets/icons/mobile-phone.svg";
import cn from "classnames";
import { Button } from "components/UIKit/Button";
import { connectPath } from "pages/Connect";
import { therapistProfileService } from "services/therapistProfileService";
import { formatPhone } from "utils/formatPhone";
import { useSnapshot } from "valtio";
import { TherapistMapList } from "../TherapistMapList";
import styles from "./TherapistRightSide.module.scss";

export const TherapistRightSide = () => {
  const { currentProfile } = useSnapshot(therapistProfileService);

  const showChatConnect = currentProfile?.chat_status === "available";

  return (
    <aside className={styles.root}>
      {showChatConnect && (
        <Button
          isLink
          to={`${connectPath}?therapistId=${currentProfile?.id}`}
          className={cn(styles.button, "w-100")}
        >
          <ChatIcon />
          Get ESA Letter Now
        </Button>
      )}
      <TherapistMapList />
    </aside>
  );
};
